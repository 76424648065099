import { Box, Text } from '@chakra-ui/layout'

import { useI18Context } from '../providers/i18Provider'
import { useSettingsContext } from '../providers/SettingsProvider'

import { Button } from './buttons/Button'

export const ClearMessagesBody = () => {
  const { t } = useI18Context()
  return (
    <Box>
      <Text as="h2" className="mb-2 font-bold">
        {t('clearMessagesModal.areYouSureClearMessages')}
      </Text>
      <Text as="p">{t('clearMessagesModal.clearMessagesExplanation')}</Text>
    </Box>
  )
}

type ClearMessagesProps = {
  onClose: () => void
  primaryButtonOnClick: () => void
}

export const ClearMessagesFooter = (props: ClearMessagesProps) => {
  const { onClose, primaryButtonOnClick } = props
  const { t } = useI18Context()
  const { isLightMode } = useSettingsContext()

  const clearMessagesOnClick = () => {
    primaryButtonOnClick()
    onClose()
  }

  return (
    <Box className="flex flex-col gap-3 md:flex-row">
      <Button className="ml-auto text-sm md:text-base max-w-min" id="close-button" onClick={onClose} aria-label="close">
        {t('controls.cancel')}
      </Button>
      <Button
        className={`text-sm md:text-base ${
          isLightMode
            ? 'clear-messages-modal-button text-white bg-kpmgCobaltBlue hover:bg-kpmgCobaltBlueHover'
            : 'text-black bg-white hover:bg-whiteHover'
        }`}
        id="next-button"
        onClick={clearMessagesOnClick}
        aria-label="next"
      >
        {t('clearMessagesModal.clearAllMessages')}
      </Button>
    </Box>
  )
}
