import { useEffect } from 'react'
import { InteractionStatus } from '@azure/msal-browser'
import { Box } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'

import { useAuthContext } from 'providers/AuthProvider'
import { useI18Context } from 'providers/i18Provider'

export const AuthenticationSpinner = ({ redirect = true }) => {
  const { isAuthenticated, msalInstance, inProgress } = useAuthContext()
  const { language } = useI18Context()

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None && redirect) {
      msalInstance.loginRedirect({
        redirectStartPage: '/#/login',
        scopes: [],
        extraQueryParameters: {
          ui_locales: language,
        },
      })
    }
  }, [msalInstance, isAuthenticated, language, inProgress, redirect])

  return (
    <Box className="flex items-center justify-center min-h-screen text-2xl">
      <Spinner size="lg" />
    </Box>
  )
}
