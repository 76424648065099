import { KeyboardEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { IconType } from 'react-icons'
import { BiChevronLeft, BiChevronRight, BiX } from 'react-icons/bi'
import { Box, Text } from '@chakra-ui/layout'
import cx from 'classnames'

import { IconButton } from 'components/buttons/IconButton'

import { useThemeContext } from 'providers/ThemeProvider'

import { useIsMounted } from '../../hooks/useIsMounted'
import { useSettingsContext } from '../../providers/SettingsProvider'

type DrawerProps = {
  isOpen: boolean
  isSummary?: boolean
  setIsOpen: (value: boolean) => void
  drawerIcon: IconType
  drawerTitle: string
  children: ReactNode | ((props: { isDrawerExpanded: boolean }) => ReactNode)
  expandable?: boolean
  position: 'left' | 'right'
  isTransition?: boolean
}

export const Drawer = (props: DrawerProps) => {
  const {
    drawerIcon: DrawerIcon,
    drawerTitle,
    isOpen,
    isSummary,
    isTransition = true,
    setIsOpen,
    expandable = false,
    children,
    position,
  } = props

  const { isLightMode } = useSettingsContext()
  const { isTablet } = useThemeContext()

  const { isMounted } = useIsMounted()

  const [isDrawerExpanded, setIsDrawerExpanded] = useState(false)
  const [hiddenClass, setHiddenClass] = useState(false)

  const handleExpansion = useCallback(() => {
    setIsDrawerExpanded(!isDrawerExpanded)
  }, [isDrawerExpanded])

  useEffect(() => {
    const timer = setTimeout(() => {
      setHiddenClass(true)
    }, 310)

    return () => clearTimeout(timer)
  }, [])

  const getTransitionClassNames = () => {
    return cx({
      'transition-none': !isTransition,
      'translate-x-0': isOpen,
      'translate-x-[-100%]': !isOpen && position === 'left',
      'translate-x-full': !isOpen && position === 'right',
      'transition-translate': isMounted,
    })
  }

  return (
    <Box
      className={`flex flex-col absolute shadow-xl z-40 duration-300 transform ease-in-out bottom-2 md:bottom-4 top-0 w-8/12
      ${position === 'left' ? 'left-0 rounded-r-xl' : 'right-0 rounded-l-xl'}
      ${hiddenClass && !isOpen ? 'invisible' : ''}
    ${
      !isDrawerExpanded
        ? expandable
          ? 'md:w-[300px] xl:w-[15.5%]'
          : 'md:w-[300px] xl:w-[16%]'
        : 'md:w-[600px] xl:w-[60%]'
    }
        ${isLightMode ? 'bg-white' : 'background-primary bg-kpmgDarkBlue'}
        ${getTransitionClassNames()}
      `}
      test-id={`${drawerTitle}-drawer`}
    >
      {/* Drawer header */}
      <Box className="flex items-center justify-between p-3 border-b box-content h-[40px]">
        <Box className={`${expandable && isTablet && 'ml-2'} flex items-center`}>
          <DrawerIcon className="mr-2 text-xl md:text-2xl" />
          <Text className="text-base 2xl:text-lg">{drawerTitle}</Text>
        </Box>
        {isOpen && (
          <IconButton
            iconName={BiX}
            size="sm"
            tabIndex={0}
            onClick={() => setIsOpen(false)}
            onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
              if (event.key === 'Enter') {
                setIsOpen(false)
              }
            }}
            aria-label="close"
          />
        )}
      </Box>
      <Box className={`p-3 ${isSummary ? 'max-h-full overflow-y-auto' : 'h-full overflow-y-auto'} min-h-0`}>
        {typeof children === 'function'
          ? children({
              isDrawerExpanded,
            })
          : children}
      </Box>
      {isOpen && expandable && isTablet && (
        <Box
          className="absolute flex items-center justify-center w-6 h-6 text-black border cursor-pointer -left-3 top-5 rounded-xl bg-kpmgGray5 border-kpmgDarkBlue"
          onClick={handleExpansion}
        >
          {isDrawerExpanded ? (
            <BiChevronRight className="md:w-5 md:h-5" />
          ) : (
            <BiChevronLeft className="md:w-5 md:h-5" />
          )}
        </Box>
      )}
    </Box>
  )
}
