import { ReactElement, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import { FEUIConfig } from 'types/types'

import { Footer } from '../components/Footer'
import { Navbar } from '../components/Navbar'

import { ErrorBoundary } from './ErrorBoundary'

export function Layout({ children, config }: { children: ReactNode; config?: FEUIConfig[] }): ReactElement {
  const location = useLocation()

  return (
    <div className="flex flex-col min-h-screen">
      {/* Conditional styling based on whether the current route matches any botConfig route */}
      {/* If it matches any of the botConfig routes, make the Navbar + Bot content take up the full screen */}
      <div
        className={`flex flex-col ${
          (config && config.some((botConfig) => location.pathname.startsWith(botConfig.route))) ||
          location.pathname === '/assessment'
            ? 'h-screen'
            : 'flex-grow'
        }`}
      >
        <header>
          <Navbar config={config} />
        </header>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}
