import { Component, ReactNode } from 'react'
import { BiError } from 'react-icons/bi'
import { Box, Text } from '@chakra-ui/layout'

import { I18Context } from '../providers/i18Provider'
import { convertErrorToString } from '../utils/handleErrors'

type APIErrorBoundaryState = {
  hasError: boolean
  errorMessage: string | null
}

type APIErrorBoundaryProps = {
  children: ReactNode
}

export class AuthErrorBoundary extends Component<APIErrorBoundaryProps, APIErrorBoundaryState> {
  constructor(props: APIErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, errorMessage: null }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error): void {
    this.setState({ ...this.state, errorMessage: convertErrorToString(error) })
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <I18Context.Consumer>
          {(context) => {
            return (
              <Box className="flex flex-col items-center flex-grow mt-20 mb-40 text-center width-layout">
                <Box className="flex items-center mb-4">
                  <BiError className="mr-2 text-2xl text-red-600 md:text-3xl" />
                  <Text as="h1" className="text-xl md:text-2xl font-opensanscondensed">
                    {context.t('generic.authErrorBoundary1')}
                  </Text>
                </Box>
                <Text as="h2" className="md:text-lg">
                  {context.t('generic.authErrorBoundary2')}
                </Text>
                <Text className="md:text-base">{this.state.errorMessage}</Text>
              </Box>
            )
          }}
        </I18Context.Consumer>
      )
    }

    return this.props.children
  }
}
