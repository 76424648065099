import { createContext, ReactNode, useContext } from 'react'
import { useMediaQuery } from '@chakra-ui/media-query'

type ThemeType = {
  isDesktop: boolean
  isExtraLargeDesktop: boolean
  isLaptop: boolean
  isLargeDesktop: boolean
  isMobile: boolean
  isTablet: boolean
}

export const ThemeContext = createContext<ThemeType>({} as ThemeType)

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [isMobile] = useMediaQuery('(max-width: 450px)')
  const [isTablet] = useMediaQuery('(min-width: 768px)')
  const [isLaptop] = useMediaQuery('(min-width: 1025px)')
  const [isDesktop] = useMediaQuery('(min-width: 1280px)')
  const [isLargeDesktop] = useMediaQuery('(min-width: 1536px)')
  const [isExtraLargeDesktop] = useMediaQuery('(min-width: 2048px)')

  return (
    <>
      <ThemeContext.Provider
        value={{
          isDesktop,
          isExtraLargeDesktop,
          isLaptop,
          isLargeDesktop,
          isMobile,
          isTablet,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </>
  )
}

export const useThemeContext = (): ThemeType => useContext(ThemeContext)
