import Markdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vs } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { Box, Text } from '@chakra-ui/layout'
import rehypeSanitize from 'rehype-sanitize'

export type MarkdownProps = {
  content: string
  forceMarkdown?: boolean
  renderCodeSnippets?: boolean
}

const allowedMarkdownTags = [
  'p',
  'ol',
  'ul',
  'li',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'span',
  'b',
  'em',
  'i',
  'small',
  'strong',
  'sub',
  'sup',
  'ins',
  'del',
  'mark',
  'code',
]

export const MarkdownRenderer = ({ content, forceMarkdown = true, renderCodeSnippets = true }: MarkdownProps) => {
  if (!forceMarkdown) {
    return <Text as="span">{content}</Text>
  }

  return (
    <Markdown
      rehypePlugins={[[rehypeSanitize, { tagNames: allowedMarkdownTags }]]}
      children={content}
      components={{
        code(props) {
          const match = props.className ? /language-(\w+)/.exec(props.className) : null
          const language = match ? match[1] : undefined

          // If we have enabled rendering code snippets and we have a valid coding language, render the snippet
          if (renderCodeSnippets && language) {
            return (
              <>
                <Box className="px-4 py-1.5 mt-2 bg-kpmgGray45 rounded-t-md text-xs">
                  <Text>{language}</Text>
                </Box>
                <SyntaxHighlighter
                  children={String(props.children)}
                  className="mt-0 text-xs md:text-sm rounded-b-md"
                  language={language}
                  styles={vs}
                />
              </>
            )
          } else {
            return <code className="text-xs md:text-sm" {...props} />
          }
        },
      }}
    />
  )
}
