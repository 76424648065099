import { Component, ErrorInfo, ReactNode } from 'react'
import { BiError } from 'react-icons/bi'
import { Box, Text } from '@chakra-ui/layout'

import { withEventLoggerHOC } from '../components/withEventLoggerHOC'
import { I18Context } from '../providers/i18Provider'
import { UIErrorEventPayload } from '../types/types'
import { ReactError } from '../utils/appError'

type errorBoundaryState = {
  hasError: boolean
}

type ErrorBoundaryProps = {
  children: ReactNode
  logUIErrorEvent: (payload: UIErrorEventPayload) => void
}

export const ErrorBoundary = withEventLoggerHOC(
  class ErrorBoundary extends Component<ErrorBoundaryProps, errorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props)
      this.state = { hasError: false }
    }

    static getDerivedStateFromError() {
      return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
      this.props.logUIErrorEvent({
        errorMessage: 'ui-run-time-error',
        error: new ReactError('React UI error', errorInfo, error),
      })
    }

    render(): ReactNode {
      if (this.state.hasError) {
        return (
          <I18Context.Consumer>
            {(context) => {
              return (
                <Box className="flex flex-col items-center flex-grow mt-20 mb-40 text-center width-layout">
                  <Box className="flex items-center mb-4">
                    <BiError className="mr-2 text-2xl text-red-600 md:text-3xl" />
                    <Text as="h1" className="text-xl md:text-2xl font-opensanscondensed">
                      {context.t('generic.errorBoundary1')}
                    </Text>
                  </Box>
                  <Text as="h2" className="md:text-lg">
                    {context.t('generic.errorBoundary2')}
                  </Text>
                </Box>
              )
            }}
          </I18Context.Consumer>
        )
      }

      return this.props.children
    }
  }
)
