import { CSVLink } from 'react-csv'
import { BiSolidDownload } from 'react-icons/bi'
import { Box } from '@chakra-ui/layout'

import { useI18Context } from 'providers/i18Provider'
import { useSettingsContext } from 'providers/SettingsProvider'

import { ResponsiveTable } from '../components/ResponsiveTable'
import { TableData } from '../types/types'

import { TextIconButton } from './buttons/TextIconButton'

type TableProps = {
  data: TableData
}

export function Table(props: TableProps) {
  const { data } = props
  const { t } = useI18Context()

  const { isLightMode } = useSettingsContext()

  return (
    <Box>
      <Box className="pt-2 max-h-[40vh] w-full text-xs md:text-sm overflow-auto">
        <ResponsiveTable data={data.rows} headers={data.headers} />
      </Box>
      <CSVLink
        data={data.rows}
        headers={data.headers.map((header) => {
          return { key: header.field, label: header.headerName }
        })}
        filename="kleo_table.csv"
        asyncOnClick={true}
        className="w-fit"
      >
        <TextIconButton
          className={`mt-2 ${
            isLightMode
              ? 'clear-messages-modal-button text-white bg-kpmgCobaltBlue hover:bg-kpmgCobaltBlueHover text-xs md:text-sm'
              : 'text-black bg-white hover:bg-whiteHover'
          }`}
          size="sm"
          rightIcon={<BiSolidDownload className="ml-2" />}
        >
          {t('generic.exportData')}
        </TextIconButton>
      </CSVLink>
    </Box>
  )
}
