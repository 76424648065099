import { useEffect, useMemo } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Box, Text } from '@chakra-ui/layout'

import { useConversationSettingsContext } from 'providers/ConversationSettingsProvider'
import { useFiltersContext } from 'providers/FiltersAndFormsProvider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useUploadContext } from 'providers/UploadProvider'

import type {
  BotFilterValues,
  BotFormValues,
  DisclaimerProperty,
  LeftDrawerProps,
  RightDrawerProps,
  SubheaderProperty,
} from 'types/types'

import { useEventLogger } from '../hooks/useEventLogger'
import { useIsMounted } from '../hooks/useIsMounted'
import { useI18Context } from '../providers/i18Provider'
import { useSettingsContext } from '../providers/SettingsProvider'
import { useThemeContext } from '../providers/ThemeProvider'
import { formatLastUpdatedDate } from '../utils/formatter'

import { BotToolbar } from './BotToolbar'
import { ChatInput } from './ChatInput'
import { ChatMessages } from './ChatMessages'

type ChatBotProps = {
  bgImageDark: string
  bgImageLight: string
  botName: string
  botType: string
  chatSummaryMemory?: number
  disclaimer: DisclaimerProperty[]
  forceMarkdown?: boolean
  hasImageUpload?: boolean
  lastUpdated?: string
  maxInput: number
  setChatSummary?: (botName: string, summary: string | null, chatSummaryMemory: number) => void
  showSettings: boolean
  subheader: SubheaderProperty[][]
  submit: SubmitHandler<BotFormValues & BotFilterValues>
} & LeftDrawerProps &
  RightDrawerProps

export const ChatBot = (props: ChatBotProps) => {
  const {
    bgImageDark,
    bgImageLight,
    botName,
    botType,
    chatSummaryMemory,
    disclaimer,
    forceMarkdown,
    lastUpdated,
    leftDrawer,
    maxInput,
    rightDrawer,
    setChatSummary,
    showSettings,
    subheader,
    submit,
  } = props

  const { isTablet } = useThemeContext()
  const { isLightMode } = useSettingsContext()
  const { isMounted } = useIsMounted()
  const { t, language, getTForNS } = useI18Context()
  const { conversations, deleteConversations, getCurrentConversationIDForBot } = useMessagesContext()
  const { deleteDocumentContent } = useUploadContext()
  const { deleteSelectedFilterValues, deleteSelectedFormValues } = useFiltersContext()
  const { updateSettingsLanguage } = useConversationSettingsContext()
  const { logUIErrorEvent } = useEventLogger()

  const botT = getTForNS(botName.toLocaleLowerCase())
  const assetsPrefix = `${botName.toLocaleLowerCase()}/assets`

  const lastUpdatedDate = useMemo(() => {
    // Months are zero-based, so 8 represents September
    if (lastUpdated) {
      try {
        return formatLastUpdatedDate(lastUpdated, language)
      } catch (e) {
        logUIErrorEvent({
          bot: botName,
          error: e as Error,
          errorMessage: 'last-updated-date-generate-error',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, lastUpdated, logUIErrorEvent])

  // Delete empty conversations that are created as placeholders when changing bots
  useEffect(() => {
    const emptyMessageConversationIDs: number[] = []

    for (const key in conversations) {
      const conversationRecord = conversations[key]
      for (const numKey in conversationRecord) {
        const conversation = conversationRecord[parseInt(numKey)]
        if (conversation.messages.length === 0 && conversation.displayName === null) {
          emptyMessageConversationIDs.push(parseInt(numKey))
        }
      }
    }

    if (emptyMessageConversationIDs.length) {
      // Need to delete all values in providers that used to belong to the conversation ID that has now been deleted
      deleteConversations(emptyMessageConversationIDs)
      deleteDocumentContent(emptyMessageConversationIDs)
      deleteSelectedFormValues(emptyMessageConversationIDs)
      deleteSelectedFilterValues(emptyMessageConversationIDs)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName])

  useEffect(() => {
    if (showSettings) {
      updateSettingsLanguage(botName, getCurrentConversationIDForBot(botName) || 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <Box
      backgroundImage={`${
        isLightMode ? `${assetsPrefix}/${bgImageLight}.webp` : `${assetsPrefix}/${bgImageDark}.webp`
      }`}
      className="flex flex-col h-full overflow-x-hidden bg-cover"
    >
      <Box
        className={`${leftDrawer?.isOpen || rightDrawer?.isOpen ? 'width-layout' : 'width-layout-xl'} ${
          isMounted && 'transition-[width] duration-300'
        } flex items-center justify-between my-2 text-sm md:text-base`}
      >
        <Box className={`text-bot-header ${isLightMode ? 'text-kpmgBlue' : 'text-white'}`}>
          <Text
            as="h1"
            className={`${
              botT('header').length > 35 ? 'text-base md:text-lg lg:text-xl' : 'text-lg md:text-xl lg:text-2xl'
            } font-opensanscondensed`}
            tabIndex={0}
            test-id={`${botName}-bot-header`}
          >
            {botT('header')}
          </Text>
          {lastUpdatedDate && isTablet && (
            <Text className="text-xs">
              {t('generic.lastUpdated')} {lastUpdatedDate}
            </Text>
          )}
        </Box>
        <BotToolbar
          botName={botName}
          chatSummaryMemory={chatSummaryMemory}
          disclaimer={disclaimer}
          lastUpdated={lastUpdated}
          leftDrawer={leftDrawer}
          rightDrawer={rightDrawer}
          showSettings={showSettings}
          setChatSummary={setChatSummary}
        />
      </Box>
      <Box className="relative flex flex-col flex-grow overflow-hidden">
        {leftDrawer && <Box>{leftDrawer.drawer}</Box>}
        {rightDrawer && <Box>{rightDrawer.drawer}</Box>}
        <Box
          className={`${leftDrawer?.isOpen || rightDrawer?.isOpen ? 'width-layout' : 'width-layout-xl'} ${
            isMounted && 'transition-[width] duration-300'
          } flex flex-col flex-grow overflow-hidden`}
        >
          <ChatMessages
            botName={botName}
            botType={botType}
            chatSummaryMemory={chatSummaryMemory ?? 10}
            disclaimer={disclaimer}
            forceMarkdown={forceMarkdown}
            subheader={subheader}
            submit={submit}
          />
          <ChatInput botName={botName} maxInput={maxInput} submit={submit} />
        </Box>
      </Box>
    </Box>
  )
}
