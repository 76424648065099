import { createContext, Dispatch, SetStateAction, useCallback, useContext, useRef, useState } from 'react'
import { AnySchema, ValidationError } from 'yup'

import { sanitizeChatInput } from 'utils/chatInputSanitize'

import type {
  BotFilterValues,
  BotFormValues,
  ChatDocument,
  ChatMessage,
  ChatRequestParams,
  ChildrenProps,
  DocumentUrlItem,
  StreamingStep,
} from 'types/types'

import { useEventLogger } from '../hooks/useEventLogger'
import { FirstFetchTimeoutError, NetworkError } from '../utils/appError'
import { updateBotMessagesWithError } from '../utils/handleErrors'
import { extractHeaders } from '../utils/http/http'
import { fetchDataStream, timeOutAction } from '../utils/http/methods'
import { extractData, StreamBuffer } from '../utils/streaming'

import { useAuthContext } from './AuthProvider'
import { useConfigContext } from './ConfigurationProvider'
import { useMessagesContext } from './MessageProvider'

type BotContextType = {
  chatRequest: (payload: ChatRequestParams) => Promise<void>
  documentUrlsArray: Record<string, DocumentUrlItem[]>
  getStreamingStatus: (botName: string) => { status: boolean; step: StreamingStep } | undefined
  isFetchingChart: Record<string, boolean>
  setAbortStatus: (botName: string, status: boolean) => void
  setDocumentUrlsArray: Dispatch<SetStateAction<Record<string, DocumentUrlItem[]>>>
  setIsFetchingChart: Dispatch<SetStateAction<Record<string, boolean>>>
  useYupValidationResolver: (validationSchema: AnySchema) => (data: BotFormValues) => Promise<{
    values: BotFormValues
    errors: { [key: string]: string }
  }>
}

export const BotContext = createContext<BotContextType>({} as BotContextType)

export const BotProvider = ({ children }: ChildrenProps) => {
  const { getToken } = useAuthContext()
  const { API_ENDPOINT, API_TIMEOUT } = useConfigContext()
  const { getMessages, getSummary, setStreamingMessage, setStreamingSummary } = useMessagesContext()
  const { logUIErrorEvent, logUIInfoEvent } = useEventLogger()

  const [streamingStatuses, setStreamingStatuses] = useState<Record<string, { status: boolean; step: StreamingStep }>>(
    {}
  )

  const [isFetchingChart, setIsFetchingChart] = useState<Record<string, boolean>>({})
  const [documentUrlsArray, setDocumentUrlsArray] = useState<Record<string, DocumentUrlItem[]>>({})

  const stopStreamingRef = useRef<Record<string, boolean>>({})

  const firstTimeout = API_TIMEOUT * 1000

  const useYupValidationResolver = (validationSchema: AnySchema) =>
    useCallback(
      async (data: BotFormValues) => {
        try {
          const values = await validationSchema.validate(
            { ...data, userQuery: data.userQuery },
            {
              abortEarly: false,
            }
          )

          return {
            values,
            errors: {},
          }
        } catch (errors) {
          const errs = errors as ValidationError

          return {
            values: {},
            errors: errs.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path as string]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [validationSchema]
    )

  const getStreamingStatus = useCallback(
    (botName: string) => {
      return streamingStatuses?.[botName]
    },
    [streamingStatuses]
  )

  const setStreamingStatus = useCallback(
    (botName: string, status: boolean, step: StreamingStep) => {
      setStreamingStatuses((streams) => ({
        ...streams,
        [botName]: { status, step },
      }))
    },
    [setStreamingStatuses]
  )

  const setAbortStatus = useCallback((botName: string, status: boolean) => {
    stopStreamingRef.current = { ...stopStreamingRef.current, [botName]: status }
  }, [])

  const abortChatRequest = (botName: string, status: boolean, rId: string, duration: number) => {
    setStreamingMessage((currentMessage) => {
      const lastMessage = currentMessage[botName]

      if (!lastMessage) return { ...currentMessage, [botName]: lastMessage }

      const copy = { ...lastMessage }
      copy.cancelled = true

      return { ...currentMessage, [botName]: { ...copy } }
    })

    setAbortStatus(botName, status)

    logUIInfoEvent({
      bot: botName,
      message: 'chat-request-cancelled-by-user',
      requestId: rId,
      duration,
    })
  }

  const buildDocuments = useCallback((chatDocuments: BotFilterValues): ChatDocument[] => {
    return Object.entries(chatDocuments.filters).map(([key, value]) => ({
      category: key,
      docList: value.items.map((item) => item.value),
      include: value.isChecked,
    }))
  }, [])

  const chatRequest = async ({
    chatType,
    config,
    context: docContext,
    filters,
    language,
    previousAnswer,
    respondingTo,
    prompt,
    settings,
    summary,
    engagementId,
  }: ChatRequestParams) => {
    const startTimeFetch = new Date().getTime()
    const rId = `${Math.random()}-${new Date().getTime()}`
    const sanitizedPrompt: ChatMessage = {
      ...prompt,
      content: sanitizeChatInput(prompt.content),
    }

    let endpoint = ''

    const { botName, shouldGenerateChatSummary } = config

    switch (chatType) {
      case 'general': {
        endpoint = '/chatapi/chat'
        break
      }
      case 'upload': {
        endpoint = '/chatapi/contextChat'
        break
      }
      case 'assessment': {
        endpoint = '/rcassessmentapi/docChat'
        break
      }
      default: {
        endpoint = '/chatapi/docChat'
      }
    }

    const defaultAssistantMessage: ChatMessage = {
      content: '',
      role: 'assistant',
      messageProperties: {
        line: {
          isFetching: false,
          isGenerateError: false,
          isAPIError: false,
          isTimeoutError: false,
        },
        bar: {
          isFetching: false,
          isGenerateError: false,
          isAPIError: false,
          isTimeoutError: false,
        },
        pie: {
          isFetching: false,
          isGenerateError: false,
          isAPIError: false,
          isTimeoutError: false,
        },
        table: {
          isFetching: false,
          isGenerateError: false,
          isAPIError: false,
          isTimeoutError: false,
        },
      },
      ...(chatType !== 'general' ? { respondingTo: sanitizedPrompt } : {}),
    }

    try {
      setStreamingStatus(botName, true, 'initial')
      // Add template for new message
      // Need to check if botName in prevMessages because we don't initialize messageData with botNames

      setStreamingMessage((prev) => ({ ...prev, [botName]: defaultAssistantMessage }))

      const authToken = await getToken()
      let fetchResult: {
        response: Promise<Response>
        signalController: AbortController
      }

      if (shouldGenerateChatSummary) {
        // If we have a summary of the conversation, set it inside of the payload
        const conversationSummary = getSummary(botName)
        if (conversationSummary) {
          summary = `${conversationSummary.replace(/\n/g, ' ')}`
        }

        const messages = getMessages(botName)

        // Below is the logic to find the last entry in messageData that equals the role 'assistant' (previous answer) and isn't an error
        const lastAssistantMessage: ChatMessage | undefined = messages.findLast(
          (message) => message.role === 'assistant' && !message.error
        )

        // If we have an assistant message (previous answer), set it inside of the payload
        if (lastAssistantMessage) {
          if (lastAssistantMessage.delimiter) {
            previousAnswer = lastAssistantMessage.content.split(lastAssistantMessage.delimiter)[0].replace(/\n/g, ' ')
          } else {
            previousAnswer = lastAssistantMessage.content
          }
        }
      }

      let payloadDocuments: ChatDocument[] = []
      if (filters) {
        payloadDocuments = buildDocuments(filters) || []
      }

      if (chatType === 'general') {
        fetchResult = await fetchDataStream({
          url: `${API_ENDPOINT}${endpoint}`,
          token: authToken,
          payload: {
            botName,
            prompt: sanitizedPrompt,
            language,
            summary,
            previousAnswer,
            settings: settings || undefined,
          },
          rId,
        })
      } else if (chatType === 'upload') {
        fetchResult = await fetchDataStream({
          url: `${API_ENDPOINT}${endpoint}`,
          token: authToken,
          payload: {
            botName,
            prompt: sanitizedPrompt,
            language,
            // Need to add undefined check to satisfy type checking. But docContext is always defined when chatType === 'upload'
            context: {
              title: docContext?.title ?? '',
              text: docContext?.text ?? '',
            },
            settings: settings || undefined,
          },
          rId,
        })
      } else if (chatType === 'assessment') {
        fetchResult = await fetchDataStream({
          url: `${API_ENDPOINT}${endpoint}`,
          token: authToken,
          payload: {
            prompt: respondingTo ?? sanitizedPrompt,
            language,
            documents: payloadDocuments,
            // TODO: Fix this definition
            engagementId: engagementId ?? '',
          },
          rId,
        })
      } else {
        fetchResult = fetchDataStream({
          url: `${API_ENDPOINT}${endpoint}`,
          token: authToken,
          payload: {
            botName,
            prompt: respondingTo ?? sanitizedPrompt,
            language,
            documents: payloadDocuments,
          },
          rId,
        })
      }

      const response: Response = await timeOutAction(fetchResult.response, firstTimeout, () => {
        fetchResult.signalController.abort(`Aborted after ${firstTimeout}`)
        throw new FirstFetchTimeoutError('Aborted', undefined, firstTimeout)
      })
      let responseAnswer = ''
      let responseDelimiter = ''
      let hasContentStreamedIn = false
      let hasSummaryStreamedIn = false
      if (response.body) {
        const buffer: StreamBuffer = {
          lastChunk: '',
          chunkProcessed: 0,
        }

        const reader = response.body.getReader()

        let done

        while (!done) {
          if (stopStreamingRef.current[botName]) {
            reader.cancel(`Aborted after ${new Date().getTime() - startTimeFetch}`)
            const endTimeFetch = new Date().getTime()
            abortChatRequest(botName, false, rId, endTimeFetch - startTimeFetch)
          } else if (responseAnswer.length && !hasContentStreamedIn) {
            setStreamingStatus(botName, true, 'content')
            hasContentStreamedIn = true
          } else if (responseDelimiter) {
            const lines = responseAnswer.split('\n')
            const lastValue = lines[lines.length - 1]
            const answerHasDelimiter = responseAnswer.includes(responseDelimiter)
            // If the delimiter starts with the lastValue, then we have a partial delimiter being rendered to the screen
            const hasPartialDelimiter = responseDelimiter.startsWith(lastValue)
            if (!hasSummaryStreamedIn && ((lastValue.length > 0 && hasPartialDelimiter) || answerHasDelimiter)) {
              // If we have a partial/full delimiter, update the streaming status
              setStreamingStatus(botName, true, 'summary')
              hasSummaryStreamedIn = true
            } else if (hasSummaryStreamedIn && lastValue.length > 0 && !hasPartialDelimiter && !answerHasDelimiter) {
              // If we falsely identified a partial delimiter (E.g., Answer includes "1" for an ordered list item and the delimiter starts with "1"), update the streaming status
              setStreamingStatus(botName, true, 'content')
              hasSummaryStreamedIn = false
            }
          }
          // eslint-disable-next-line no-loop-func
          const { value, done: status } = await reader.read()

          if (value) {
            const { answer, context, delimiter } = extractData(value, buffer)
            responseAnswer += answer
            responseDelimiter += delimiter

            setStreamingMessage((currentStreamingMessage) => {
              try {
                const lastMessage = currentStreamingMessage[botName]

                if (!lastMessage) return { ...currentStreamingMessage, [botName]: lastMessage }
                const copy = { ...lastMessage }
                copy.content += answer
                copy.docContext = copy.docContext ? (copy.docContext += context) : context

                // general specific
                copy.delimiter = copy.delimiter ? (copy.delimiter += delimiter) : delimiter

                return { ...currentStreamingMessage, [botName]: copy }
              } catch (e) {
                const lastMessage = currentStreamingMessage[botName]
                if (!lastMessage) return { ...currentStreamingMessage, [botName]: lastMessage }

                const endTimeFetch = new Date().getTime()

                logUIErrorEvent({
                  api: endpoint,
                  bot: botName,
                  duration: endTimeFetch - startTimeFetch,
                  error: e as Error,
                  errorMessage: 'message-chunk-update-error',
                  requestId: rId,
                })

                return { ...currentStreamingMessage, [botName]: updateBotMessagesWithError(lastMessage, e) }
              }
            })
          }
          done = status
        }
        if (responseAnswer === '') {
          const endTimeFetch = new Date().getTime()
          // Throw an error that indicates we got back an empty string from processing the chunks
          throw new NetworkError(
            'Empty message being returned as a response.',
            endTimeFetch - startTimeFetch,
            extractHeaders(response),
            response.status ? response.status.toString() : 'unknown'
          )
        }
      }

      if (shouldGenerateChatSummary) {
        // Split the response by the delimiter to get the summary. Then remove all leading "\n" characters
        const responseSummary = responseAnswer.split(responseDelimiter)?.[1]?.replace(/^\n+/, '')
        // Need to do a check to ensure that index 1 of the split doesn't return undefined
        // Need to also check if the summary contains values, and not empty by checking the length
        if (responseSummary && typeof responseSummary === 'string' && responseSummary.replace(/\n/g, '').length > 0) {
          setStreamingSummary((prev) => ({ ...prev, [botName]: responseSummary }))
        }
      }
    } catch (e) {
      const durationToFail = new Date().getTime() - startTimeFetch
      logUIErrorEvent({
        api: endpoint,
        bot: botName,
        duration: durationToFail,
        error: e as Error,
        errorMessage: 'chat-request-streaming-error',
        requestId: rId,
        data: { startTimeFetch },
      })
      setStreamingMessage((currentMessage) => {
        const lastMessage = currentMessage[botName]
        if (!lastMessage) return { ...currentMessage, [botName]: lastMessage }

        return { ...currentMessage, [botName]: updateBotMessagesWithError(lastMessage, e) }
      })
    } finally {
      setStreamingStatus(botName, false, 'initial')
    }
  }

  return (
    <>
      <BotContext.Provider
        value={{
          chatRequest,
          documentUrlsArray,
          getStreamingStatus,
          isFetchingChart,
          setAbortStatus,
          setDocumentUrlsArray,
          setIsFetchingChart,
          useYupValidationResolver,
        }}
      >
        {children}
      </BotContext.Provider>
    </>
  )
}

export const useBotContext = (): BotContextType => useContext(BotContext)
