import { createContext, ReactElement, useCallback, useContext, useEffect, useState } from 'react'

import { ChildrenProps, UIClassNameConfig, UICLassNameConfigValue } from '../types/types'

import { useConfigContext } from './ConfigurationProvider'
import { useI18Context } from './i18Provider'
import { useSettingsContext } from './SettingsProvider'

type UIConfigContextType = {
  getUILabel: (key: string) => ReactElement | string
  getUIClassName: (key: string, defaultClassName?: string) => string | undefined
}

export const UIConfigContext = createContext<UIConfigContextType>({} as UIConfigContextType)

export function UIConfigProvider({ children }: ChildrenProps): JSX.Element {
  const { loadUISpecificTranslations, getTForNS, i18n, t } = useI18Context()
  const { isLightMode } = useSettingsContext()
  const { ENV_TYPE } = useConfigContext()
  const [loadedUIConfigs, setLoadedUIConfigs] = useState(false)
  const [uiOverrideStyles, setUIOverrideStyles] = useState({})
  const uiT = getTForNS(`appconfiguration/${ENV_TYPE}`)

  useEffect(() => {
    const fetchData = async () => {
      const uiStylesOverride = await fetch(/* webpackIgnore: true */ `/appconfiguration/${ENV_TYPE}/styles/styles.json`)
      await loadUISpecificTranslations(ENV_TYPE)
      setUIOverrideStyles(await uiStylesOverride.json())
      // Setting this flag ensures that we only run this useEffect ONCE on application load
      setLoadedUIConfigs(true)
    }
    if (!loadedUIConfigs) {
      fetchData()
    }
  }, [ENV_TYPE, loadUISpecificTranslations, loadedUIConfigs])

  /**
   * Returns the whole object of a given ui configuration, if it exists
   * @param {string} dotNotationKey - the nesting of a json object in dot notation (eg a[b][c] -> a.b.c)
   * @returns {string | undefined}
   */
  const getUIClassNameConfigValue = useCallback(
    (dotNotationKey: string): string | undefined => {
      const keys = dotNotationKey.split('.')
      const uiClassNameConfigValue: UICLassNameConfigValue = keys.reduce(
        (currentVal: UIClassNameConfig | UICLassNameConfigValue, key) => {
          if (!currentVal || 'light' in currentVal) {
            return currentVal
          }
          return currentVal[key]
        },
        uiOverrideStyles ?? {}
      ) as UICLassNameConfigValue

      // if the type of the value is string at the end, then that means we found the desired value
      return uiClassNameConfigValue?.[isLightMode ? 'light' : 'dark']
    },
    [isLightMode, uiOverrideStyles]
  )

  /**
   * Returns the label of a given ui configuration
   * @param {string} key - the nesting of a json object in dot notation (eg a[b][c] -> a.b.c)
   * @returns {string} - the label as a string
   */
  const getUILabel = useCallback(
    (key: string): string => {
      // Return the value that is held in the ui namespace
      // Automatically uses the default namespace as a fallback if it doesn't exist
      return i18n.exists(key, { ns: `appconfiguration/${ENV_TYPE}` }) ? uiT(key) : t(key)
    },
    [ENV_TYPE, i18n, t, uiT]
  )

  /**
   * Returns the className of a given ui configuration
   * @param {string} key - the nesting of a json object in dot notation (eg a[b][c] -> a.b.c)
   * @param {string} defaultClassName - a className to fall back on if the key does not exist in the configuration
   * @returns {JSX.Element | string} - the label as a string, or if loading a skeleton
   */
  const getUIClassName = useCallback(
    (key: string, defaultClassName?: string): string | undefined => {
      const className = getUIClassNameConfigValue(key)

      return className ?? defaultClassName
    },
    [getUIClassNameConfigValue]
  )

  return (
    <>
      <UIConfigContext.Provider value={{ getUILabel, getUIClassName }}>{children}</UIConfigContext.Provider>
    </>
  )
}

export const useUIConfigContext = () => useContext(UIConfigContext)
