import { ReactElement } from 'react'
import { IconButton as ChakraIconButton } from '@chakra-ui/button'
import { Tooltip, TooltipProps } from '@chakra-ui/tooltip'

import { useThemeContext } from 'providers/ThemeProvider'

import { Button } from './Button'
import { IconButton } from './IconButton'
import { TextIconButton } from './TextIconButton'

type TooltipButtonProps = {
  button:
    | ReactElement<typeof ChakraIconButton>
    | ReactElement<typeof Button>
    | ReactElement<typeof IconButton>
    | ReactElement<typeof TextIconButton>
} & Omit<TooltipProps, 'children'>

export const TooltipButton = (props: TooltipButtonProps) => {
  const { isTablet } = useThemeContext()
  const { button, placement = 'top', isDisabled = !isTablet } = props

  return (
    <Tooltip
      {...props}
      className="text-xs text-black bg-kpmgGray5"
      isDisabled={isDisabled}
      openDelay={200}
      placement={placement}
      tabIndex={0}
    >
      {button}
    </Tooltip>
  )
}
