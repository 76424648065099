import React from 'react'
import { Text } from '@chakra-ui/layout'

type TableCommonProps<T> = {
  headers: {
    field: string
    headerName: string
  }[]
  data: T[]
}

type TableCellCommonProps = {
  cellValue: string
  cellKey: string
  row?: { [key: string]: { value: string; label: string } | string | number | boolean }
  rowIndex: number
}

type TableStyles = {
  tableCellStyle?: 'default'
}

type TableProps<T> = TableCommonProps<T> & TableStyles
type TableCellProps = TableCellCommonProps & TableStyles
type TableHeaderProps = TableCellCommonProps & TableStyles

export function ResponsiveTable<
  T extends { [key: string]: { value: string; label: string } | string | number | boolean },
>({ data, headers, tableCellStyle = 'default' }: TableProps<T>) {
  return (
    <table id="myTable1" className="w-full text-left">
      <thead className="text-white bg-kpmgBlue">
        <tr>
          {headers.map((header, index) => (
            <th key={index} className="p-2" tabIndex={0}>
              <TableHeader
                cellKey={header.field}
                cellValue={header.headerName}
                tableCellStyle={tableCellStyle}
                rowIndex={index}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row: T, rowIndex) => {
          return (
            <tr key={rowIndex} className={`${rowIndex % 2 ? 'bg-gray-100 ' : 'bg-gray-200'} hover:bg-slate-300`}>
              {headers.map((header, index) => {
                const rowValue = row[header.field]
                const cellValue = `${rowValue}`

                return (
                  <td key={`${header.field}__${index}`} tabIndex={0} data-label={header.headerName} className="p-2">
                    <TableCell cellKey={header.field} cellValue={cellValue} row={row} rowIndex={rowIndex} />
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function TableHeader({ cellValue }: TableHeaderProps) {
  return <Text>{cellValue}</Text>
}

function TableCell({ cellKey, cellValue }: TableCellProps) {
  return <Cell cellKey={cellKey} cellValue={cellValue} />
}

type CellValueProps = {
  cellKey: string
  cellValue: string
}

function Cell({ cellValue = '' }: CellValueProps) {
  return <>{cellValue}</>
}
