import { useCallback } from 'react'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { IconButton } from '@chakra-ui/button'
import { Box } from '@chakra-ui/layout'
import { API } from '@kleo/types'

import { useI18Context } from '../providers/i18Provider'
import { useSettingsContext } from '../providers/SettingsProvider'
import { useThemeContext } from '../providers/ThemeProvider'

import { TooltipButton } from './buttons/TooltipButton'

type BaseOpenFeedbackButtonProps = {
  onClick: () => void
  isDisabled?: boolean
  feedback?: API.ChatMessageFeedback
}

export const OpenFeedbackButton = (props: BaseOpenFeedbackButtonProps) => {
  const { onClick, isDisabled = false, feedback } = props
  const { isLightMode, isChatFullScreen } = useSettingsContext()
  const { isTablet } = useThemeContext()
  const { t } = useI18Context()

  const onClickHandler = useCallback(() => {
    onClick()
  }, [onClick])

  // If feedback has been submitted, show either the Like/Dislike icon depending on response
  if (feedback) {
    return (
      <Box className="flex items-center justify-center p-2">
        {feedback === 'good' ? (
          <BiSolidLike
            className={isChatFullScreen ? 'text-kpmgPacificBlue' : 'text-kpmgLightBlue'}
            size={isTablet ? 32 : 16}
          />
        ) : (
          <BiSolidDislike
            className={isChatFullScreen ? 'text-kpmgPacificBlue' : 'text-kpmgLightBlue'}
            size={isTablet ? 32 : 16}
          />
        )}
      </Box>
    )
  }

  // If feedback has not been submitted, show the combined Like/Dislike button to allow feedback submission
  return (
    <TooltipButton
      label={!isDisabled && t('generic.feedbackTooltip')}
      isDisabled={!isTablet}
      // If the padding or size value ever changes for this IconButton, need to adjust the widths defined in ChartAccordion and UserMessage
      button={
        <IconButton
          aria-label="open-feedback"
          className={`bg-transparent p-1.5 focus:shadow-none rounded-full ${
            isLightMode ? 'focus:ring-light' : 'focus:ring-dark'
          } ${isChatFullScreen ? 'border border-kpmgGray4 border-opacity-50 hover:bg-kpmgGray45' : 'hover:bg-opacity-10 hover:bg-kpmgGray5'}`}
          isDisabled={isDisabled}
          onClick={() => onClickHandler()}
          size={isTablet ? 'lg' : 'sm'}
          tabIndex={0}
          icon={
            <Box className="relative w-full">
              <Box className="flex justify-start">
                {/* If the size value ever changes for this Icon, need to adjust the widths defined in ChartAccordion and UserMessage */}
                <BiSolidLike className={isChatFullScreen ? 'text-kpmgBlue' : 'text-white'} size={isTablet ? 18 : 10} />
              </Box>
              <Box className="flex justify-end">
                {/* If the size value ever changes for this Icon, need to adjust the widths defined in ChartAccordion and UserMessage */}
                <BiSolidDislike
                  className={isChatFullScreen ? 'text-kpmgBlue' : 'text-white'}
                  size={isTablet ? 18 : 10}
                />
              </Box>
              <Box
                className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[1px] h-full rotate-45 ${
                  isChatFullScreen ? 'bg-kpmgBlue' : 'bg-white'
                }`}
              />
            </Box>
          }
        />
      }
    />
  )
}
