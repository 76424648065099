import React, { useContext, useEffect, useState } from 'react'
import { initReactI18next, useTranslation } from 'react-i18next'
import i18n, { i18n as i18nInstance, TFunction } from 'i18next'
import Backend from 'i18next-http-backend'

import { ChildrenProps } from '../types/types'

import { useConfigContext } from './ConfigurationProvider'

const commonTranslations = ['translation', 'termsAndConditions']
const httpBackend = new Backend(undefined, {
  loadPath: (lngs: string[], namespaces: string[]): string => {
    if (commonTranslations.includes(namespaces[0])) {
      return `/locales/${lngs}/${namespaces[0]}.json`
    } else {
      //Assuming its bot's namespace
      return `/${namespaces[0]}/assets/locales/${lngs}/translation.json`
    }
  },
})

i18n
  .use(httpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    debug: false,
    supportedLngs: ['en-US', 'fr-CA'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
i18n.loadLanguages(['en-US', 'fr-CA'])

export type I18ContextType = {
  language: string
  languageAbbreviation: string
  LANGUAGES: string[]
  t: TFunction<'translation'>
  tc: TFunction<'termsAndConditions'>
  getTForNS: (ns: string) => TFunction
  changeLanguage: (lang: string) => void
  loadBotTranslations: (botName: string) => Promise<void>
  loadUISpecificTranslations: (configurationName: 'standard' | 'as') => Promise<void>
  i18n: i18nInstance
}

export const I18Context = React.createContext<I18ContextType>({} as I18ContextType)

export function I18Provider({ children }: ChildrenProps) {
  const langFromQuery = new URL(window.location.href).searchParams.get('lang') || 'en-US'
  const [language, setLanguage] = useState<string>(langFromQuery)
  const [languageAbbreviation, setLanguageAbbreviation] = useState<string>(langFromQuery.slice(0, 2))
  const { LANGUAGES } = useConfigContext()
  const { t, i18n } = useTranslation()
  const { t: tc } = useTranslation('termsAndConditions')

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang, function () {
      setLanguage(lang)
      setLanguageAbbreviation(lang.slice(0, 2))
    })
  }
  const getTForNS = (ns: string) => {
    return i18n.getFixedT(language, ns, '')
  }
  const loadBotTranslations = async (botName: string) => {
    await i18n.loadNamespaces(botName.toLocaleLowerCase())
  }

  const loadUISpecificTranslations = async (configurationName: 'standard' | 'as') => {
    await i18n.loadNamespaces(`appconfiguration/${configurationName}`)
  }

  return (
    <>
      <I18Context.Provider
        value={{
          changeLanguage,
          getTForNS,
          language,
          languageAbbreviation,
          LANGUAGES,
          t,
          tc,
          loadBotTranslations,
          loadUISpecificTranslations,
          i18n,
        }}
      >
        {children}
      </I18Context.Provider>
    </>
  )
}

export const useI18Context = (): I18ContextType => useContext(I18Context)
