import { Suspense, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { Spinner } from '@chakra-ui/spinner'

import { useAuthContext } from 'providers/AuthProvider'

import { ChildrenProps } from '../types/types'

import { AuthenticationSpinner } from './AuthenticationSpinner'

export const SuspenseFullPage = ({ children }: ChildrenProps) => {
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center flex-grow text-2xl">
          <Spinner size="lg" />
        </div>
      }
    >
      {children}
    </Suspense>
  )
}

export const SuspenseAuthFullPage = ({ children }: ChildrenProps) => {
  return (
    <>
      <AuthenticatedTemplate>
        <Suspense
          fallback={
            <div className="flex items-center justify-center flex-grow text-2xl">
              <Spinner size="lg" />
            </div>
          }
        >
          {children}
        </Suspense>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticationSpinner />
      </UnauthenticatedTemplate>
    </>
  )
}
export const SuspenseRedirectFullPage = ({ children }: ChildrenProps) => {
  const { areTermsAccepted } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!areTermsAccepted) {
      navigate('/')
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AuthenticatedTemplate>
        <Suspense
          fallback={
            <div className="flex items-center justify-center flex-grow text-2xl">
              <Spinner size="lg" />
            </div>
          }
        >
          {children}
        </Suspense>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticationSpinner />
      </UnauthenticatedTemplate>
    </>
  )
}
