import { useCallback, useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { UploadBot } from 'forms/UploadBot'
import * as yup from 'yup'

import { useBotContext } from 'providers/BotProvider'
import { useConversationSettingsContext } from 'providers/ConversationSettingsProvider'
import { useFiltersContext } from 'providers/FiltersAndFormsProvider'
import { useI18Context } from 'providers/i18Provider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useUploadContext } from 'providers/UploadProvider'

import type { BotFilterValues, BotFormValues, ChatMessage, ContextChatRequest, FEUIConfig } from 'types/types'

export const Upload = ({ config }: { config: FEUIConfig }) => {
  const { chatRequest, useYupValidationResolver } = useBotContext()
  const { addMessage, getCurrentConversationIDForBot } = useMessagesContext()
  const { getConversationSettings } = useConversationSettingsContext()
  const { t } = useI18Context()
  const { uploadFileRequest, docContents } = useUploadContext()
  const { getSelectedFormValues } = useFiltersContext()

  const cssPath = `${config.botName.toLocaleLowerCase()}/assets/bot.css`

  const currentConversationID = useMemo(() => {
    return getCurrentConversationIDForBot('UPLOADBOT') ?? 0
  }, [getCurrentConversationIDForBot])

  const schema = yup.object().shape({
    userQuery: yup.string().when('clicked', {
      is: (clicked: ChatMessage | null) => !clicked,
      then: yup
        .string()
        .test({
          message: t('generic.pleaseUploadFile'),
          test: () => docContents[currentConversationID] !== undefined,
        })
        .max(config.maxInput, t('generic.maxLengthError'))
        .test({
          message: t('generic.messageRequiredDefault'),
          test: (userQuery) => {
            return userQuery?.trim().length !== undefined && userQuery?.trim().length >= 1
          },
        }),
    }),
  })

  const resolver = useYupValidationResolver(schema)

  const methods = useForm<BotFormValues>({
    defaultValues: getSelectedFormValues(config.botName, currentConversationID),
    resolver,
  })

  const handleChatSubmission = useCallback(
    (payload: ContextChatRequest) => {
      const conversationID = getCurrentConversationIDForBot(config.botName) || 0
      const settings = getConversationSettings(config.botName, conversationID)
      chatRequest({
        ...payload,
        chatType: 'upload',
        settings: settings
          ? {
              input: { voice: settings.input.voice.value, temperature: settings.input.temperature.value },
            }
          : undefined,
      })
    },
    [chatRequest, config.botName, getConversationSettings, getCurrentConversationIDForBot]
  )

  const handleChatSubmit: SubmitHandler<BotFormValues & BotFilterValues> = (
    values: BotFormValues & BotFilterValues
  ) => {
    const { userQuery } = values

    const prompt: ChatMessage = {
      content: userQuery.trim(),
      role: 'user',
    }

    //  Validation has passed at this point so we can add the new input to the messages if it wasn't generated by a click
    if (!values.clicked) {
      addMessage(config.botName, prompt)
    }

    if (docContents) {
      const documentForSubmission = docContents[currentConversationID]
      if (documentForSubmission) {
        handleChatSubmission({
          config,
          language: 'EN',
          prompt,
          context: { title: documentForSubmission.title, text: documentForSubmission.text },
        })
      }
    }
  }

  const handleFileSubmit = (files: File[]) => {
    const currentConversationID = getCurrentConversationIDForBot('UPLOADBOT')
    if (files?.length) {
      uploadFileRequest(files[0], currentConversationID)
    }
  }

  return (
    <>
      {config.hasCustomCSS && <link rel="stylesheet" type="text/css" href={cssPath} />}
      <FormProvider {...methods}>
        <UploadBot config={config} chatQuerySubmit={handleChatSubmit} fileSubmit={handleFileSubmit} />
      </FormProvider>
    </>
  )
}
