import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ResizeTextarea from 'react-textarea-autosize'
import { Textarea, TextareaProps } from '@chakra-ui/textarea'

const AutoResizeTextarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const { control } = useFormContext()
  return (
    <Controller
      name="userQuery"
      control={control}
      rules={{ required: false }}
      render={({ field }) => (
        <Textarea
          {...field}
          name="userQuery"
          ref={ref}
          onPaste={props.onPaste}
          aria-label="sendMessage"
          className={props.className}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          placeholder={props.placeholder}
          rows={1}
          minH="unset"
          overflow="hidden"
          w="100%"
          resize="none"
          minRows={1}
          as={ResizeTextarea}
          test-id="userQuery-textarea"
        />
      )}
    />
  )
})

export { AutoResizeTextarea }
