import { Fragment, KeyboardEvent, MutableRefObject, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { BiCheck, BiClipboard } from 'react-icons/bi'
import { useClipboard } from '@chakra-ui/hooks'
import { Box, Text } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/tooltip'

import { useMatchedElements } from 'hooks/useMatchedElements'

import { useBotContext } from 'providers/BotProvider'
import { useBotSpecificContext } from 'providers/BotSpecificProvider'
import { useFiltersContext } from 'providers/FiltersAndFormsProvider'
import { useI18Context } from 'providers/i18Provider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useSettingsContext } from 'providers/SettingsProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import type {
  BotFilterValues,
  BotFormValues,
  ChatMessage,
  ChatMessageError,
  ConversationSpecificSettings,
  DisclaimerProperty,
  SearchFilterItem,
} from 'types/types'

import { BotVerifiedMessage } from '../BotVerifiedMessage'
import { DotDotDot } from '../DotDotDot'
import { Link } from '../Link'
import { MarkdownRenderer } from '../Markdown'

import { Disclaimer } from './Disclaimer'
import { TemperatureVoiceControl } from './TemperatureVoiceControl'

type ContentProps = {
  botName: string
  botType: string
  cancelled?: boolean
  content: string
  delimiter?: string
  disclaimer: DisclaimerProperty[]
  docContext?: string
  error?: ChatMessageError
  forceMarkdown?: boolean
  hasBarChart: boolean
  hasLineChart: boolean
  hasPieChart: boolean
  hasTable: boolean
  index: number
  scrollRef: MutableRefObject<HTMLDivElement | null>
  submit: SubmitHandler<BotFormValues & BotFilterValues>
  tempVoiceControl?: ConversationSpecificSettings
}

export const Content = (props: ContentProps) => {
  const {
    botName,
    botType,
    cancelled,
    content,
    delimiter,
    disclaimer,
    docContext,
    error,
    hasBarChart,
    hasLineChart,
    hasPieChart,
    hasTable,
    index: messageIndex,
    scrollRef,
    submit,
    tempVoiceControl,
    forceMarkdown,
  } = props

  const { handleSubmit, setValue } = useFormContext<BotFormValues & BotFilterValues>()
  const { t } = useI18Context()
  const { isChatFullScreen } = useSettingsContext()
  const { isTablet } = useThemeContext()
  const { documentUrlsArray } = useBotContext()
  const { getCurrentConversationIDForBot, conversations } = useMessagesContext()
  const { isFetchingChart, isStreaming, urlLinks, functions } = useBotSpecificContext()
  const { getAvailableFilterOptions, getDefaultFilterValues, uncheckAllFilterValues } = useFiltersContext()

  const clipboardText = delimiter ? content.split(delimiter)[0].replace(/\n+$/, '') : content
  const { onCopy } = useClipboard(clipboardText, { format: 'text/plain' })

  const [contentToMatch, setContentToMatch] = useState<string[]>([])
  const [partsOfContentToRender, setPartsOfContentToRender] = useState<string[]>([])
  const [showCopy, setShowCopy] = useState<boolean>(true)

  const { showLinks, matchedElements } = useMatchedElements({
    botType,
    content,
    docContext,
  })

  // Function that handles the logic needed for asking a question previously asked against a specific question
  const handleDocumentNameClick = (name: string, index: number) => {
    // If we aren't streaming in a message, allow the user to ask the same question within a specific document
    if (!isStreaming) {
      const filterOptions = getAvailableFilterOptions(botName)
      if (filterOptions) {
        const filterEntries = Object.entries(filterOptions.filters)
        let matchedFilterKey: string | null = null
        let matchedFilterItem: SearchFilterItem | null = null

        // Need to loop through our available filter options so that we can map the clicked document link to it's specific category
        for (const [key, filter] of filterEntries) {
          const foundItem = filter.find((item) => item.value === name)
          if (foundItem) {
            matchedFilterItem = foundItem
            matchedFilterKey = key
            break
          }
        }

        // If we found a match for the clicked document link, run the logic for asking the same question against the matched item
        if (matchedFilterKey && matchedFilterItem) {
          const defaultFilterValues = getDefaultFilterValues(botName) || { filters: {} }
          const uncheckedFilterValues = uncheckAllFilterValues(defaultFilterValues.filters)

          // This object makes sure that for all categories, besides the one belonging to the clicked document link, that they get set to empty with the checkbox unchecked
          const updatedFilterValues = {
            ...uncheckedFilterValues,
            [matchedFilterKey]: { items: [matchedFilterItem], isChecked: true },
          }
          setValue('filters', updatedFilterValues)

          const conversationID = getCurrentConversationIDForBot(botName)
          if (conversationID) {
            setValue('clicked', conversations[botName][conversationID].messages[index])
            // This doesn't actually set the text for the text input because we don't control it using the value
            setValue('userQuery', conversations[botName][conversationID].messages[index]?.respondingTo?.content ?? '')
          }

          handleSubmit(submit)()
        }
      }
    }
  }

  useEffect(() => {
    if (matchedElements.length > 0) {
      // we have to escaped brackets, because brackets are special syntax for Regexp
      // eslint-disable-next-line no-useless-escape
      const escapedInputs = `(${matchedElements.join('|').replace(/[\$\(\)\?\+\*\{\}\^\.\[\]\\\/]/g, '\\$&')})`
      const chunks = content.split(new RegExp(escapedInputs, 'g'))
      setPartsOfContentToRender(chunks)
    } else {
      setPartsOfContentToRender([content])
    }
  }, [content, matchedElements])

  useEffect(() => {
    // This is used to match quotations
    if (docContext) {
      const regex = /html\scontent:\s*"<html><body>([^"]+)<\/body><\/html>"/g
      const matches: string[] = []
      let match: RegExpExecArray | null

      while ((match = regex.exec(docContext)) !== null) {
        matches.push(match[1].trim().replace(/\n/g, ' '))
      }

      setContentToMatch(matches)
    }
  }, [docContext])

  useEffect(() => {
    if (showLinks && scrollRef && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [showLinks, scrollRef])

  const contentToRenderWhileStreaming = useMemo(
    () => <StreamingContentToRender content={content} delimiter={delimiter} />,
    [content, delimiter]
  )

  const shouldShowBorder =
    botType === 'upload'
      ? hasLineChart || hasBarChart || hasPieChart || hasTable
      : docContext || hasLineChart || hasBarChart || hasPieChart || hasTable

  return (
    <Box
      whiteSpace="pre-wrap"
      className={`text-sm md:text-base rounded-b-2xl rounded-tr-2xl bg-kpmgGray5 h-min ${
        shouldShowBorder && 'rounded-b-none'
      } ${!isChatFullScreen && 'p-3 lg:p-4'}`}
    >
      <Box>
        {/* copy clipboard icon */}
        <Tooltip
          className="text-xs text-black bg-kpmgGray5"
          label={showCopy ? t('generic.copyToClipboard') : t('generic.copied')}
          placement="top"
          openDelay={200}
        >
          <Box
            className={`float-right ml-2 p-2 rounded-full cursor-pointer ${
              showCopy ? 'text-black hover:bg-kpmgDarkGray5' : 'text-white hover:bg-green-500'
            }`}
            onClick={() => {
              onCopy()
              setShowCopy(false)
            }}
            onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
              if (event.key === 'Enter') {
                onCopy()
                setShowCopy(false)
              }
            }}
            onMouseLeave={() => setShowCopy(true)}
            tabIndex={0}
            as="button"
            aria-label="copyToClipBoard"
          >
            {showCopy ? <BiClipboard /> : <BiCheck />}
          </Box>
        </Tooltip>
        {/* Message Content */}
        <Box className="text-sm md:text-base">
          {/* If we are still streaming data, don't stress the system by trying to do the below splitting */}
          {botType === 'general' || botType === 'upload' ? (
            <Fragment>{contentToRenderWhileStreaming}</Fragment>
          ) : partsOfContentToRender.length > 0 ? (
            partsOfContentToRender.map((part: string, partIndex: number) => {
              const index = matchedElements.findIndex((element) => element === part)
              // -1 means that we could not find the part inside of matchedElements
              if (index === -1) {
                return (
                  <Box as="span" key={`${part}_${partIndex}_${messageIndex}`}>
                    <BotVerifiedMessage content={part} arrayToMatch={contentToMatch} forceMarkdown={forceMarkdown} />
                  </Box>
                )
              } else if (urlLinks?.baseURL && urlLinks?.fallbackURL) {
                const documentWithUrl = documentUrlsArray[botName].find((doc) => {
                  // TODO: FE team make a bot specific function out of this
                  if (functions?.matchDocumentUrl) {
                    return functions.matchDocumentUrl(doc.url, matchedElements, index)
                  } else {
                    return doc.documentTitle === matchedElements[index]
                  }
                })
                // Only run this block of code if we have all the inputs necessary to generate a URL for Knowledge base bots (e.g., the 2 URLs (baseURL and fallbackURL))
                const url = functions.documentToLink(documentWithUrl?.url, urlLinks.baseURL, urlLinks.baseURL)

                const name = functions.documentToName(part)

                if (url) {
                  let lastCharPrevContent
                  let firstCharNextContent
                  if (partIndex !== 0) {
                    const prevContent = partsOfContentToRender[partIndex - 1]
                    const nextContent = partsOfContentToRender[partIndex + 1]

                    lastCharPrevContent = prevContent.charAt(prevContent.length - 1)
                    firstCharNextContent = nextContent.charAt(0)
                  }

                  return (
                    <Tooltip
                      className="text-xs text-black bg-kpmgGray5"
                      label={t('generic.documentURLlink')}
                      placement="top"
                      openDelay={200}
                      aria-label="hyperlinkClick"
                      key={`${part}_${partIndex}_${messageIndex}`}
                      isDisabled={!isTablet}
                    >
                      {/* Need the below Box in order for the Tooltip to work */}
                      <Box as="span">
                        {/* This check is used to determine if a document name was rendered within the text and not between an instance of "" or []. If true, add spaces around the document name */}
                        <Link
                          classToAdd={`text-lightLink hover:text-lightLinkHover
                            ${(lastCharPrevContent === ' ' || lastCharPrevContent === '\u{0020}') && 'ml-1'}
                            ${(firstCharNextContent === ' ' || firstCharNextContent === '\u{0020}') && 'mr-1'}
                          `}
                          external
                          name={name.label}
                          url={url}
                        />
                      </Box>
                    </Tooltip>
                  )
                }
              }

              // If we are not at the first element
              if (partIndex !== 0) {
                const prevContent = partsOfContentToRender[partIndex - 1]
                const nextContent = partsOfContentToRender[partIndex + 1]

                const lastCharPrevContent = prevContent.charAt(prevContent.length - 1)
                const firstCharNextContent = nextContent.charAt(0)

                return (
                  <Box as="span" key={`${part}_${partIndex}_${messageIndex}`}>
                    {/* This check is used to determine if a document name was rendered within the text and not between an instance of "" or []. If true, add spaces around the document name */}
                    <Text
                      aria-label={part}
                      className={`text-black
                        ${(lastCharPrevContent === ' ' || lastCharPrevContent === '\u{0020}') && 'ml-1'}
                        ${(firstCharNextContent === ' ' || firstCharNextContent === '\u{0020}') && 'mr-1'}
                      `}
                      as="span"
                      tabIndex={0}
                    >
                      {part}
                    </Text>
                  </Box>
                )
              }
              return (
                <Box as="span" key={`${part}_${partIndex}_${messageIndex}`}>
                  <Text aria-label={part} className="text-black" as="span" tabIndex={0}>
                    {part}
                  </Text>
                </Box>
              )
            })
          ) : (
            <Fragment>{contentToRenderWhileStreaming}</Fragment>
          )}
        </Box>
        {error && (
          <Box
            whiteSpace="pre-wrap"
            className={`${content && content.trim().length > 0 && 'mt-4'} text-kpmgCobaltBlue text-sm md:text-base`}
          >
            <Text as="span" className="font-bold">
              {t('generic.systemMessage')}{' '}
            </Text>
            <Text as="span">{error.message}</Text>
          </Box>
        )}
        {cancelled && (
          <Box
            whiteSpace="pre-wrap"
            className={`${content && content.trim().length > 0 && 'mt-4'} text-kpmgCobaltBlue text-sm md:text-base`}
          >
            <Text as="span" className="font-bold">
              {t('generic.cancelledResponse')}
            </Text>
          </Box>
        )}
        {content && content.trim().length > 0 && <Disclaimer disclaimer={disclaimer} botName={botName} />}
        {tempVoiceControl && (
          <Box className="mt-2 md:mt-4">
            <TemperatureVoiceControl conversationSettings={tempVoiceControl} />
          </Box>
        )}
        {/* Show all of the relevant documents to ask the same question in at the bottom */}
        {showLinks && (
          <Box className="mt-4">
            <Text className="text-kpmgPurple">{t('generic.documentLinkClick')}</Text>
            <Box className="flex flex-wrap">
              {matchedElements.map((element: string, elementIndex: number) => {
                const documentName = functions.documentToName(element)

                return (
                  <Tooltip
                    key={`${element}_${elementIndex}_${messageIndex}`}
                    className="text-xs text-black bg-kpmgGray5"
                    label={t('generic.docHyperlinkTooltip')}
                    placement="top"
                    openDelay={200}
                    aria-label="hyperlinkClick"
                    isDisabled={!isTablet}
                  >
                    <Text
                      aria-label={element}
                      className={`underline mr-2 ${
                        isStreaming || isFetchingChart
                          ? 'text-kpmgGray3 cursor-not-allowed'
                          : 'text-kpmgBlue hover:text-kpmgCobaltBlue cursor-pointer'
                      }`}
                      onClick={() => {
                        ;(!isStreaming || !isFetchingChart) &&
                          handleDocumentNameClick &&
                          handleDocumentNameClick(element, messageIndex)
                      }}
                      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
                        if (event.key === 'Enter') {
                          ;(!isStreaming || !isFetchingChart) &&
                            handleDocumentNameClick &&
                            handleDocumentNameClick(element, messageIndex)
                        }
                      }}
                      as="span"
                      tabIndex={0}
                    >
                      {documentName.label}
                    </Text>
                  </Tooltip>
                )
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

type StreamingContentProps = {
  forceMarkdown?: boolean
  streamingMessage: ChatMessage
}

export const StreamingContent = ({
  forceMarkdown,
  streamingMessage: { content, delimiter, error },
}: StreamingContentProps) => {
  const { isChatFullScreen } = useSettingsContext()
  const { t } = useI18Context()

  const contentToRenderWhileStreaming = useMemo(
    () => <StreamingContentToRender content={content} delimiter={delimiter} forceMarkdown={forceMarkdown} />,
    [content, delimiter, forceMarkdown]
  )

  return (
    <Box
      whiteSpace="pre-wrap"
      className={`text-sm md:text-base rounded-b-2xl rounded-tr-2xl bg-kpmgGray5 h-min ${
        !isChatFullScreen && 'p-3 lg:p-4'
      }`}
    >
      <Box>
        {/* Message Content */}
        <Box className="text-sm md:text-base">{contentToRenderWhileStreaming}</Box>
        {error && (
          <Box
            whiteSpace="pre-wrap"
            className={`${content && content.trim().length > 0 && 'mt-4'} text-kpmgCobaltBlue text-sm md:text-base`}
          >
            <Text as="span" className="font-bold">
              {t('generic.systemMessage')}{' '}
            </Text>
            <Text as="span">{error.message}</Text>
          </Box>
        )}
      </Box>
      <Box className={`flex items-center ${!content.length ? '' : 'mt-8'}`}>
        <DotDotDot />
        {delimiter && content.split(delimiter)[1] && (
          <Text className="ml-2 font-medium text-kpmgPurple">{t('generic.generatingSummary')}</Text>
        )}
      </Box>
    </Box>
  )
}

const StreamingContentToRender = ({
  content,
  delimiter,
  forceMarkdown,
}: {
  content: string
  delimiter?: string
  forceMarkdown?: boolean
}) => {
  // Below 3 lines of code split the content up so that we can check if a part of the delimiter is included in the content being streamed in
  const lines = content.split('\n')
  // Can't do .pop(), need to do lines.length - 1. That's because we don't want to mutate the lines array
  const lastValue = lines[lines.length - 1]
  // If the delimiter starts with the lastValue, then we have a partial delimiter being rendered to the screen
  const renderContent = (): string => {
    // If we have a delimiter coming back with the response, handle it accordingly (a. or b.)
    if (delimiter) {
      // If the delimiter starts with the lastValue, then we have a partial delimiter being rendered to the screen
      if (lastValue.length > 0 && delimiter.startsWith(lastValue)) {
        // If we have a partial delimiter streamed back (only first few characters of it), remove the last line that contains the partial delimiter
        return lines.slice(0, -1).join('\n')
      } else {
        // Otherwise split the content by the delimiter value to hide the summary being show on screen as part of the message
        return content.split(delimiter)[0].replace(/\n+$/, '')
      }
    } else {
      // If we don't have a delimiter, just display the content
      return content
    }
  }

  return (
    <Text className="text-sm md:text-base markdown-list-renderer content-list-spacing" as="span">
      <MarkdownRenderer content={renderContent()} forceMarkdown={forceMarkdown} />
    </Text>
  )
}
