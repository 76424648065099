export type FooterLinkForLangProps = {
  [key: string]: { [key: string]: string }
}

export const helpAndSupportLinks: FooterLinkForLangProps = {
  'en-US': {
    theNewKPMGToday: 'https://kpmgcan.sharepoint.com/sites/ca-oi-comms/SitePages/Welcome-to-the-new-KPMG-Today!.aspx',
    provideFeedback:
      'https://forms.office.com/Pages/ResponsePage.aspx?id=uyT_3okgAESMjvceaAN4stXu0Qz8fr1Fv0OsQLUM-gdUQVlYWUI4SzVTWklNNDlBTDE1TEtQNEE5NyQlQCN0PWcu',
    intranetSupport:
      'https://cakpmgprod.service-now.com/its?id=sc_cat_item&sys_id=1eb1687cdb0f14507e4660ab13961944&sysparm_category=8e1e3509db8b94507e4660ab13961964',
    ITSOnline: 'https://cakpmgprod.service-now.com/its',
  },
  'fr-CA': {
    theNewKPMGToday:
      'https://kpmgcan.sharepoint.com/sites/ca-oi-comms/SitePages/fr/Welcome-to-the-new-KPMG-Today!.aspx',
    provideFeedback:
      'https://forms.office.com/Pages/ResponsePage.aspx?id=uyT_3okgAESMjvceaAN4stXu0Qz8fr1Fv0OsQLUM-gdUQVlYWUI4SzVTWklNNDlBTDE1TEtQNEE5NyQlQCN0PWcu',
    intranetSupport:
      'https://cakpmgprod.service-now.com/its?id=sc_cat_item&sys_id=1eb1687cdb0f14507e4660ab13961944&sysparm_category=8e1e3509db8b94507e4660ab13961964',
    ITSOnline: 'https://cakpmgprod.service-now.com/its',
  },
}

export const usefulLinks: FooterLinkForLangProps = {
  'en-US': {
    accessibility:
      'https://kpmgcan.sharepoint.com/sites/ca-oi-inf-Modern-Workplace/SitePages/Accessiblity-Features.aspx',
    acceptableUsePolicy:
      'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-ITS-Security/Shared%20Documents/Global%20Acceptable%20Use%20Policy%20for%20KPMG%20Canada.pdf',
    globalIntranet: 'https://spo-global.kpmg.com/sites/go-oi-bus-GQRM/IP/Intranet%20Usage%20Policy.pdf',
    ethicsAndComplianceHotline:
      'https://kpmgcan.sharepoint.com/sites/CA-OI-INF-QRM/SitePages/Ethics-and-Compliance-Hotline-Code-of-Conduct.aspx',
  },
  'fr-CA': {
    accessibility:
      'https://kpmgcan.sharepoint.com/sites/ca-oi-inf-Modern-Workplace/SitePages/fr/Accessiblity-Features.aspx',
    acceptableUsePolicy:
      'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-ITS-Security/Shared%20Documents/Global%20Acceptable%20Use%20Policy%20for%20KPMG%20Canada_FRE.pdf',
    globalIntranet: 'https://spo-global.kpmg.com/sites/go-oi-bus-GQRM/IP/Intranet%20Usage%20Policy.pdf',
    ethicsAndComplianceHotline:
      'https://kpmgcan.sharepoint.com/sites/CA-OI-INF-QRM/SitePages/fr/Ethics-and-Compliance-Hotline-Code-of-Conduct.aspx',
  },
}

export const aboutKPMGLinks: FooterLinkForLangProps = {
  'en-US': {
    KPMGcaFrancais: 'https://home.kpmg/ca/fr/home.html',
    KPMGcaEnglish: 'https://home.kpmg/ca/en/home.html',
    inclusionDiversityEquity: 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-inclusion-diversity',
    KPMGSocialImpact: 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-Social-Impact',
    leadershipTeam: 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-Strategy/SitePages/Our-Leadership-Team.aspx',
    strategy: "https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-Strategy/SitePages/KPMG's-Evolved-Strategy.aspx",
    values: 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-Strategy/SitePages/Our-Values.aspx',
    KPMGGlobal: 'https://spo-global.kpmg.com/sites/GO-OI-Intranet',
  },
  'fr-CA': {
    KPMGcaFrancais: 'https://home.kpmg/ca/fr/home.html',
    KPMGcaEnglish: 'https://home.kpmg/ca/en/home.html',
    inclusionDiversityEquity:
      'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-inclusion-diversity/SitePages/fr/Home.aspx',
    KPMGSocialImpact: 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-Social-Impact/SitePages/fr/Home.aspx',
    leadershipTeam: 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-Strategy/SitePages/fr/Our-Leadership-Team.aspx',
    strategy: "https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-Strategy/SitePages/fr/KPMG's-Evolved-Strategy.aspx",
    values: 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-Strategy/SitePages/fr/Our-Values.aspx',
    KPMGGlobal: 'https://spo-global.kpmg.com/sites/GO-OI-Intranet',
  },
}
