import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import { ColorModeScript } from '@chakra-ui/color-mode'
import { ChakraProvider } from '@chakra-ui/provider'

// Providers
import { ConfigProvider } from './providers/ConfigurationProvider'
import { I18Provider } from './providers/i18Provider'
import { SettingsProvider } from './providers/SettingsProvider'
import { ThemeProvider } from './providers/ThemeProvider'
// Components
import { App } from './router/App'
import { SuspenseFullPage } from './router/SuspenseFullPage'
import theme from './theme'

import './styles/fonts.css'
import './styles/table.css'
import './index.css'

const container = document.getElementById('root')

const root = createRoot(container as Element)

root.render(
  <React.StrictMode>
    <HashRouter basename="/">
      <ConfigProvider>
        <I18Provider>
          <ChakraProvider theme={theme}>
            <SettingsProvider>
              <ThemeProvider>
                <Suspense fallback={<SuspenseFullPage />}>
                  <ColorModeScript initialColorMode={theme.config.initialColorMode} />
                  <Suspense fallback={<SuspenseFullPage />}>
                    <App />
                  </Suspense>
                </Suspense>
              </ThemeProvider>
            </SettingsProvider>
          </ChakraProvider>
        </I18Provider>
      </ConfigProvider>
    </HashRouter>
  </React.StrictMode>
)
