import { BiUser } from 'react-icons/bi'
import { Box } from '@chakra-ui/layout'

import { useSettingsContext } from '../providers/SettingsProvider'

type UserMessageProps = {
  content: string
}

export const UserMessage = ({ content }: UserMessageProps) => {
  const { isChatFullScreen, isLightMode } = useSettingsContext()

  if (isChatFullScreen) {
    return (
      <Box
        className={`${
          isLightMode ? 'bg-white' : 'bg-gradient-to-r from-kpmgDarkBlue to-kpmgBlue'
        } flex items-center py-4 px-2 w-full text-sm md:text-base`}
        test-id="user-message"
      >
        {/* For width definitions below, we need to match what the K and Feedback buttons have: w-[34px] is w-8 + 2px worth of border. w-[50px] is w-12 + 2px worth of border */}
        <Box className={`${isLightMode ? 'text-black' : 'text-white'} flex justify-center w-[34px] md:w-[50px] mr-2`}>
          <BiUser className="w-4 h-4 md:h-8 md:w-8" />
        </Box>
        <Box tabIndex={0} className={`${isLightMode ? 'text-black' : 'text-white'} flex-1`}>
          {content}
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box
        tabIndex={0}
        className={`max-w-[80%] text-sm md:text-base self-end mt-2 md:mt-4 p-3 lg:p-4 rounded-t-2xl rounded-bl-2xl ${
          isLightMode ? 'bg-kpmgCobaltBlue text-white' : 'bg-gradient-to-r from-kpmgDarkBlue to-kpmgBlue'
        }`}
        whiteSpace="pre-wrap"
        test-id="user-message"
      >
        {content}
      </Box>
    </>
  )
}
