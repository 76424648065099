export const chartDataExtractor = <T>(content: string): T | false => {
  const cleanedString = content
    .replace(/'/g, '"')
    .replace(/(\w+)(?=:)/g, '"$1"')
    .replace(/(\w+)(?=\s*:\s*[{|[])/g, '"$1"')
    .replace(/:\s*{/g, ':{')
    .replace(/:\s*\[/g, ':[')

  try {
    const convertedArray: T = JSON.parse(cleanedString)

    // Output the array value
    return convertedArray
  } catch (e) {
    return false
  }
}
