import { ForwardedRef, forwardRef, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { BiSolidSend, BiStopCircle } from 'react-icons/bi'
import { Box, Text } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/tooltip'

import { useBotContext } from 'providers/BotProvider'
import { useBotSpecificContext } from 'providers/BotSpecificProvider'
import { useFiltersContext } from 'providers/FiltersAndFormsProvider'
import { useI18Context } from 'providers/i18Provider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useSettingsContext } from 'providers/SettingsProvider'
import { useUploadContext } from 'providers/UploadProvider'

import type { BotFilterValues, BotFormValues } from 'types/types'

import { IconButton } from './buttons/IconButton'
import { TooltipButton } from './buttons/TooltipButton'
import { AutoResizeTextarea } from './AutoTextArea'
import { RecentSearches } from './RecentSearches'

type ChatInputProps = {
  botName: string
  maxInput: number
  submit: SubmitHandler<BotFormValues & BotFilterValues>
  includeStyles?: boolean
}

export const ChatInput = forwardRef(
  ({ botName, maxInput, submit, includeStyles = true }: ChatInputProps, ref: ForwardedRef<HTMLDivElement | null>) => {
    const { getValues, setValue, formState, handleSubmit } = useFormContext<BotFormValues & BotFilterValues>()
    const { t, language } = useI18Context()
    const { isDarkMode } = useSettingsContext()
    const { docContents } = useUploadContext()
    const { isFetchingChart, isStreaming, messages, streamingStep } = useBotSpecificContext()
    const { setAbortStatus } = useBotContext()
    const {
      conversations,
      findEmptyConversationID,
      getCurrentConversationIDForBot,
      getNumberOfBotConversations,
      getNumberOfConversations,
      MAX_CONVERSATIONS,
    } = useMessagesContext()
    const { getIsFetchingDocumentData } = useFiltersContext()

    const inputFocusRef = useRef<HTMLTextAreaElement | null>(null) // use this to focus the chat input

    const values = getValues()

    // Used to determine if a user has clicked inside of the chat input field
    const [isInputFocused, setInputFocused] = useState(false)

    // Need to wrap this in a useMemo, otherwise calling "new Intl.NumberFormat" consecutively causes the site to lag
    const numberFormat = useMemo(() => {
      return new Intl.NumberFormat(language)
    }, [language])

    const [isMaxCharactersReached, setIsMaxCharactersReached] = useState<boolean>(false)

    const currentCoversationId = useMemo(
      () => getCurrentConversationIDForBot(botName),
      [botName, getCurrentConversationIDForBot]
    )

    const handleInputChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        // if on upload bot but no document uploaded, all other bots docContents return undefined
        if (docContents === null) {
          setValue('userQuery', event.target.value, {
            shouldValidate: false,
          })
        } else {
          setValue('userQuery', event.target.value, {
            shouldValidate:
              (values.userQuery?.trim().length < 1 && event.target.value.trim().length >= 1) ||
              (values.userQuery?.length > maxInput && event.target.value.length <= maxInput),
          })
        }
      },
      [docContents, maxInput, setValue, values.userQuery]
    )

    const handleStopStreamingPress = useCallback(() => {
      setAbortStatus(botName, true)
    }, [setAbortStatus, botName])

    const handleEnterKeyPress = useCallback(() => {
      // Only need to check if streaming, validation will handle checking input length meets requirements
      if (!isStreaming && !isFetchingChart) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        handleSubmit(submit)()
        setValue('userQuery', '', { shouldValidate: false })
      }
    }, [handleSubmit, isFetchingChart, isStreaming, setValue, submit])

    // Used to determine when to show the "too many conversations" tooltip.
    const isTooManyConversations = useMemo(() => {
      // Only need to show the tooltip above the input field when a bot doesn't contain any conversations/messages, hence the check for: getNumberOfBotConversations(botName).length === 0 (otherwise it would show this tooltip while communicating in an active conversation which is bad UX)
      return getNumberOfBotConversations(botName) === 0 && getNumberOfConversations() >= MAX_CONVERSATIONS
    }, [botName, getNumberOfBotConversations, getNumberOfConversations, MAX_CONVERSATIONS])

    const TextAreas = useMemo(() => {
      const handlePaste = () => {
        if (inputFocusRef.current) inputFocusRef.current.scrollTop = inputFocusRef.current.scrollHeight
      }

      return (
        <AutoResizeTextarea
          name="userQuery"
          aria-label="sendMessage"
          className="w-full py-2.5 px-3 md:py-3 md:px-4 overflow-y-auto text-sm text-white placeholder-white transition-none bg-transparent border-0 resize-none md:text-base min-h-min max-h-[20vh] focus:ring-0 focus-visible:ring-0"
          onChange={handleInputChange}
          onKeyDown={(event: KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key === 'Enter' && !event.shiftKey && !isTooManyConversations) {
              event.preventDefault()
              handleEnterKeyPress()
            }
          }}
          onPaste={handlePaste}
          placeholder={t('generic.sendAMessage')}
          rows={1}
          ref={inputFocusRef}
          // DON'T pass in a value prop, let the input control it's own value, (which is registered to the form)
          // The form is defined to use selectedFormValues as the defaultValue so that we see the proper initial value when we load the bot/page this input is for.
        />
      )
    }, [handleEnterKeyPress, handleInputChange, isTooManyConversations, t])

    // When switching between bots, we run this useEffect to set the input to an empty string if input is a falsy value (like undefined)
    useEffect(() => {
      if (!values.userQuery) {
        setValue('userQuery', '', { shouldValidate: false })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botName, values.userQuery])

    useEffect(() => {
      if (values.userQuery && values.userQuery.length > maxInput) {
        setIsMaxCharactersReached(true)
      } else {
        setIsMaxCharactersReached(false)
      }
    }, [values.userQuery, maxInput])

    useEffect(() => {
      if (currentCoversationId === findEmptyConversationID(conversations[botName] ?? {})) {
        if (inputFocusRef && inputFocusRef.current) {
          inputFocusRef.current.focus()
        }
      }
      // Only include currentCoversationId in the dependency array to prevent the input from focusing unconditionally
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCoversationId])

    return (
      <Box
        className={`w-full backdrop-blur bg-gray-800 width-layout ${includeStyles ? 'bg-opacity-[.6] rounded-b-xl mb-2 md:mb-4' : 'bg-opacity-[.2] rounded-b-md'}`}
        ref={ref}
      >
        <Box className="w-full p-2 pb-1.5 border-t border-white min-h-min md:pt-3 md:px-3">
          {/* Input for the Chat Bot */}
          <Box className="grid grid-cols-[auto,1fr] grid-rows-[auto,min-content]">
            <Box className="mt-auto h-min">
              <RecentSearches inputRef={inputFocusRef} messages={messages} />
            </Box>
            <Box className="flex flex-row items-end">
              <Tooltip
                className="text-xs text-black bg-kpmgGray5"
                label={t('generic.tooManyConversations')}
                placement="top-start"
                openDelay={200}
                tabIndex={0}
                isOpen={isTooManyConversations && isInputFocused}
              >
                <Box
                  className="flex-grow mr-2 border border-white rounded-xl md:mr-3"
                  onFocus={() => setInputFocused(true)}
                  onBlur={() => setInputFocused(false)}
                >
                  {TextAreas}
                </Box>
              </Tooltip>
              <TooltipButton
                label={isTooManyConversations ? t('generic.tooManyConversations') : t('controls.sendMessage')}
                placement="top-end"
                isDisabled={isStreaming}
                button={
                  isStreaming && streamingStep === 'content' ? (
                    <IconButton
                      aria-label="stopStreaming"
                      variant="ghost"
                      className={`text-white rounded-xl background-button bg-kpmgCobaltBlue h-full p-2.5 md:p-3 ${
                        isDarkMode ? 'focus:ring-dark' : 'focus:ring-light'
                      }`}
                      onClick={() => handleStopStreamingPress()}
                      iconName={BiStopCircle}
                      iconClassName="text-xl md:text-2xl"
                      test-id="stop-streaming-button"
                    />
                  ) : (
                    <IconButton
                      isLoading={isStreaming || isFetchingChart}
                      isDisabled={
                        values.userQuery?.trim().length === 0 ||
                        isTooManyConversations ||
                        getIsFetchingDocumentData(botName)
                      }
                      aria-label="sendMessage"
                      variant="ghost"
                      className={`text-white rounded-xl background-button bg-kpmgCobaltBlue h-full p-3 md:p-3.5 ${
                        isDarkMode ? 'focus:ring-dark' : 'focus:ring-light'
                      }`}
                      onClick={() => handleEnterKeyPress()}
                      iconName={BiSolidSend}
                      iconClassName="text-base md:text-xl"
                      test-id="send-button"
                    />
                  )
                }
              />
            </Box>
            <Box className="col-start-2 row-start-2 mt-1.5 text-xs md:text-sm">
              <Text
                as="span"
                className={`${isMaxCharactersReached ? 'text-red-400' : 'text-white'}`}
                test-id="character-count"
              >
                {numberFormat.format(values.userQuery?.length ?? 0)} / {numberFormat.format(maxInput)}{' '}
              </Text>
              {/* Because the below ErrorMessage is only used to show an error for the "userQuery" AND the only Yup schema requirement is the maximum length, we can wrap it in the "isMaxCharactersReached" boolean to show it conditionally */}
              {/* Currently there is a side-effect error where the ErrorMessage will show and remain on screen after a user deletes enough characters to be within the accepted range, so we need "isMaxCharactersReached" to be used here  */}
              {formState.errors['userQuery'] ? (
                <Text as="span" className="text-red-400" test-id="userQuery-error">
                  {`(${formState.errors['userQuery'].message as string})`}
                </Text>
              ) : formState.errors['filters'] ? (
                <Text as="span" className="text-red-400" test-id="filters-error">
                  {`(${formState.errors['filters'].message as string})`}
                </Text>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
)
