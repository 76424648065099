import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'

import { useSettingsContext } from '../providers/SettingsProvider'

type ModalBoxProps = {
  closeOnEscape?: boolean
  closeOnOverlayClick?: boolean
  disableCloseButton?: boolean
  hideCloseButton?: boolean
  isOpen: boolean
  modalBody: React.ReactNode
  modalFooter?: React.ReactNode
  modalHeader: string
  onClose: () => void
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full'
}

export const ModalBox = (props: ModalBoxProps) => {
  const {
    closeOnEscape = true,
    closeOnOverlayClick = false,
    disableCloseButton = false,
    hideCloseButton = false,
    isOpen,
    modalBody,
    modalFooter,
    modalHeader,
    onClose,
    size = 'md',
  } = props

  const { isDarkMode } = useSettingsContext()

  const handleDisabledClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    // if it's not disabled, close it, else do nothing
    if (!disableCloseButton) {
      onClose()
    }
  }

  return (
    <Modal
      closeOnEsc={closeOnEscape}
      closeOnOverlayClick={closeOnOverlayClick}
      isOpen={isOpen}
      onClose={onClose}
      size={size}
    >
      <ModalOverlay />
      <ModalContent className={`mx-2 ${isDarkMode ? 'background-primary' : ''}`}>
        <ModalHeader test-id="modal-header" className="pr-12 text-base md:text-lg">
          {modalHeader}
        </ModalHeader>
        {!hideCloseButton && (
          <ModalCloseButton
            onClick={handleDisabledClose}
            _disabled={disableCloseButton ? { cursor: 'not-allowed', opacity: 0.4 } : {}}
            isDisabled={disableCloseButton}
          />
        )}
        <ModalBody className="text-sm md:text-base">{modalBody}</ModalBody>
        <ModalFooter className="pt-2">{modalFooter}</ModalFooter>
      </ModalContent>
    </Modal>
  )
}
