import { t } from 'i18next'

import { ChatMessage } from '../types/types'

import {
  APIError,
  ChunkFetchTimeoutError,
  ConnectionTimeoutError,
  GPTError,
  NetworkError,
  NetworkTimeoutError,
  ReactError,
} from './appError'

const generateErrorMessage = (
  error: unknown | string | APIError | TypeError | Error | GPTError | NetworkTimeoutError | NetworkError
): string => {
  if (error instanceof NetworkError) {
    const codeString = error.status
    // TODO: currently catch all 5** errors, be specific later on.
    if (codeString === '504') {
      return `${t('generic.504Error')} HTTP_${codeString}.`
    } else if (codeString === '404') {
      return `${t('generic.404Error')} HTTP_${codeString}`
    } else if (codeString === '403') {
      return `${t('generic.403Error')} HTTP_${codeString}`
    } else {
      return `${t('generic.networkError')} HTTP_${codeString}`
    }
  } else if (error instanceof APIError) {
    return `${t('generic.backendError')} BE_${error.code}.`
  } else if (error instanceof TypeError) {
    if (error.message === 'Failed to fetch') {
      return `${t('generic.failedToFetchError')} NET_001`
    }
    return t('generic.errorMessage')
  } else if (error instanceof GPTError) {
    return t(`generic.gptStreamingError-${error.code}`, t('generic.backendError'))
  } else if (error instanceof ConnectionTimeoutError) {
    return t('generic.connectionTimeoutError')
  } else if (error instanceof ChunkFetchTimeoutError) {
    return t('generic.chunkFetchTimeoutError')
  } else if (error instanceof NetworkTimeoutError) {
    return t('generic.networkTimeoutError')
  }
  // General error
  return t('generic.errorMessage')
}

export const updateBotMessagesWithError = (
  currentMessage: ChatMessage,
  error: unknown | string | APIError | TypeError | Error | GPTError | NetworkTimeoutError | NetworkError
): ChatMessage => {
  currentMessage.error = {
    message: generateErrorMessage(error),
  }
  return { ...currentMessage }
}

export const convertErrorToString = (err?: Error): string => {
  if (err) {
    if (err instanceof ReactError) {
      return JSON.stringify({
        message: err.message,
        stack: err.stack,
        error: err.constructor.name,
        componentStack: err.componentStack,
      })
    }
    return JSON.stringify({ message: err.message, stack: err.stack, error: err.constructor.name })
  } else {
    return ''
  }
}
