import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  BiCloudUpload,
  BiSolidFile,
  BiSolidFileCss,
  BiSolidFileDoc,
  BiSolidFileHtml,
  BiSolidFileJs,
  BiSolidFileMd,
  BiSolidFilePdf,
  BiSolidFileTxt,
  BiSolidSlideshow,
} from 'react-icons/bi'
import { Box, Text } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import mime from 'mime'

import { Button } from 'components/buttons/Button'

import { useI18Context } from 'providers/i18Provider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useSettingsContext } from 'providers/SettingsProvider'
import { useThemeContext } from 'providers/ThemeProvider'
import { useUploadContext } from 'providers/UploadProvider'

import { GeneralSpinner } from 'router/Spinner'

import { Banner } from '../Banner'
import { HTMLStringParser } from '../HTMLStringParser'
import { ModalBox } from '../Modal'

import { Drawer } from './Drawer'

const isBinaryFile = (buffer: ArrayBuffer) => {
  const chunk = new Uint8Array(buffer.slice(0, 256))
  for (let i = 0; i < chunk.length; i++) {
    if (chunk[i] === 0) {
      return true
    }
  }

  return false
}

const getFileExt = (fileType: string) => {
  return mime.getExtension(fileType)
}

const fileIconMap: Record<string, JSX.Element> = {
  css: <BiSolidFileCss className="w-8 h-8" />,
  doc: <BiSolidFileDoc className="w-8 h-8" />,
  docx: <BiSolidFileDoc className="w-8 h-8" />,
  html: <BiSolidFileHtml className="w-8 h-8" />,
  js: <BiSolidFileJs className="w-8 h-8" />,
  md: <BiSolidFileMd className="w-8 h-8" />,
  pdf: <BiSolidFilePdf className="w-8 h-8" />,
  pptx: <BiSolidSlideshow className="w-8 h-8" />,
  txt: <BiSolidFileTxt className="w-8 h-8" />,
}

type UploadDrawerProps = {
  fileSubmit: (files: File[]) => void
  isDrawerOpen: boolean
  isFetchingChart: boolean
  isStreaming: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
  supportedFileExtensions: Array<string>
  supportedMimeTypes: Array<string>
  uploadTokenMaxSize: number
}

export const UploadDrawer = (props: UploadDrawerProps) => {
  const {
    fileSubmit,
    isDrawerOpen,
    isFetchingChart,
    isStreaming,
    setIsDrawerOpen,
    supportedFileExtensions,
    supportedMimeTypes,
    uploadTokenMaxSize,
  } = props
  const { isLightMode } = useSettingsContext()
  const { isTablet } = useThemeContext()
  const { getCurrentConversationIDForBot } = useMessagesContext()
  const { t, language } = useI18Context()
  const {
    docContents,
    isFileUploading,
    maxFileNameLength,
    maxFileSize,
    setUploadError,
    uploadError,
    uploadModalOperations: { isOpen, onOpen, onClose },
  } = useUploadContext()

  const [isWarningAcknowledged, setIsWarningAcknowledged] = useState<boolean>(false)

  const scrollRef = useRef<HTMLDivElement | null>(null) // use this to scroll to the top of the file contents when the content changes

  const extensionTypes = useMemo(() => {
    const uniqueExtensions: Set<string> = new Set()
    supportedMimeTypes.forEach((fileType) => {
      const ext = getFileExt(fileType)
      if (ext && !uniqueExtensions.has(ext)) {
        if (ext === 'jpeg') {
          // for visualization, we need to show jpg as an option
          uniqueExtensions.add('jpg')
        }
        uniqueExtensions.add(ext)
      }
    })

    return [...Array.from(uniqueExtensions), ...supportedFileExtensions]
  }, [supportedMimeTypes, supportedFileExtensions])

  const currentConversationID = useMemo(() => {
    return getCurrentConversationIDForBot('UPLOADBOT')
  }, [getCurrentConversationIDForBot])

  const documentContentsToRender = useMemo(() => {
    if (currentConversationID && docContents[currentConversationID]) {
      return docContents[currentConversationID]
    } else if (docContents[0]) {
      return docContents[0]
    }
  }, [docContents, currentConversationID])

  const uploadFileIcon = useMemo(() => {
    if (!docContents) return null

    const fileExtensionType = documentContentsToRender?.type
    if (!fileExtensionType) return null

    const fileExtension = getFileExt(fileExtensionType)

    if (!fileExtension) return null

    if (Object.keys(fileIconMap).includes(fileExtension)) {
      return fileIconMap[fileExtension]
    } else {
      return <BiSolidFile className="w-8 h-8" />
    }
  }, [docContents, documentContentsToRender?.type])

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const onModalClose = () => {
    // After the user closes the modal, reset the error message
    setUploadError('')
    onClose()
  }

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setUploadError('')

      const ext = getFileExt(acceptedFiles[0].type)
      const fileNameSplits = acceptedFiles[0].name.split('.')
      const fileExt = fileNameSplits[fileNameSplits.length - 1]

      if ((ext && extensionTypes.includes(ext)) || extensionTypes.includes(fileExt)) {
        // .ts file (typescript) will be recognized as video/mp2t, so we need to identify the content of the file
        let fileToSubmit = acceptedFiles[0]

        // ".ts" file (typescript) will be recognized as video/mp2t, so we need to identify the content of the file
        if (ext === 'ts') {
          const isBinary = isBinaryFile(await fileToSubmit.slice(0, fileToSubmit.size, fileToSubmit.type).arrayBuffer())
          // isBinary tells us if the file is a video of mime-type "video/mp2t"
          if (isBinary) {
            setUploadError('unsupportedFileType')
            return
          }
        }

        // Need to set the type to "text/plain" to those files that can't be translated using the "mime" library
        if (supportedFileExtensions.includes(fileExt)) {
          // Create a new instance of the File type, specifically with type equaling "text/plain"
          // Need to create a new instance since the original file object is read-only
          const fileTemp = fileToSubmit.slice(0, fileToSubmit.size, fileToSubmit.type)
          fileToSubmit = new File([fileTemp], fileToSubmit.name, {
            type: 'text/plain',
            lastModified: fileToSubmit.lastModified,
          })
        }
        fileSubmit([fileToSubmit])
      } else {
        setUploadError('unsupportedFileType')
      }
    },
    [extensionTypes, supportedFileExtensions, fileSubmit, setUploadError]
  )

  useEffect(() => {
    if (documentContentsToRender && documentContentsToRender?.text && scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }
  }, [documentContentsToRender, documentContentsToRender?.text])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled: isFileUploading })

  const uploadErrorMessage = useMemo(() => {
    return t(`uploadModal.${uploadError}`, {
      maxFileNameLength,
      // If the uploadFileMaxSize is less than 1,000,000 then we are dealing with KB (e.g., 900,000 -> 900 KB). Otherwise we are dealing with MB (e.g., 20,000,000 -> 20 MB)
      maxFileSize,
      maxNumberTokens: numberFormat.format(uploadTokenMaxSize * 4),
    })
  }, [maxFileNameLength, maxFileSize, numberFormat, t, uploadError, uploadTokenMaxSize])

  const uploadWarningBanner = useMemo(() => {
    return t('uploadModal.warningBanner', { returnObjects: true }) as string[]
  }, [t])

  return {
    drawer: (
      <Drawer
        drawerIcon={BiCloudUpload}
        drawerTitle={t('generic.fileUpload')}
        expandable
        isOpen={isDrawerOpen}
        position="right"
        setIsOpen={setIsDrawerOpen}
      >
        {({ isDrawerExpanded }) => (
          <>
            <ModalBox
              disableCloseButton={isFileUploading}
              isOpen={isOpen}
              onClose={onModalClose}
              modalHeader={t('uploadModal.uploadCTA')}
              modalFooter={
                <Box>
                  {uploadError && <Text className="mb-2 text-xs text-red-400">{uploadErrorMessage}</Text>}
                  <Text className="text-xs">{t('uploadModal.disclaimer')}</Text>
                </Box>
              }
              modalBody={
                <>
                  <Banner
                    className="mb-4"
                    onClick={() => setIsWarningAcknowledged(true)}
                    title={t('uploadModal.warningBannerHeader')}
                    description={
                      <Box className="last:mb-0">
                        {uploadWarningBanner.map((warning: string, warningIndex: number) => {
                          return (
                            <Text className="mb-2" key={`${warning}_${warningIndex}`}>
                              {warning}
                            </Text>
                          )
                        })}
                      </Box>
                    }
                    isVisible={!isWarningAcknowledged}
                  />
                  <Box
                    className={`flex justify-center items-center flex-col border-2 rounded-lg p-4 ${
                      isDragActive ? 'border-solid' : 'border-dashed'
                    } ${uploadError && 'border-red-400'}`}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {isFileUploading ? (
                      <>
                        <Text className="text-sm md:text-base">{t('uploadModal.uploading')}...</Text>
                        <GeneralSpinner fullHeight={false} />
                        <Text className="text-xs text-center md:text-sm">{t('uploadModal.uploadingMessaging')}</Text>
                      </>
                    ) : (
                      <Box>
                        <Box className="flex flex-col items-center justify-center mb-4 text-center">
                          <BiCloudUpload className="w-6 h-6 mb-2 md:h-8 md:w-8" />
                          <Text className="text-sm md:text-base">{t('uploadModal.dragMessage')}</Text>
                          <Text className="p-1 text-xs md:text-sm">{t('uploadModal.or')}</Text>
                          <Button className="py-4 text-sm whitespace-normal md:text-base min-h-fit">
                            {t('uploadModal.clickMessage')}
                          </Button>
                        </Box>
                        <Box className="flex flex-col items-center justify-center text-center">
                          <Text className="mb-2 text-xs">
                            {t('uploadModal.maxFileSize')}:{' '}
                            <Text as="span" className="font-bold">
                              {/* If the uploadFileMaxSize is less than 1,000,000 then we are dealing with KB (e.g., 900,000 -> 900 KB). Otherwise we are dealing with MB (e.g., 20,000,000 -> 20 MB) */}
                              {maxFileSize}
                            </Text>
                          </Text>
                          <Text className="mb-2 text-xs">
                            {t('uploadModal.maxCharacterCount')}:{' '}
                            <Text as="span" className="font-bold">
                              {numberFormat.format(uploadTokenMaxSize * 4)} {t('uploadModal.characters')}
                            </Text>
                          </Text>
                          <Text className="text-xs">
                            {t('uploadModal.supportedFileTypes')}:{' '}
                            <Text as="span" className="text-xs font-bold uppercase">
                              {extensionTypes.length && extensionTypes.join(', ')}
                            </Text>
                          </Text>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </>
              }
            />
            <Box className={`flex flex-col items-start justify-start h-full ${!isTablet && 'min-h-[65vh]'}`}>
              <Box
                className={`w-full box-content flex ${
                  isDrawerExpanded && isTablet ? 'flex-row justify-between items-center' : 'flex-col justify-center'
                }`}
              >
                <Box>
                  <Button
                    onClick={() => onOpen()}
                    className={`shadow-none py-4 min-h-fit min-w-fit hover:cursor-pointer focus:ring text-sm ${language === 'en-US' && 'md:text-base'} ${
                      isLightMode ? 'focus:ring-kpmgCobaltBlue focus:ring-inset' : 'focus:ring-kpmgGray5'
                    } w-full whitespace-normal`}
                    aria-label="signIn"
                    isDisabled={isStreaming || isFetchingChart || isFileUploading}
                  >
                    {isFileUploading && <Spinner size="xs" mr={2} />}
                    {t('uploadModal.uploadCTA')}
                  </Button>
                </Box>
                <Box className={`flex-1 ${isDrawerExpanded && isTablet ? 'ml-4' : 'mt-4'}`}>
                  {documentContentsToRender ? (
                    <Box className="flex justify-start h-8">
                      <Text as="span">{uploadFileIcon}</Text>
                      <Box className="flex flex-col justify-center h-8 ml-1">
                        <Text as="span" className="text-xs break-all">
                          {documentContentsToRender.title}
                        </Text>
                        <Text as="span" className="text-xs">
                          {`${numberFormat.format(documentContentsToRender.characterCount ?? 0)} ${t(
                            'uploadModal.characters'
                          )}`}
                        </Text>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Text className="text-sm">{t('uploadModal.noFileUploaded')}</Text>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box className="flex flex-col w-full h-full min-h-0 mt-4">
                <Text className="mb-2 text-sm font-bold md:text-base">{t('uploadModal.uploadedFileContents')}:</Text>
                <Box className="h-full overflow-hidden rounded-xl">
                  <Box
                    className="h-full p-2 overflow-auto rounded-xl bg-kpmgDarkGray5"
                    whiteSpace="pre-wrap"
                    ref={scrollRef}
                  >
                    {documentContentsToRender && documentContentsToRender?.text ? (
                      <Text as="span" className="text-xs text-black">
                        {documentContentsToRender.type === 'text/plain' ||
                        documentContentsToRender.type === 'application/x-httpd-php' ||
                        documentContentsToRender.type === 'text/xml' ||
                        documentContentsToRender.type === 'application/pdf' ? (
                          documentContentsToRender.text
                        ) : (
                          <HTMLStringParser
                            htmlString={'<html><body>' + documentContentsToRender.text + '</body></html>'}
                          />
                        )}
                      </Text>
                    ) : (
                      <Text as="span" className="text-xs text-kpmgGray2">
                        {t('uploadModal.noContent')}
                      </Text>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Drawer>
    ),
    button: {
      hideTooltipText: t('generic.hideTheUploadDrawer'),
      icon: BiCloudUpload,
      onClick: () => setIsDrawerOpen(!isDrawerOpen),
      showTooltipText: t('generic.showTheUploadDrawer'),
      text: t('controls.upload'),
    },
    isOpen: isDrawerOpen,
  }
}
