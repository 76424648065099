import { NetworkError } from '../appError'

const http = async (path: string, config: RequestInit) => {
  const response = await fetch(path, config)
  if (response.ok) {
    return response
  }
  throw new NetworkError('request-error', -1, extractHeaders(response), response.status.toString())
}

export const httpJSON = async <T>(
  path: string,
  config: RequestInit,
  retries: number,
  setRetrying?: (value: boolean) => void
): Promise<T> => {
  try {
    return (await http(path, config)).json()
  } catch (e) {
    console.error(`Error: Failed to fetch and reach server. Retries: ${retries}`, e)
    if (retries > 0) {
      setRetrying && setRetrying(true)
      return httpJSON(path, config, retries - 1, setRetrying)
    } else {
      setRetrying && setRetrying(false)
    }
    throw e
  }
}

export const httpStream = async (
  path: string,
  config: RequestInit,
  retries: number,
  setRetrying?: (value: boolean) => void
): Promise<Response> => {
  try {
    return fetch(path, config)
  } catch (e) {
    console.error(`Error: Failed to stream and reach server. Retries: ${retries}`, e)
    if (retries > 0) {
      setRetrying && setRetrying(true)
      return httpStream(path, config, retries - 1, setRetrying)
    } else {
      setRetrying && setRetrying(false)
    }
    throw e
  }
}

export const httpRaw = async (
  path: string,
  config: RequestInit,
  retries: number,
  setRetrying?: (value: boolean) => void
): Promise<Response> => {
  try {
    return fetch(path, config)
  } catch (e) {
    console.error(`Error: Failed to fetch and reach server. Retries: ${retries}`, e)
    if (retries > 0) {
      setRetrying && setRetrying(true)
      return httpRaw(path, config, retries - 1, setRetrying)
    } else {
      setRetrying && setRetrying(false)
    }
    throw e
  }
}

export const extractHeaders = (response: Response): string[] => {
  const responseHeaders: string[] = []
  if (response.headers) {
    response.headers.forEach((header, key) => {
      if (!header.toLowerCase().includes('authorization') && !key.toLowerCase().includes('authorization')) {
        responseHeaders.push(`${key}: ${header}`)
      }
    })
  }
  return responseHeaders
}
