import { useMemo } from 'react'
import { Box } from '@chakra-ui/layout'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import { ResponsivePie } from '@nivo/pie'

import { useThemeContext } from '../../providers/ThemeProvider'
import { BarChartData, LineChartData, PieChartData } from '../../types/types'

type LineChartProps = {
  data: LineChartData
}

type PieChartProps = {
  data: PieChartData
}

type BarChartProps = {
  data: BarChartData
}

export const LineChart = (props: LineChartProps) => {
  const { data } = props

  const { isDesktop } = useThemeContext()

  const showLegend = isDesktop ? true : false

  const largestXAxisLabelLength = useMemo(() => {
    return Math.max(...data.flatMap((item) => item.data.map((point) => point.x.length)))
  }, [data])

  const largestYAxisLabelLength = useMemo(() => {
    return Math.floor(Math.max(...data.flatMap((item) => item.data.map((point) => point.y)))).toString().length
  }, [data])

  const largestLegendLabelLength = useMemo(() => {
    return Math.max(...data.map((item) => item.id.length))
  }, [data])

  const averageXAxisLabelLength = useMemo(() => {
    const xAxisLabelLengths = data.flatMap((item) => item.data.map((point) => point.x.length))
    return xAxisLabelLengths.reduce((total, num) => total + num, 0) / xAxisLabelLengths.length
  }, [data])

  const rotateCondition = averageXAxisLabelLength * data.length > 80 || !isDesktop

  const rightMargin = showLegend
    ? largestLegendLabelLength * 6 + (largestLegendLabelLength > 20 ? 30 : 50)
    : averageXAxisLabelLength * 4
  const bottomMargin = rotateCondition ? largestXAxisLabelLength * 3 + 30 : 30
  const leftMargin = rotateCondition
    ? largestYAxisLabelLength * 6 + 20
    : largestYAxisLabelLength * 2 > averageXAxisLabelLength
    ? largestYAxisLabelLength * 6 + 30
    : averageXAxisLabelLength * 2 + 20

  return (
    <Box className="h-[40vh]">
      <ResponsiveLine
        data={data}
        margin={{ top: 20, right: rightMargin, bottom: bottomMargin, left: leftMargin }}
        xScale={{ type: 'point' }}
        enableSlices="x"
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        colors={(point) => {
          return point.color
        }} // Use the color property from the data
        axisTop={null}
        axisRight={null}
        axisBottom={{ tickRotation: rotateCondition ? 45 : 0 }}
        pointSize={12}
        useMesh={true}
        theme={{
          tooltip: {
            container: {
              fontSize: isDesktop ? '14px' : '10px',
            },
          },
        }}
        legends={
          showLegend
            ? [
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]
            : []
        }
      />
    </Box>
  )
}

export const PieChart = (props: PieChartProps) => {
  const { data } = props
  const { isDesktop } = useThemeContext()

  const showLegend = isDesktop ? true : false

  const largestLabelLength = useMemo(() => {
    return Math.max(...data.map((pie) => pie.label.length))
  }, [data])

  const rightMargin = showLegend ? largestLabelLength * 5 + 30 : 20

  return (
    <Box className="h-[40vh]">
      <ResponsivePie
        data={data}
        margin={{ top: 20, right: rightMargin, bottom: 40, left: 20 }}
        innerRadius={0}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.5]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#000"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        enableArcLinkLabels={isDesktop ? data.length < 10 : false}
        colors={({ data }) => data.color} // Use the color property from the data
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        theme={{
          tooltip: {
            container: {
              fontSize: isDesktop ? '14px' : '10px',
            },
          },
        }}
        legends={
          showLegend
            ? [
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 80,
                  translateY: 0,
                  itemsSpacing: 5,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: '#000',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000',
                      },
                    },
                  ],
                },
              ]
            : []
        }
      />
    </Box>
  )
}

export const BarChart = (props: BarChartProps) => {
  const { data } = props
  const { isDesktop } = useThemeContext()

  const showLegend = isDesktop ? true : false

  const largestXAxisLabelLength = useMemo(() => {
    return Math.max(...data.map((item) => item.item.length))
  }, [data])

  const largestYAxisLabelLength = useMemo(() => {
    return Math.floor(Math.max(...data.map((bar) => bar.value))).toString().length
  }, [data])

  const averageXAxisLabelLength = useMemo(() => {
    return Math.max(data.reduce((total, num) => total + num.item.length, 0) / data.length)
  }, [data])

  const rotateCondition = averageXAxisLabelLength * data.length > 80 || !isDesktop

  const rightMargin = showLegend ? largestXAxisLabelLength * 6 + (largestXAxisLabelLength > 20 ? 30 : 50) : 30
  const bottomMargin = rotateCondition ? largestXAxisLabelLength * 3 + 30 : 30
  const leftMargin = largestYAxisLabelLength * 6 + 20

  return (
    <Box className="h-[40vh]">
      <ResponsiveBar
        data={data}
        indexBy="item"
        margin={{
          top: 10,
          right: rightMargin,
          bottom: bottomMargin,
          left: leftMargin,
        }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        colors={({ data }) => data.color} // Use the color property from the data
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        axisBottom={{ tickRotation: rotateCondition ? 45 : 0 }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        theme={{
          tooltip: {
            container: {
              fontSize: isDesktop ? '14px' : '10px',
            },
          },
        }}
        legends={
          showLegend
            ? [
                {
                  dataFrom: 'indexes',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]
            : []
        }
      />
    </Box>
  )
}
