import { createContext, useContext, useState } from 'react'

import { ScrollState } from 'types/types'

type ScrollContextType = {
  stateClassNameString: string | undefined
  stateExpanded: ScrollState
  stateScroll: ScrollState
  handleScrollStateCallback: (scrollState: ScrollState) => void
  updateStateClassNameString: (name: string | undefined) => void
  updateStateExpanded: (
    conversationID: number,
    messageId: string,
    messageState: boolean,
    activeScrollToState: string | null
  ) => void
  updateStateScroll: (scrollState: ScrollState) => void
}

export const ScrollContext = createContext<ScrollContextType>({} as ScrollContextType)

type ScrollProviderProps = {
  children?: React.ReactNode
}

export const ScrollProvider = ({ children }: ScrollProviderProps) => {
  const [stateExpanded, setStateExpanded] = useState<ScrollState>({ activeStates: {}, activeScrollTo: null })
  const [stateScroll, setStateScroll] = useState<ScrollState>({ activeStates: {}, activeScrollTo: null })
  const [stateClassNameString, setStateClassNameString] = useState<string | undefined>()

  // type guard for identifier of scrollable item
  const handleScrollStateCallback = (scrollBoolean: ScrollState): void => {
    updateStateScroll(scrollBoolean)
    if (scrollBoolean['activeScrollTo']) {
      updateStateClassNameString(scrollBoolean['activeScrollTo'] as string)
    }
  }

  const updateStateExpanded = (
    conversationID: number,
    messageId: string,
    messageState: boolean,
    activeScrollToState: string | null
  ): void => {
    setStateExpanded((prevState) => {
      if (conversationID && messageId) {
        return {
          ...prevState,
          activeStates: {
            ...prevState.activeStates,
            [conversationID]: {
              [`${messageId}`]: messageState,
            },
          },
          activeScrollTo: activeScrollToState,
        }
      } else {
        return prevState
      }
    })
  }

  const updateStateScroll = (scrollState: ScrollState) => {
    setStateScroll(scrollState)
  }

  const updateStateClassNameString = (name: string | undefined) => {
    setStateClassNameString(name)
  }

  return (
    <>
      <ScrollContext.Provider
        value={{
          handleScrollStateCallback,
          stateClassNameString,
          stateExpanded,
          stateScroll,
          updateStateClassNameString,
          updateStateExpanded,
          updateStateScroll,
        }}
      >
        {children}
      </ScrollContext.Provider>
    </>
  )
}

export const useScrollContext = (): ScrollContextType => useContext(ScrollContext)
