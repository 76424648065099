import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import type { AvailableConversationSettings, ConversationSpecificSettings, FEUIConfig } from 'types/types'

import { useI18Context } from './i18Provider'

export function isKeyOfObject<T extends object>(key: string | number | symbol, obj: T): key is keyof T {
  return key in obj
}

type ConversationSettingsContextType = {
  availableConversationSettings: Record<string, AvailableConversationSettings>
  deleteConversationSettings: (conversationIDsToDelete: number[]) => void
  getAllBotConversationSettings: (botName: string) => Record<number, ConversationSpecificSettings>
  getConversationSettings: (botName: string, conversationID: number) => ConversationSpecificSettings | undefined
  getDefaultSettings: (botName: string) => ConversationSpecificSettings | undefined
  updateConversationSettings: (
    botName: string,
    conversationID: number,
    newSettings?: ConversationSpecificSettings
  ) => void
  updateSettingsLanguage: (botName: string, conversationID: number) => void
}

export const ConversationSettingsContext = createContext<ConversationSettingsContextType>(
  {} as ConversationSettingsContextType
)

type ConversationSettingsProviderProps = {
  children?: React.ReactNode
  config?: FEUIConfig[]
}

type ConversationsSettingsType = Record<string, Record<number, ConversationSpecificSettings>>

export const ConversationSettingsProvider = ({ children, config }: ConversationSettingsProviderProps) => {
  const { t } = useI18Context()
  const [conversationSettings, setConversationSettings] = useState<ConversationsSettingsType>({})

  const [defaultSettings, setDefaultSettings] = useState<Record<string, ConversationSpecificSettings>>({})

  // Set the available options for the settings
  const availableConversationSettings = useMemo(() => {
    let settings: Record<string, AvailableConversationSettings> = {}
    config?.forEach((botConfig) => {
      if (botConfig.settings) {
        settings = {
          ...settings,
          [botConfig.botName]: {
            input: {
              // Eventually/ideally availableVoices and availableTemperatures should be fetched from the backend
              voices: botConfig.settings.input.availableVoices.map((voice) => ({
                value: voice,
                label: t(`settings.voices.${voice}`),
              })),
              temperatures: botConfig.settings.input.availableTemperatures.map((temperature) => ({
                value: temperature,
                label: t(`settings.temperatures.${temperature}`),
              })),
            },
          },
        }
      }
    })
    return settings
  }, [config, t])

  // Set default empty conversation settings
  useEffect(() => {
    config?.forEach((botConfig) => {
      if (botConfig.settings) {
        setDefaultSettings((currentSettings) =>
          botConfig.settings
            ? {
                ...currentSettings,
                [botConfig.botName]: {
                  input: {
                    voice: {
                      value: botConfig.settings.input.voice,
                      label: t(`settings.voices.${botConfig.settings.input.voice}`),
                    },
                    temperature: {
                      value: botConfig.settings.input.temperature,
                      label: t(`settings.temperatures.${botConfig.settings.input.temperature}`),
                    },
                  },
                },
              }
            : {
                ...currentSettings,
              }
        )
      }
    })
  }, [config, t])

  const updateSettingsLanguage = useCallback(
    (botName: string, conversationID: number): void => {
      if (
        isKeyOfObject(botName, conversationSettings) &&
        isKeyOfObject(conversationID, conversationSettings[botName]) &&
        isKeyOfObject(botName, availableConversationSettings)
      ) {
        const newVoices = availableConversationSettings[botName].input.voices.map((voice) => ({
          value: voice.value,
          label: t(`settings.voices.${voice.value}`),
        }))
        const newTemps = availableConversationSettings[botName].input.temperatures.map((temperature) => ({
          value: temperature.value,
          label: t(`settings.temperatures.${temperature.value}`),
        }))

        const voiceToMatch = conversationSettings[botName][conversationID].input.voice.value
        const voiceToUse = newVoices.find((option) => voiceToMatch === option.value) || {
          value: defaultSettings[botName].input.voice.value,
          label: t(`settings.voices.${defaultSettings[botName].input.voice.value}`),
        }
        const tempToMatch = conversationSettings[botName][conversationID].input.temperature.value
        const temperatureToUse = newTemps.find((option) => tempToMatch === option.value) || {
          value: defaultSettings[botName].input.temperature.value,
          label: t(`settings.temperatures.${defaultSettings[botName].input.temperature.value}`),
        }
        setConversationSettings((currentSettings) => ({
          ...currentSettings,
          [botName]: {
            ...currentSettings[botName],
            [conversationID]: {
              input: {
                voice: voiceToUse,
                temperature: temperatureToUse,
              },
            },
          },
        }))
      }
    },
    [availableConversationSettings, conversationSettings, defaultSettings, t]
  )

  /**
   * Returns the settings of the provided bot-conversation
   * @param {string} botName - The name of the bot
   * @param {number} conversationID - The id of the conversation
   * @returns {ConversationSpecificSettings | undefined}
   */
  const getConversationSettings = useCallback(
    (botName: string, conversationID: number): ConversationSpecificSettings | undefined => {
      if (
        isKeyOfObject(botName, conversationSettings) &&
        isKeyOfObject(conversationID, conversationSettings[botName])
      ) {
        return conversationSettings[botName][conversationID]
      }
      return undefined
    },
    [conversationSettings]
  )

  /**
   * Returns the default settings of a bot
   * @param {string} botName - The name of the bot
   * @returns {ConversationSpecificSettings | undefined}
   */
  const getDefaultSettings = useCallback(
    (botName: string): ConversationSpecificSettings | undefined => {
      if (isKeyOfObject(botName, defaultSettings)) {
        return defaultSettings[botName]
      }
      return undefined
    },
    [defaultSettings]
  )

  /**
   * Updates the settings of the provided bot-conversation, with the provided new values
   * If no new values are provided, updates it with the default values for the provided bot
   * @param {string} botName - The name of the bot
   * @param {number} conversationID - The id of the conversation
   * @param {ConversationSpecificSettings=} newSettings - The new settings that the current conversation will be saved to
   * @returns {void}
   */
  const updateConversationSettings = useCallback(
    (botName: string, conversationID: number, newSettings?: ConversationSpecificSettings): void => {
      setConversationSettings((currentSettings) => {
        return {
          ...currentSettings,
          [botName]: { ...currentSettings[botName], [conversationID]: newSettings || defaultSettings[botName] },
        }
      })
    },
    [defaultSettings]
  )

  /**
   * Deletes the settings of the provided bot-conversation
   * @param {string} botName - The name of the bot
   * @param {number} conversationID - The id of the conversation
   * @param {ConversationSpecificSettings} newSettings - The new settings that the current conversation will be saved to
   * @returns {void}
   */
  const deleteConversationSettings = useCallback((conversationIDsToDelete: number[]): void => {
    setConversationSettings((currentSettings) => {
      const filteredConversations: ConversationsSettingsType = {}

      for (const key in currentSettings) {
        const conversationRecord = currentSettings[key]
        const filteredRecord: Record<number, ConversationSpecificSettings> = {}

        for (const numKey in conversationRecord) {
          const recordNumber = parseInt(numKey)
          if (!conversationIDsToDelete.includes(recordNumber)) {
            filteredRecord[recordNumber] = conversationRecord[recordNumber]
          }
        }

        if (Object.keys(filteredRecord).length > 0) {
          filteredConversations[key] = filteredRecord
        }
      }

      return filteredConversations
    })
  }, [])

  /**
   * Returns all the conversation settings of the provided bot
   * @param {string} botName - The new settings that the current conversation will be saved to
   * @returns {Record<number, ConversationSpecificSettings>}
   */
  const getAllBotConversationSettings = useCallback(
    (botName: string): Record<number, ConversationSpecificSettings> => {
      return conversationSettings[botName]
    },
    [conversationSettings]
  )

  return (
    <>
      <ConversationSettingsContext.Provider
        value={{
          availableConversationSettings,
          deleteConversationSettings,
          getAllBotConversationSettings,
          getConversationSettings,
          getDefaultSettings,
          updateConversationSettings,
          updateSettingsLanguage,
        }}
      >
        {children}
      </ConversationSettingsContext.Provider>
    </>
  )
}

export const useConversationSettingsContext = (): ConversationSettingsContextType =>
  useContext(ConversationSettingsContext)
