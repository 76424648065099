import { createContext, useContext, useEffect, useState } from 'react'

import { ChildrenProps, ConfigurationType } from '../types/types'

const initialConfig: ConfigurationType = {
  API_ENDPOINT: 'http://localhost:4103',
  API_TIMEOUT: 60,
  LANGUAGES: ['en-US'],
  INACTIVITY_TIMEOUT: 60,
  AUTH: {
    CLIENT_ID: '',
    AUTHORITY: '',
    REDIRECT_URI: '',
    SCOPES: [],
    KNOWN_AUTHORITIES: [],
  },
  isContextReady: false,
  ENV_TYPE: 'standard',
}

export const ConfigContext = createContext<ConfigurationType>(initialConfig)

export function ConfigProvider({ children }: ChildrenProps): JSX.Element {
  const [config, setConfig] = useState<ConfigurationType>(initialConfig)

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('config.json')
      const jsonData = await response.json()
      setConfig({ ...jsonData, isContextReady: true })
    }
    fetchData()
  }, [])

  return (
    <>
      <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
    </>
  )
}

export const useConfigContext = (): ConfigurationType => useContext(ConfigContext)
