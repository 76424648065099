import { KeyboardEvent, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { BiBookOpen, BiCheck, BiDotsHorizontalRounded, BiMessageAdd, BiSolidEditAlt, BiX } from 'react-icons/bi'
import { IconButton as ChakraIconButton } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { Text } from '@chakra-ui/layout'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu'
import { Box, Input } from '@chakra-ui/react'

import { Button } from 'components/buttons/Button'
import { IconButton } from 'components/buttons/IconButton'
import { TextIconButton } from 'components/buttons/TextIconButton'
import { TooltipButton } from 'components/buttons/TooltipButton'

import { useBotContext } from 'providers/BotProvider'
import { useFiltersContext } from 'providers/FiltersAndFormsProvider'
import { useI18Context } from 'providers/i18Provider'
import { ConversationType, useMessagesContext } from 'providers/MessageProvider'
import { useSettingsContext } from 'providers/SettingsProvider'
import { useThemeContext } from 'providers/ThemeProvider'
import { useUploadContext } from 'providers/UploadProvider'

import { ModalBox } from '../Modal'

import { Drawer } from './Drawer'

type ConversationDrawerProps = {
  botName: string
  isDrawerOpen: boolean
  isStreaming: boolean
  setIsDrawerOpen: (value: SetStateAction<boolean>) => void
}

export const ConversationDrawer = (props: ConversationDrawerProps) => {
  const { botName, isDrawerOpen, isStreaming, setIsDrawerOpen } = props

  const {
    addConversation,
    conversations,
    deleteConversations,
    getAllConversationsForDrawer,
    getCurrentConversationIDForBot,
    getNumberOfConversations,
    MAX_CONVERSATIONS,
    renameConversation,
    setActiveConversationIDForBot,
  } = useMessagesContext()
  const { deleteDocumentContent } = useUploadContext()
  const { isFetchingChart } = useBotContext()
  const { deleteSelectedFilterValues, deleteSelectedFormValues } = useFiltersContext()
  const { t } = useI18Context()
  const { isLightMode } = useSettingsContext()
  const { isOpen: isOpenDel, onOpen: onOpenDel, onClose: onCloseDel } = useDisclosure()
  const { isTablet, isDesktop } = useThemeContext()

  const inputFieldRef = useRef<{ [key: string]: HTMLInputElement | null }>({})

  const [isEditingInstance, setIsEditingInstance] = useState<Array<number>>([])
  const [conversationIDToDelete, setConversationIDToDelete] = useState<number | null>(null)

  const tooManyConversations = useMemo(
    () => getNumberOfConversations() >= MAX_CONVERSATIONS,
    [MAX_CONVERSATIONS, getNumberOfConversations]
  )

  const saveRenameChanges = (conversationItem: {
    conversation: ConversationType & { conversationID: number }
    inputValue: string
  }) => {
    const { conversation, inputValue } = conversationItem
    // Call setIsEditingInstance to remove the specific conversation from the "renaming" state
    setIsEditingInstance((prevState) => {
      const newState = [...prevState]
      const index = newState.findIndex((number) => number === conversation.conversationID)

      if (index !== -1) {
        newState.splice(index, 1)
      }
      return newState
    })
    // Call the renameConversation function to change it's displayName property
    renameConversation(botName, conversation.conversationID, inputValue)
  }

  const conversationList = useMemo(() => {
    return getAllConversationsForDrawer(botName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName, conversations, getAllConversationsForDrawer])

  const currentConversationID = useMemo(() => {
    return getCurrentConversationIDForBot(botName)
  }, [botName, getCurrentConversationIDForBot])

  // This useEffect is used to focus the cursor inside of the Input field of the conversation requested to be renamed
  useEffect(() => {
    if (isEditingInstance.length > 0) {
      const lastEditingId = isEditingInstance[isEditingInstance.length - 1]
      const inputRef = inputFieldRef.current[lastEditingId]
      if (inputRef) {
        inputRef.focus()
      }
    }
  }, [isEditingInstance])

  return {
    drawer: (
      <Drawer
        drawerIcon={BiBookOpen}
        drawerTitle={t('generic.conversation')}
        isOpen={isDrawerOpen}
        position="left"
        setIsOpen={setIsDrawerOpen}
      >
        <ModalBox
          isOpen={isOpenDel}
          modalBody={
            <Box>
              <Text as="h2" className="mb-2 font-bold">
                {t('deleteInstanceModal.areYouSureMessages')}
              </Text>
              <Text as="p">{t('deleteInstanceModal.clearExplanation')}</Text>
            </Box>
          }
          modalFooter={
            <Box className="flex flex-col gap-3 md:flex-row">
              <Button
                className="ml-auto text-sm md:text-base max-w-min"
                id="next-button"
                onClick={onCloseDel}
                aria-label="close"
              >
                {t('controls.cancel')}
              </Button>
              <Button
                className={`text-sm md:text-base ${
                  isLightMode
                    ? 'clear-messages-modal-button text-white bg-kpmgCobaltBlue hover:bg-kpmgCobaltBlueHover'
                    : 'text-black bg-white hover:bg-whiteHover'
                }`}
                id="next-button"
                onClick={() => {
                  if (conversationIDToDelete) {
                    // Need to delete all values in providers that used to belong to the conversation ID that has now been deleted
                    deleteConversations([conversationIDToDelete])
                    deleteDocumentContent([conversationIDToDelete])
                    deleteSelectedFormValues([conversationIDToDelete])
                    deleteSelectedFilterValues([conversationIDToDelete])
                    setConversationIDToDelete(null)
                  }
                  onCloseDel()
                }}
                aria-label="next"
              >
                {t('deleteInstanceModal.deleteInstance')}
              </Button>
            </Box>
          }
          modalHeader={t('generic.deleteInstance')}
          onClose={onCloseDel}
        />
        {Array.isArray(conversationList) && conversationList.length ? (
          <>
            <TooltipButton
              label={t('generic.tooManyConversations')}
              isDisabled={!isTablet || !tooManyConversations}
              button={
                <TextIconButton
                  isDisabled={isStreaming || isFetchingChart[botName] || tooManyConversations}
                  leftIcon={<BiMessageAdd className="text-lg md:text-xl" />}
                  className={`w-full mb-4 whitespace-normal ${!isTablet && 'text-sm'}`}
                  height={!isTablet ? 'var(--chakra-sizes-12)' : undefined}
                  onClick={() => {
                    // Create a new blank conversation instance and then close the drawer if on tablet/mobile view
                    addConversation(botName)
                    if (!isDesktop) {
                      setIsDrawerOpen(false)
                    }
                  }}
                  size={isTablet ? 'md' : 'sm'}
                >
                  {t('generic.newConversation')}
                </TextIconButton>
              }
            />
            {conversationList.map((item, conversationListIndex) => {
              const { conversationID, displayName } = item
              if (displayName) {
                return (
                  <Box className="flex justify-end mb-2" key={`${conversationID}_${conversationListIndex}`}>
                    <Box
                      className={`flex items-center justify-between w-full border rounded-md ${
                        isEditingInstance.includes(conversationID) && 'border-dashed'
                      } ${isLightMode ? 'bg-[#F5F5F5]' : 'bg-[#0A2849] '} ${
                        currentConversationID === conversationID
                          ? 'border-kpmgGray45'
                          : isLightMode
                            ? 'border-white'
                            : 'border-kpmgDarkBlue'
                      }`}
                    >
                      {isEditingInstance.includes(conversationID) ? (
                        <Input
                          className="px-2 text-xs md:text-sm"
                          variant="unstyled"
                          ref={(el) => (inputFieldRef.current[conversationID] = el)}
                          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                            if (event.key === 'Enter') {
                              // Save the rename value as the displayName for the specific conversation
                              event.preventDefault()
                              const inputValue = event.currentTarget.value
                              saveRenameChanges({ conversation: item, inputValue })
                            }
                          }}
                        />
                      ) : (
                        <Button
                          isDisabled={isStreaming || isFetchingChart[botName]}
                          className="inline-block w-full px-2 text-xs font-normal text-left truncate rounded-r-none md:text-sm"
                          variant="ghost"
                          onClick={() => {
                            // Change the active conversation to view and then close the drawer if on tablet/mobile view
                            setActiveConversationIDForBot(botName, conversationID)
                            if (!isDesktop) {
                              setIsDrawerOpen(false)
                            }
                          }}
                        >
                          {displayName}
                        </Button>
                      )}
                      {isEditingInstance.includes(conversationID) ? (
                        <IconButton
                          isDisabled={isStreaming || isFetchingChart[botName]}
                          variant="ghost"
                          className="rounded-l-none"
                          aria-label="confirm instance name"
                          iconName={BiCheck}
                          iconClassName="text-xl md:text-2xl"
                          onClick={() => {
                            // Get the value of the input field and then use this to update the displayName property for the specific conversation
                            const valueRef = inputFieldRef.current[conversationID]
                            if (valueRef) {
                              // Use the valueRef value as the input for saveRenameChanges
                              saveRenameChanges({ conversation: item, inputValue: valueRef.value })
                            }
                          }}
                        />
                      ) : (
                        <Menu>
                          <MenuButton
                            as={ChakraIconButton}
                            aria-label="options"
                            icon={<BiDotsHorizontalRounded className="text-xl md:text-2xl" />}
                            variant="ghost"
                            className="rounded-l-none"
                            isDisabled={isStreaming || isFetchingChart[botName]}
                          />
                          <MenuList>
                            <MenuItem
                              isDisabled={isStreaming || isFetchingChart[botName]}
                              icon={<BiX className="text-xl md:text-2xl" />}
                              onClick={() => {
                                // Before opening the modal to confirm the conversation delete, update the useState with the ID of the conversation being requested to get deleted
                                setConversationIDToDelete(conversationID)
                                onOpenDel()
                              }}
                            >
                              {t('generic.delete')}
                            </MenuItem>
                            <MenuItem
                              isDisabled={isStreaming || isFetchingChart[botName]}
                              icon={<BiSolidEditAlt className="text-xl md:text-2xl" />}
                              onClick={() => {
                                // Add the ID of the conversation to the useState for tracking which ones are actively being renamed
                                setIsEditingInstance((prevState) => {
                                  return [...prevState, conversationID]
                                })
                              }}
                            >
                              {t('generic.rename')}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      )}
                    </Box>
                  </Box>
                )
              }
              return null
            })}
          </>
        ) : (
          <Text className="text-sm">{t('generic.currentlyNoConversations')}</Text>
        )}
      </Drawer>
    ),
    button: {
      hideTooltipText: t('generic.hideConversation'),
      icon: BiBookOpen,
      onClick: () => setIsDrawerOpen(!isDrawerOpen),
      showTooltipText: t('generic.showConversation'),
      text: t('controls.conversation'),
    },
    isOpen: isDrawerOpen,
  }
}
