import { createContext, useContext, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import { BotFilterOptions, BotFilterValues, BotFormOptions, BotFormValues } from 'types/types'

type FiltersContextType = {
  deleteSelectedFilterValues: (conversationIDsToDelete: number[]) => void
  deleteSelectedFormValues: (conversationIDsToDelete: number[]) => void
  getAvailableFilterOptions: (botName: string) => BotFilterOptions | undefined
  getAvailableFormOptions: (botName: string) => BotFormOptions | undefined
  getDefaultFilterValues: (botName: string) => BotFilterValues | undefined
  getDefaultValues: (botName: string, conversationID: number) => (BotFormValues & BotFilterValues) | undefined
  getIsFetchingDocumentData: (botName: string) => boolean
  getSelectedFilterValues: (botName: string, conversationID: number) => BotFilterValues | undefined
  getSelectedFormValues: (botName: string, conversationID: number) => BotFormValues | undefined
  initializeFilterOptionsAndValues: (
    botName: string,
    filterOptions: BotFilterOptions,
    setValue: UseFormSetValue<BotFormValues & BotFilterValues>
  ) => void
  uncheckAllFilterValues: (filters: BotFilterValues['filters']) => BotFilterValues['filters']
  updateAvailableFilterOptions: (botName: string, filterOptions: BotFilterOptions) => void
  updateAvailableFormOptions: (botName: string, formOptions: BotFormOptions) => void
  updateIsFetchingDocumentData: (botName: string, isFetching: boolean) => void
  updateSelectedFilterAndFormValues: (
    botName: string,
    conversationID: number,
    newFilterValues?: BotFilterValues,
    newFormValues?: BotFormValues
  ) => void
  updateSelectedFilterValues: (botName: string, conversationID: number, filterValues: BotFilterValues) => void
  updateSelectedFormValues: (botName: string, conversationID: number, formValues: BotFormValues) => void
}

export const FiltersContext = createContext<FiltersContextType>({} as FiltersContextType)

type FiltersAndFormsProviderProps = {
  children?: React.ReactNode
}

export const FiltersAndFormsProvider = ({ children }: FiltersAndFormsProviderProps) => {
  // Options presented to the user based on selections. Used for remembering state when jumping between pages
  const [availableFilterOptions, setAvailableFilterOptions] = useState<Record<string, BotFilterOptions>>({})
  // Selections made by the user. Used for remembering state when jumping between pages
  const [selectedFilterValues, setSelectedFilterValues] = useState<Record<string, Record<number, BotFilterValues>>>({})

  // Options presented to the user based on selections. Used for remembering state when jumping between pages
  const [availableFormOptions, setAvailableFormOptions] = useState<Record<string, BotFormOptions>>({})
  // Selections made by the user. Used for remembering state when jumping between pages
  const [selectedFormValues, setSelectedFormValues] = useState<Record<string, Record<number, BotFormValues>>>({})

  // These are the values we can use to reset filter values. They are the "empty" or "default" values for each bot's filters
  const [defaultFilterValues, setDefaultFilterValues] = useState<Record<string, BotFilterValues>>({})

  // These are the boolean values that represent whether a bot is currently fetching document data
  const [isFetchingDocumentData, setIsFetchingDocumentData] = useState<Record<string, boolean>>({})

  const getDefaultValues = (botName: string, conversationID: number): (BotFormValues & BotFilterValues) | undefined => {
    const filterValues = getSelectedFilterValues(botName, conversationID)
    const formValues = getSelectedFormValues(botName, conversationID)
    return filterValues && formValues ? { ...filterValues, ...formValues } : undefined
  }
  const getSelectedFilterValues = (botName: string, conversationID: number): BotFilterValues | undefined => {
    return selectedFilterValues?.[botName]?.[conversationID]
  }
  const getSelectedFormValues = (botName: string, conversationID: number): BotFormValues | undefined => {
    return selectedFormValues?.[botName]?.[conversationID]
  }
  const getAvailableFilterOptions = (botName: string): BotFilterOptions | undefined => {
    return availableFilterOptions?.[botName]
  }
  const getAvailableFormOptions = (botName: string): BotFormOptions | undefined => {
    return availableFormOptions?.[botName]
  }
  const getDefaultFilterValues = (botName: string): BotFilterValues | undefined => {
    return defaultFilterValues[botName]
  }
  const getIsFetchingDocumentData = (botName: string): boolean => {
    return isFetchingDocumentData[botName] ?? false
  }

  const updateSelectedFilterValues = (botName: string, conversationID: number, filterValues: BotFilterValues) => {
    setSelectedFilterValues((currentSelectedFilterValues) => {
      return {
        ...currentSelectedFilterValues,
        [botName]: { ...currentSelectedFilterValues[botName], [conversationID]: filterValues },
      }
    })
  }
  const updateSelectedFormValues = (botName: string, conversationID: number, formValues: BotFormValues) => {
    setSelectedFormValues((currentSelectedFormValues) => {
      return {
        ...currentSelectedFormValues,
        [botName]: { ...currentSelectedFormValues[botName], [conversationID]: formValues },
      }
    })
  }
  const updateAvailableFilterOptions = (botName: string, filterOptions: BotFilterOptions) => {
    setAvailableFilterOptions((currentAvailableFilterOptions) => {
      return {
        ...currentAvailableFilterOptions,
        [botName]: {
          filters: { ...(currentAvailableFilterOptions[botName]?.filters || {}), ...filterOptions.filters },
        },
      }
    })
  }
  const updateAvailableFormOptions = (botName: string, formOptions: BotFormOptions) => {
    setAvailableFormOptions((currentAvailableFormOptions) => {
      return {
        ...currentAvailableFormOptions,
        [botName]: { ...currentAvailableFormOptions[botName], ...formOptions },
      }
    })
  }
  const updateIsFetchingDocumentData = (botName: string, isFetching: boolean) => {
    setIsFetchingDocumentData((currentIsFetchingDocumentData) => {
      return { ...currentIsFetchingDocumentData, [botName]: isFetching }
    })
  }

  // Based on new filter and form values, update those for the specific conversation ID in the function payload
  const updateSelectedFilterAndFormValues = (
    botName: string,
    conversationID: number,
    newFilterValues?: BotFilterValues,
    newFormValues?: BotFormValues
  ): void => {
    updateSelectedFilterValues(botName, conversationID, newFilterValues || defaultFilterValues[botName])
    updateSelectedFormValues(botName, conversationID, newFormValues || { userQuery: '', clicked: null })
  }

  // This function returns all filter values with isChecked set to false
  const uncheckAllFilterValues = (filters: BotFilterValues['filters']): BotFilterValues['filters'] => {
    const updatedFilters: BotFilterValues['filters'] = {}

    Object.keys(filters).forEach((filterName) => {
      updatedFilters[filterName] = {
        ...filters[filterName],
        isChecked: false,
      }
    })

    return updatedFilters
  }

  // Based on conversationIDs in the function payload, delete those Record's selected filter values
  const deleteSelectedFilterValues = (conversationIDsToDelete: number[]) => {
    setSelectedFilterValues((currentSelectedFilterValues) => {
      const filteredConversations: Record<string, Record<number, BotFilterValues>> = {}

      for (const key in currentSelectedFilterValues) {
        const conversationRecord = currentSelectedFilterValues[key]
        const filteredRecord: Record<number, BotFilterValues> = {}

        for (const numKey in conversationRecord) {
          const recordNumber = parseInt(numKey)
          if (!conversationIDsToDelete.includes(recordNumber)) {
            filteredRecord[recordNumber] = conversationRecord[recordNumber]
          }
        }

        if (Object.keys(filteredRecord).length > 0) {
          filteredConversations[key] = filteredRecord
        }
      }

      return filteredConversations
    })
  }
  // Based on conversation IDs in the function payload, delete those Record's selected form values
  const deleteSelectedFormValues = (conversationIDsToDelete: number[]) => {
    setSelectedFormValues((currentSelectedFormValues) => {
      const filteredConversations: Record<string, Record<number, BotFormValues>> = {}

      for (const key in currentSelectedFormValues) {
        const conversationRecord = currentSelectedFormValues[key]
        const filteredRecord: Record<number, BotFormValues> = {}

        for (const numKey in conversationRecord) {
          const recordNumber = parseInt(numKey)
          if (!conversationIDsToDelete.includes(recordNumber)) {
            filteredRecord[recordNumber] = conversationRecord[recordNumber]
          }
        }

        if (Object.keys(filteredRecord).length > 0) {
          filteredConversations[key] = filteredRecord
        }
      }

      return filteredConversations
    })
  }

  // This function creates for us the "empty" or "default" values for the bot so that we have a starting point for updating values over time
  const initializeFilterOptionsAndValues = (
    botName: string,
    filterOptions: BotFilterOptions,
    setValue: UseFormSetValue<BotFormValues & BotFilterValues>
  ) => {
    const filterKeys = Object.keys(filterOptions.filters)
    if (filterKeys.length === 0) {
      setAvailableFilterOptions((currentAvailableFilterOptions) => {
        return {
          ...currentAvailableFilterOptions,
          [botName]: {
            filters: {},
          },
        }
      })
    } else {
      updateAvailableFilterOptions(botName, filterOptions)
    }
    const filters = filterKeys.reduce(
      (acc, botName) => {
        acc[botName] = {
          items: [],
          isChecked: true,
        }
        return acc
      },
      {} as BotFilterValues['filters']
    )
    setDefaultFilterValues((currentDefaultFilterValues) => {
      return { ...currentDefaultFilterValues, [botName]: { filters: filters } }
    })
    setValue('filters', filters)
  }

  return (
    <>
      <FiltersContext.Provider
        value={{
          deleteSelectedFilterValues,
          deleteSelectedFormValues,
          getAvailableFilterOptions,
          getAvailableFormOptions,
          getDefaultFilterValues,
          getDefaultValues,
          getIsFetchingDocumentData,
          getSelectedFilterValues,
          getSelectedFormValues,
          initializeFilterOptionsAndValues,
          uncheckAllFilterValues,
          updateAvailableFilterOptions,
          updateAvailableFormOptions,
          updateIsFetchingDocumentData,
          updateSelectedFilterAndFormValues,
          updateSelectedFilterValues,
          updateSelectedFormValues,
        }}
      >
        {children}
      </FiltersContext.Provider>
    </>
  )
}

export const useFiltersContext = (): FiltersContextType => useContext(FiltersContext)
