import React from 'react'
import { Button as ChakraButton, ButtonProps } from '@chakra-ui/button'

type TextButtonProps = Omit<ButtonProps, 'leftIcon' | 'rightIcon'>

export const Button = React.forwardRef<HTMLButtonElement, TextButtonProps>((props, ref) => {
  const { children } = props

  return (
    <ChakraButton {...props} ref={ref} tabIndex={0}>
      {children}
    </ChakraButton>
  )
})
