import { Box, Text } from '@chakra-ui/layout'

import { useI18Context } from '../../providers/i18Provider'
import { DisclaimerProperty } from '../../types/types'

import { Link } from './../Link'

type DisclaimerProps = {
  botName: string
  disclaimer: DisclaimerProperty[]
}

export const Disclaimer = (props: DisclaimerProps) => {
  const { disclaimer, botName } = props
  const { i18n, getTForNS } = useI18Context()

  const botT = getTForNS(botName.toLocaleLowerCase())

  // If the Bot Configuration translations do not include a "disclaimer", don't try and return an empty disclaimer
  if (!i18n.exists('disclaimer', { ns: botName.toLocaleLowerCase() })) return null

  return (
    <Box className="mt-4 text-xs md:text-sm text-kpmgPurple">
      {disclaimer.map((property, disclaimerIndex: number) => {
        return (
          <Box
            as="span"
            key={`${property.type}_${property.value}_${disclaimerIndex}`}
            // Add in a space at the end of each part if we are not at the last property
            className={`${disclaimerIndex !== disclaimer.length && !property.noRightMargin && 'mr-1'}`}
          >
            {/* By checking i18n.exists(property.value, { ns: botName.toLocaleLowerCase() }) makes sure that we got a value back from the translation file using the key "property.value" */}
            {property.type === 'title' && i18n.exists(property.value, { ns: botName.toLocaleLowerCase() }) ? (
              <Text as="span" className="font-bold">
                {botT(property.value)}:
              </Text>
            ) : property.type === 'text' && i18n.exists(property.value, { ns: botName.toLocaleLowerCase() }) ? (
              <Text as="span">{botT(property.value)}</Text>
            ) : property.type === 'link' &&
              i18n.exists(property.value.text, { ns: botName.toLocaleLowerCase() }) &&
              i18n.exists(property.value.url, { ns: botName.toLocaleLowerCase() }) ? (
              <Link
                classToAdd="text-lightLink hover:text-lightLinkHover"
                external
                name={botT(property.value.text)}
                url={botT(property.value.url)}
              />
            ) : null}
          </Box>
        )
      })}
    </Box>
  )
}
