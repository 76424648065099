import { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react'

import type { ChatMessage, FEUIConfig } from 'types/types'

import { isKeyOfObject, useConversationSettingsContext } from './ConversationSettingsProvider'
import { useFiltersContext } from './FiltersAndFormsProvider'

export type ConversationType = { displayName: string | null; messages: ChatMessage[]; summary: Array<string> | null }

type ConversationsRecordType = Record<string, Record<number, ConversationType>>

type MessagesContextType = {
  addConversation: (botName: string) => void
  addMessage: (botName: string, newMessage: ChatMessage) => void
  clearAllMessagesInConversation: (botName: string) => void
  conversations: ConversationsRecordType
  deleteConversations: (conversationIDsToDelete: number[]) => void
  findEmptyConversationID: (botConversations: Record<number, ConversationType>) => number | undefined
  getAllConversationsForDrawer: (botName: string) => Array<ConversationType & { conversationID: number }>
  getConversation: (botName: string, conversationID: number) => ConversationType | undefined
  getCurrentConversationIDForBot: (botName: string) => number | undefined
  getMessages: (botName: string) => ChatMessage[]
  getNumberOfBotConversations: (botName: string) => number
  getNumberOfConversations: () => number
  getSummary: (botName: string) => string | null
  MAX_CONVERSATIONS: number
  renameConversation: (botName: string, conversationID: number, newConversationName: string) => void
  setConversations: Dispatch<SetStateAction<ConversationsRecordType>>
  setConversationSummary: (botName: string, summary: string | null, chatSummaryMemory: number) => void
  setActiveConversationIDForBot: (botName: string, conversationID: number | null) => void
  setStreamingMessage: Dispatch<SetStateAction<Record<string, ChatMessage | null>>>
  setStreamingSummary: Dispatch<SetStateAction<Record<string, string | null>>>
  streamingMessage: Record<string, ChatMessage | null>
  streamingSummary: Record<string, string | null>
}

export const MessagesContext = createContext<MessagesContextType>({} as MessagesContextType)

type MessagesProviderProps = {
  children?: React.ReactNode
  config?: FEUIConfig[]
}

export const MessagesProvider = ({ children }: MessagesProviderProps) => {
  const MAX_CONVERSATIONS = 15
  const [conversations, setConversations] = useState<ConversationsRecordType>({})
  const [streamingMessage, setStreamingMessage] = useState<Record<string, ChatMessage | null>>({})
  const [streamingSummary, setStreamingSummary] = useState<Record<string, string | null>>({})

  const [activeConversationID, setActiveConversationID] = useState<Record<string, number>>({})

  const {
    availableConversationSettings,
    deleteConversationSettings,
    getConversationSettings,
    updateConversationSettings,
  } = useConversationSettingsContext()

  const { updateSelectedFilterAndFormValues } = useFiltersContext()

  /**
   * Adds a new empty instance of a conversation
   * @param {string} botName - The name of the bot to which the conversation will be added.
   * @returns {void}
   */
  const addConversation = (botName: string): void => {
    const conversationID = getCurrentConversationIDForBot(botName)
    const botConversations = conversations[botName]
    let newConversationID = Date.now()

    if (botName in conversations && conversationID) {
      const emptyConversationID = findEmptyConversationID(botConversations)

      if (conversationID !== emptyConversationID) {
        if (emptyConversationID) {
          newConversationID = emptyConversationID
        } else {
          setConversations((currentConversations) => ({
            ...currentConversations,
            [botName]: {
              ...currentConversations[botName],
              [newConversationID]: { displayName: null, messages: [], summary: null },
            },
          }))
          // Running the below update functions will create default instances of filter, form, and conversation settings values for the new conversation ID
          updateSelectedFilterAndFormValues(botName, newConversationID)
          updateConversationSettings(botName, newConversationID)
        }
        setActiveConversationIDForBot(botName, newConversationID)
      }
    }
  }

  /**
   * Adds a message to the current conversation opened, creating a non-empty conversation, and updating the settings for the new conversation ID
   * @param {string} botName - The name of the bot associated with the conversation.
   * @param {ChatMessage} newMessage - The new message to be added to the conversation.
   * @returns {void}
   */
  const addMessage = (botName: string, newMessage: ChatMessage): void => {
    const conversationID = getCurrentConversationIDForBot(botName)
    if (botName in conversations && conversationID && conversationID in conversations[botName]) {
      setConversations((currentConversations) => {
        return {
          ...currentConversations,
          [botName]: {
            ...currentConversations[botName],
            [conversationID]: {
              ...currentConversations[botName][conversationID],
              displayName: currentConversations[botName][conversationID].displayName ?? newMessage.content,
              messages: [...currentConversations[botName][conversationID].messages, newMessage],
            },
          },
        }
      })
    } else {
      const newConversationID = Date.now()
      setConversations((currentConversations) => {
        return {
          ...currentConversations,
          [botName]: {
            ...currentConversations[botName],
            [newConversationID]: {
              displayName: newMessage.content,
              messages: [newMessage],
              summary: null,
            },
          },
        }
      })

      // If there are settings available, sync the settings from the empty to the new ID
      const settingsToCopy = getConversationSettings(
        botName,
        conversations[botName] ? findEmptyConversationID(conversations[botName]) || 0 : 0
      )
      if (isKeyOfObject(botName, availableConversationSettings) && settingsToCopy) {
        updateConversationSettings(botName, newConversationID, settingsToCopy)
      }
      setActiveConversationIDForBot(botName, newConversationID)
    }
  }

  /**
   * Clears all messages from the current conversation opened
   * @param {string} botName - The name of the bot associated with the conversation.
   * @returns {void}
   */
  const clearAllMessagesInConversation = (botName: string): void => {
    const conversationID = getCurrentConversationIDForBot(botName)
    if (botName in conversations && conversationID && conversationID in conversations[botName]) {
      setConversations((currentConversations) => ({
        ...currentConversations,
        [botName]: {
          ...currentConversations[botName],
          [conversationID]: { ...currentConversations[botName][conversationID], messages: [] },
        },
      }))
    }
  }

  /**
   * Deletes an entire conversation instance and the settings associated with it
   * @param {string} botName - The name of the bot associated with the conversation.
   * @param {number} conversationIDToDelete - The ID of the conversation to be deleted.
   * @returns {void}
   */
  const deleteConversations = (conversationIDsToDelete: number[]): void => {
    setConversations((currentConversations) => {
      const filteredConversations: ConversationsRecordType = {}

      for (const key in currentConversations) {
        const conversationRecord = currentConversations[key]
        const filteredRecord: Record<number, ConversationType> = {}

        for (const numKey in conversationRecord) {
          const recordNumber = parseInt(numKey)
          if (!conversationIDsToDelete.includes(recordNumber)) {
            filteredRecord[recordNumber] = conversationRecord[recordNumber]
          }
        }

        if (Object.keys(filteredRecord).length > 0) {
          filteredConversations[key] = filteredRecord
        }
      }

      return filteredConversations
    })
    // If we are deleting a conversation that used to be the current conversation ID opened, reset this value
    setActiveConversationID((currentActiveConversationID) => {
      const filteredDocContents: Record<string, number> = {}

      for (const key in currentActiveConversationID) {
        const recordNumber = currentActiveConversationID[key]
        if (!conversationIDsToDelete.includes(recordNumber)) {
          filteredDocContents[key] = currentActiveConversationID[key]
        }
      }

      return filteredDocContents
    })

    // Delete the settings that are associated with the deleted conversation
    deleteConversationSettings(conversationIDsToDelete)
  }

  /**
   * Returns the ID of an empty conversation instance
   * @param {Record<number, ConversationType>} botConversations - The conversations associated with the bot.
   * @returns {number | undefined} The ID of the empty conversation instance, if found; otherwise, undefined.
   */
  const findEmptyConversationID = (botConversations: Record<number, ConversationType>): number | undefined => {
    const emptyConversationEntry = Object.entries(botConversations).find(
      ([, conversation]) => conversation.messages.length === 0 && conversation.displayName === null
    )
    return emptyConversationEntry ? Number(emptyConversationEntry[0]) : undefined
  }

  /**
   * Returns all conversations for a specific bot, including the key as a property, in reverse order
   * @param {string} botName - The name of the bot to retrieve conversations for.
   * @returns {Array<ConversationType & { conversationID: number }>} An array containing all conversations for the specified bot, with each conversation object extended to include the conversationID property.
   */
  const getAllConversationsForDrawer = (botName: string): Array<ConversationType & { conversationID: number }> => {
    if (botName in conversations) {
      const conversationsArray = Object.entries(conversations[botName]).map(([key, value]) => ({
        ...value, // Spread the properties of the original ConversationType object
        conversationID: Number(key), // Add the key as conversationID and ensure it's a number
      }))
      return [...conversationsArray].reverse()
    }
    return []
  }

  /**
   * Returns the data related to a conversation
   * @param {string} botName - The name of the bot associated with the conversation.
   * @param {number} conversationID - The ID of the conversation to retrieve data for.
   * @returns {ConversationType | undefined} The data related to the specified conversation, or undefined if the conversation does not exist.
   */
  const getConversation = (botName: string, conversationID: number): ConversationType | undefined => {
    if (botName in conversations && conversationID in conversations[botName]) {
      return conversations[botName][conversationID]
    }
  }

  /**
   * Returns the ID of the conversation opened on a specific bot
   * @param {string} botName - The name of the bot to retrieve the current conversation ID for.
   * @returns {number | undefined} The ID of the conversation opened on the specified bot, or undefined if not found.
   */
  const getCurrentConversationIDForBot = useCallback(
    (botName: string): number | undefined => {
      if (botName in activeConversationID) {
        return activeConversationID[botName]
      }
    },
    [activeConversationID]
  )

  /**
   * Returns the message data for the current conversation opened on a bot
   * @param {string} botName - The name of the bot associated with the conversation.
   * @returns {ChatMessage[]} An array containing the message data for the specified conversation.
   */
  const getMessages = (botName: string): ChatMessage[] => {
    const conversationID = getCurrentConversationIDForBot(botName)
    if (botName in conversations && conversationID && conversationID in conversations[botName]) {
      return conversations[botName][conversationID].messages
    }
    return []
  }

  /**
   * Returns the number of conversations across all bots
   * * @param {string} botName - The name of the bot associated with the conversation.
   * @returns {number} The total number of conversations across all bots.
   */
  const getNumberOfBotConversations = (botName: string): number => {
    if (botName in conversations) {
      return Object.keys(conversations[botName]).length
    }
    return 0
  }

  /**
   * Returns the number of conversations across all bots
   * @returns {number} The total number of conversations across all bots.
   */
  const getNumberOfConversations = (): number => {
    return Object.values(conversations).reduce((acc, userConversations) => {
      return acc + Object.keys(userConversations).length
    }, 0)
  }

  /**
   * Returns the summary value of a bot
   * @param {string} botName - The name of the bot associated with the conversation.
   * @returns {string | null} The summary value of the specified bot, or null if not found.
   */
  const getSummary = useCallback(
    (botName: string): string | null => {
      const conversationID = getCurrentConversationIDForBot(botName)
      if (botName in conversations && conversationID && conversationID in conversations[botName]) {
        return conversations[botName][conversationID]?.summary?.join('\n') || null
      }
      return null
    },
    [conversations, getCurrentConversationIDForBot]
  )

  /**
   * Updates the displayName property of a specific conversation
   * @param {string} botName - The name of the bot associated with the conversation.
   * @param {number} conversationID - The ID of the conversation to update.
   * @param {string} newConversationName - The new display name for the conversation.
   * @returns {void}
   */
  const renameConversation = (botName: string, conversationID: number, newConversationName: string): void => {
    if (botName in conversations && conversationID in conversations[botName] && newConversationName.trim().length) {
      setConversations((currentConversations) => {
        return {
          ...currentConversations,
          [botName]: {
            ...currentConversations[botName],
            [conversationID]: { ...currentConversations[botName][conversationID], displayName: newConversationName },
          },
        }
      })
    }
  }

  /**
   * Updates the summary property of a specific conversation
   * @param {string} botName - The name of the bot associated with the conversation.
   * @param {string | null} summary - The new summary value for the conversation.
   * @returns {void}
   */
  const setConversationSummary = (botName: string, summary: string | null, chatSummaryMemory: number): void => {
    const conversationID = getCurrentConversationIDForBot(botName)
    if (botName in conversations && conversationID && conversationID in conversations[botName]) {
      setConversations((currentConversations) => {
        const currentSummary = currentConversations[botName][conversationID]?.summary
        if (summary === null) {
          // Update summary to null and return updated state
          return {
            ...currentConversations,
            [botName]: {
              ...currentConversations[botName],
              [conversationID]: { ...currentConversations[botName][conversationID], summary: null },
            },
          }
        }

        let summaryToSet: Array<string>
        if (Array.isArray(currentSummary)) {
          summaryToSet =
            chatSummaryMemory && currentSummary.length >= chatSummaryMemory
              ? // The current chatSummary contains more than 10 items, so remove the first element and add the new responseSummary
                [...currentSummary.slice(1), summary]
              : // There is no limitation on the number of summaries we can keep ahold of, so add the new responseSummary
                [...currentSummary, summary]
        } else {
          // There aren't any summaries yet set for this particular bot, so set the summary with just the new responseSummary
          summaryToSet = [summary]
        }
        return {
          ...currentConversations,
          [botName]: {
            ...currentConversations[botName],
            [conversationID]: { ...currentConversations[botName][conversationID], summary: summaryToSet },
          },
        }
      })
    }
  }

  /**
   * Updates the ID of the conversation that is currently opened on a bot
   * @param {string} botName - The name of the bot to update the current conversation ID for.
   * @param {number} conversationID - The ID of the conversation to set as the current conversation.
   * @returns {void}
   */
  const setActiveConversationIDForBot = (botName: string, conversationID: number | null): void => {
    if (typeof conversationID === 'number') {
      setActiveConversationID((currentActiveConversationID) => {
        return { ...currentActiveConversationID, [botName]: conversationID }
      })
    } else {
      setActiveConversationID((currentActiveConversationID) => {
        const currentConversationCopy = { ...currentActiveConversationID }
        delete currentConversationCopy[botName]
        return currentConversationCopy
      })
    }
  }

  return (
    <>
      <MessagesContext.Provider
        value={{
          addConversation,
          addMessage,
          clearAllMessagesInConversation,
          conversations,
          deleteConversations,
          findEmptyConversationID,
          getAllConversationsForDrawer,
          getConversation,
          getCurrentConversationIDForBot,
          getMessages,
          getNumberOfBotConversations,
          getNumberOfConversations,
          getSummary,
          MAX_CONVERSATIONS,
          renameConversation,
          setConversations,
          setConversationSummary,
          setActiveConversationIDForBot,
          setStreamingMessage,
          setStreamingSummary,
          streamingMessage,
          streamingSummary,
        }}
      >
        {children}
      </MessagesContext.Provider>
    </>
  )
}

export const useMessagesContext = (): MessagesContextType => useContext(MessagesContext)
