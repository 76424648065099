import { Box, Text } from '@chakra-ui/react'
import DOMPurify from 'dompurify'
import htmlReactParser from 'html-react-parser'

import { documentsMatchInString } from 'utils/documentUtils/documentsMatchInString'

const allowedTags = [
  'table',
  'tr',
  'td',
  'th',
  'thead',
  'tbody',
  'tfoot',
  'caption',
  'colgroup',
  'col',
  'body',
  'p',
  'ol',
  'ul',
  'li',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'span',
  'b',
  'em',
  'i',
  'small',
  'strong',
  'sub',
  'sup',
  'ins',
  'del',
  'mark',
  // DomPurify needs to know that it should read the text from the tags
  // that's why we need to pass #text
  '#text',
]

const allowedAttributes = ['colspan', 'rowspan']

type HTMLStringParserProps = {
  htmlString: string
  getDocumentName?: (document: string) => {
    id: string
    label: string
  }
}

export const HTMLStringParser = ({ htmlString, getDocumentName }: HTMLStringParserProps) => {
  if (!htmlString) return null

  const contextList = documentsMatchInString(htmlString)

  if (!contextList) {
    return <span>return</span>
  }

  return (
    <Box>
      {contextList.map((item, index) => {
        // Sanitize the HTML
        const sanitizedContext = DOMPurify.sanitize(item.text, {
          ALLOWED_TAGS: allowedTags,
          ALLOWED_ATTR: allowedAttributes,
          // remove content from non-allow-listed nodes
          KEEP_CONTENT: false,
        })

        // Use html-react-parser to parse the sanitized html string into React elements
        const textToRender = htmlReactParser(sanitizedContext)

        return (
          <Box key={`${item.text}__${index}`} className={`${contextList.length > 1 && 'mt-5'}`}>
            {'document' in item && getDocumentName && (
              <Text className="text-xs font-bold">{getDocumentName(item.document).label}</Text>
            )}
            <Box className="text-xs html-string-container">{textToRender}</Box>
          </Box>
        )
      })}
    </Box>
  )
}
