import { Badge, Text } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/tooltip'

import { removePunctuation } from 'utils/StringUtils'

import { useI18Context } from 'providers/i18Provider'
import { useThemeContext } from 'providers/ThemeProvider'

import { MarkdownRenderer } from './Markdown'

type BotVerifiedMesssageProps = {
  content: string
  arrayToMatch: string[]
  forceMarkdown?: boolean
}

export const BotVerifiedMessage = ({ content, arrayToMatch, forceMarkdown = true }: BotVerifiedMesssageProps) => {
  const { t } = useI18Context()
  const { isTablet } = useThemeContext()

  const regex = /"(?=(?:(?:[^"]*"){2})*[^"]*$)/g

  if (forceMarkdown) {
    return <MarkdownRenderer content={content} />
  }

  // replace() method is changing all instances of unicode characters "\u{201c}" (left double quotation mark character) and "\u{201d}" (right double quotation mark character) with a regular quotation mark
  let contentArray = content
    .trim()
    .replace(/\u{201c}|\u{201d}/gu, '"')
    .split(regex)

  if (contentArray.length > 0) {
    contentArray = contentArray.map((str, index) => {
      // if it is the last item, we don't add the quote back
      if (contentArray.length - 1 === index) {
        return str
      } else {
        return str + '"'
      }
    })
  }

  if (content.length === 0) {
    return null
  }

  return (
    <>
      {contentArray?.map((item, index) => {
        const contentItemMatch = item.match(/"([^"]+)"/)
        if (
          arrayToMatch.some((matchString) => {
            // contentItemMatch[1].split(' ').length > 5 is used to match only quotations that exceed 5 words
            if (contentItemMatch !== null && contentItemMatch[1].split(' ').length > 5) {
              return removePunctuation(matchString)
                .toLowerCase()
                .includes(removePunctuation(contentItemMatch[1]).toLowerCase())
            } else {
              return false
            }
          })
        ) {
          return (
            <Text as="span" key={`${item}__${index}`}>
              {item}
              <Tooltip
                className="text-xs text-black bg-kpmgGray5"
                label={t('generic.verifiedExplanation')}
                placement="top"
                openDelay={200}
                isDisabled={!isTablet}
              >
                <Badge variant="solid" colorScheme="green" className="mx-1">
                  {t('generic.verified')}
                </Badge>
              </Tooltip>
            </Text>
          )
        }
        return (
          <Text as="span" key={`${item}__${index}`}>
            {item}
          </Text>
        )
      })}
    </>
  )
}
