import XRegExp from 'xregexp'

// function to sanitize unicode strings
const sanitizeChatInput = (inputString: string): string => {
  // p{L} - Letters, p{N} - Numbers, p{Sm} - math symbols, p{Sc} - currency symbols
  const allowedPattern = XRegExp('^[\\p{L}\\p{N}\\p{Sm}\\p{Sc}\\p{P}\\s,.!*?-]*$')

  // bidirectional text control characters, zero-width characters
  const problematicPattern = XRegExp('[\\p{Cf}\\p{Zl}\\p{Zp}\\p{Cc}\\u200E\\u200F\\u202A-\\u202E]+', 'g')

  const sanitizedInput = XRegExp.replace(inputString, problematicPattern, '')

  if (XRegExp.test(sanitizedInput, allowedPattern)) {
    // input is safe
    return sanitizedInput
  } else {
    const disallowedPattern = XRegExp('[^\\p{L}\\p{N}\\s,.!?-]', 'g')
    return XRegExp.replace(inputString, disallowedPattern, '')
  }
}

export { sanitizeChatInput }
