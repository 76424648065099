import { KeyboardEvent, MutableRefObject, useMemo, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { BiHistory, BiMessageRoundedDots } from 'react-icons/bi'
import { IconButton } from '@chakra-ui/button'
import { Box, Text } from '@chakra-ui/layout'
import { Menu, MenuButton, MenuGroup, MenuItem, MenuList } from '@chakra-ui/menu'

import { useI18Context } from '../providers/i18Provider'
import { useSettingsContext } from '../providers/SettingsProvider'
import { ChatMessage } from '../types/types'

type RecentSearchesProps = {
  messages: ChatMessage[] | null
  inputRef: MutableRefObject<HTMLTextAreaElement | null>
}

export const RecentSearches = ({ messages, inputRef }: RecentSearchesProps) => {
  const lastMenuItemRef = useRef<HTMLButtonElement>(null)
  const { t } = useI18Context()
  const { setValue, getValues } = useFormContext()
  const { isLightMode } = useSettingsContext()

  const handleRecentSearchClick = (recentSearch: string) => {
    setValue('userQuery', recentSearch, { shouldValidate: false })
    if (inputRef && inputRef.current) {
      // focus the chat input and set the caret's position to the end of the string
      inputRef.current.focus()
      inputRef.current.selectionStart = getValues('userQuery') ? getValues('userQuery').length - 1 : 0
      inputRef.current.scrollTop = inputRef.current.scrollHeight
    }
  }

  const handleMenuOpen = (menuItemToFocus: HTMLButtonElement | null) => {
    if (!menuItemToFocus) return

    // timeout is to make sure this fires AFTER the menu is rendered
    setTimeout(() => {
      menuItemToFocus.focus()
    })
  }

  const userMessages = useMemo(() => {
    return messages?.filter((message) => message.role === 'user')
  }, [messages])

  const formatUserMessageDisplayText = (text: string, maxLength: number, endLength: number): string => {
    if (text.length <= maxLength) {
      return text
    }

    // find the nearest space before truncation point
    let truncationPoint = text.lastIndexOf(' ', maxLength - endLength - 3)
    if (truncationPoint === -1) {
      // if no space is found - fallback
      truncationPoint = maxLength - endLength - 3
    }

    // find the space near the end
    const endStart = text.length - endLength
    let endSpace = text.indexOf(' ', endStart)
    if (endSpace === -1) {
      // no space is found - fallback
      endSpace = text.length
    }

    const startText = text.substring(0, truncationPoint)
    const endText = text.substring(endSpace).trim()

    return `${startText}...${endText}`
  }

  // maxLength = char length before ellipsis, endLength = char length after ellipsis
  const [displayMaxLength, displayEndLength] = [500, 20]

  if (!userMessages || !userMessages?.length) return null

  return (
    <Menu onOpen={() => handleMenuOpen(lastMenuItemRef.current)} flip={false} placement="top-start">
      <MenuButton
        aria-label="menu"
        as={IconButton}
        icon={<BiHistory className="text-white md:text-2xl" />}
        variant="ghost"
        className={`mr-2 md:mr-3 focus:shadow-none h-full p-3 transition-colors bg-transparent border rounded-xl border-white background-button hover:bg-kpmgCobaltBlue ${
          isLightMode ? 'focus:ring-light ' : 'focus:ring-dark'
        }`}
        test-id="recent-messages-menu-button"
      />
      <MenuList
        className={`overflow-hidden max-w-[85vw] md:max-w-[60vw] break-words ${
          isLightMode ? 'bg-white' : 'background-secondary bg-kpmgDarkBlue'
        }`}
      >
        <MenuGroup className="flex flex-col" title={t('generic.previousSearches')}>
          <Box className="overflow-y-auto max-h-[30vh]">
            {userMessages
              .filter((userMessage, index) => {
                return index === userMessages.findIndex((element) => userMessage.content === element.content)
              })
              .map((userMessage, index, originalArray) => {
                return (
                  <MenuItem
                    className={`${
                      isLightMode
                        ? 'bg-white hover:bg-whiteHover focus:bg-whiteHover'
                        : 'background-menu-item bg-kpmgDarkBlue hover:bg-kpmgCobaltBlue focus:bg-kpmgCobaltBlue'
                    }`}
                    key={`user_message_${index}`}
                    onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                      if (event.key === 'Enter') {
                        handleRecentSearchClick(userMessage.content)
                      }
                    }}
                    onClick={() => handleRecentSearchClick(userMessage.content)}
                    tabIndex={index}
                    ref={index === originalArray.length - 1 ? lastMenuItemRef : null}
                  >
                    <BiMessageRoundedDots className="min-w-[20px] inline-block" />
                    <Text as="span" className="px-3">
                      {formatUserMessageDisplayText(userMessage.content, displayMaxLength, displayEndLength)}
                    </Text>
                  </MenuItem>
                )
              })}
          </Box>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}
