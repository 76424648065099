import { BiX } from 'react-icons/bi'
import { Alert, AlertDescription, AlertTitle } from '@chakra-ui/alert'
import { Box, Text } from '@chakra-ui/layout'
import { Collapse } from '@chakra-ui/transition'

import { useSettingsContext } from 'providers/SettingsProvider'

import { IconButton } from './buttons/IconButton'

type BannerProps = {
  className?: string
  description: JSX.Element
  isVisible: boolean
  onClick: () => void
  status?: 'success' | 'error' | 'warning' | 'info'
  title?: string
  variant?: 'subtle' | 'solid' | 'left-accent' | 'top-accent'
}

const Banner = ({
  className,
  description,
  isVisible,
  onClick,
  status = 'warning',
  title,
  variant = 'subtle',
}: BannerProps) => {
  const { isDarkMode } = useSettingsContext()

  return (
    <Collapse in={isVisible} animateOpacity>
      <Box className={className}>
        <Alert className={`text-gray-800 relative ${isDarkMode && 'bg-orange-100'}`} status={status} variant={variant}>
          <Box className="text-xs">
            {title && (
              <AlertTitle className="flex justify-between mb-1 mr-0">
                <Text>{title}</Text>
                <IconButton
                  sx={{
                    p: '0', // Set the desired padding value in the y-direction
                  }}
                  size="xs"
                  onClick={onClick}
                  aria-label="close-warning-banner"
                  variant="ghost"
                  iconName={BiX}
                  iconClassName="text-lg md:text-xl text-gray-800"
                />
              </AlertTitle>
            )}
            <AlertDescription className="leading-4">{description}</AlertDescription>
          </Box>
        </Alert>
      </Box>
    </Collapse>
  )
}

export { Banner }
