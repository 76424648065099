import { ReactNode, useEffect, useMemo } from 'react'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion'
import { Box, Text } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'

import { useMessagesContext } from 'providers/MessageProvider'
import { useScrollContext } from 'providers/ScrollProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import { useI18Context } from '../../providers/i18Provider'
import { useSettingsContext } from '../../providers/SettingsProvider'
import { BarChartData, LineChartData, PieChartData, TableData } from '../../types/types'
import { Table } from '../Table'

import { BarChart, LineChart, PieChart } from './Chart'

const ChartWrapper = ({
  hasOtherCharts,
  isChatFullScreen,
  children,
}: {
  hasOtherCharts: boolean
  isChatFullScreen: boolean
  children: ReactNode
}) => {
  return (
    <Box className="flex text-black">
      <Box className="w-full">
        <Box
          className={`bg-kpmgGray45 items-center justify-between ${
            isChatFullScreen ? 'rounded-b-none' : 'rounded-b-2xl'
          } ${hasOtherCharts && 'rounded-b-none border-b border-b-kpmgDarkBlue'}`}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

type ChartTypeProps =
  | {
      chartType: 'line'
      chartData: LineChartData | undefined
    }
  | {
      chartType: 'bar'
      chartData: BarChartData | undefined
    }
  | {
      chartType: 'pie'
      chartData: PieChartData | undefined
    }
  | {
      chartType: 'table'
      chartData: TableData | undefined
    }

type ChartProps = {
  botName: string
  chartName: string
  messageIndex: number
  chartIcon: JSX.Element
  isFetchingChart: boolean
  hasAPIError: boolean
  hasGenerateError: boolean
  hasTimeoutError: boolean
  hasOtherCharts: boolean
  isOpen: boolean
} & ChartTypeProps

export const Chart = ({
  botName,
  chartName,
  messageIndex,
  chartIcon,
  chartData,
  chartType,
  isFetchingChart,
  hasAPIError,
  hasGenerateError,
  hasTimeoutError,
  hasOtherCharts,
  isOpen,
}: ChartProps) => {
  const { t } = useI18Context()
  const { isChatFullScreen, isLightMode } = useSettingsContext()
  const { isTablet } = useThemeContext()
  const { getCurrentConversationIDForBot } = useMessagesContext()
  const { stateExpanded, updateStateExpanded } = useScrollContext()

  const currentConversationID = useMemo(() => {
    return getCurrentConversationIDForBot(botName)
  }, [botName, getCurrentConversationIDForBot])

  useEffect(() => {
    if (isFetchingChart && currentConversationID) {
      updateStateExpanded(currentConversationID, `${chartType}${messageIndex.toString()}`, false, null)
      updateStateExpanded(currentConversationID, `${chartType}${messageIndex.toString()}__spinner`, false, null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName, chartType, currentConversationID, isFetchingChart, messageIndex])

  useEffect(() => {
    if (isFetchingChart) {
      if (
        currentConversationID &&
        stateExpanded['activeStates'][currentConversationID]?.[`${chartType}${messageIndex.toString()}__spinner`] ===
          false
      ) {
        const currentMessageId = `${chartType}${messageIndex.toString()}__spinner`
        updateStateExpanded(currentConversationID, currentMessageId, true, currentMessageId)
      }
    }
  }, [chartType, currentConversationID, isFetchingChart, messageIndex, stateExpanded, updateStateExpanded])

  if (isFetchingChart) {
    return (
      <ChartWrapper hasOtherCharts={hasOtherCharts} isChatFullScreen={isChatFullScreen}>
        <Box className={`flex items-center p-2 w-full ${!isChatFullScreen && 'px-3 lg:px-4'}`}>
          <Box className={`flex justify-center ${isChatFullScreen && 'w-[34px] md:w-[50px] mr-2'}`}>{chartIcon}</Box>
          <Box className="flex items-center justify-between flex-grow">
            <Text className="text-xs md:text-sm">{t('generic.fetching') + ' ' + chartName + '...'}</Text>
            <Spinner
              className={`${isTablet ? 'mr-0.5' : 'mr-1'}`}
              size={isTablet ? 'sm' : 'xs'}
              id={`${chartType}${messageIndex.toString()}__spinner`}
            />
          </Box>
        </Box>
      </ChartWrapper>
    )
  } else if (hasAPIError || hasTimeoutError || hasGenerateError) {
    return (
      <ChartWrapper hasOtherCharts={hasOtherCharts} isChatFullScreen={isChatFullScreen}>
        <Box className={`flex items-center p-2 ${!isChatFullScreen && 'px-3 lg:px-4'}`}>
          <Box className={`flex justify-center ${isChatFullScreen && 'w-[34px] md:w-[50px] mr-2'}`}>{chartIcon}</Box>
          <Text className={`text-xs md:text-sm ${hasGenerateError ? 'text-kpmgPurple' : 'text-red-800'}`}>
            {hasAPIError
              ? t('generic.errorFetching') + ' ' + chartName
              : hasTimeoutError
                ? t('generic.504Error')
                : hasGenerateError &&
                  t('chart.generateError1') + ' ' + chartName.toLowerCase() + '. ' + t('chart.generateError2') + '.'}
          </Text>
        </Box>
      </ChartWrapper>
    )
  }

  if (!chartData) return null

  return (
    <ChartWrapper hasOtherCharts={hasOtherCharts} isChatFullScreen={isChatFullScreen}>
      <Accordion id={chartType} defaultIndex={isOpen ? [0] : undefined} allowToggle>
        {/* className used as id to be identified in ChatBot component for purposes of react scroll library, in order to know to which accordian item to scroll to exactly */}
        <AccordionItem
          className={`${chartType}${messageIndex.toString()} rounded-b-2xl border-kpmgGray45 ${
            isLightMode ? 'focus:ring-light focus:shadow-none' : 'focus:ring-dark focus:shadow-none'
          }`}
          tabIndex={0}
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton
                className={`shadow-none border-kpmgGray45 p-2 ${!isChatFullScreen && 'px-3 lg:px-4'} ${
                  isChatFullScreen || isExpanded || hasOtherCharts ? 'rounded-b-none' : 'rounded-b-2xl'
                } ${isLightMode ? 'focus:ring-light' : 'focus:ring-dark'} flex justify-between`}
                tabIndex={0}
              >
                <Box className="flex items-center">
                  {/* For width definitions below, we need to match what the K and Feedback buttons have: w-[34px] is w-8 + 2px worth of border. w-[50px] is w-12 + 2px worth of border */}
                  <Box className={`flex justify-center ${isChatFullScreen && 'w-[34px] md:w-[50px] mr-2'}`}>
                    {chartIcon}
                  </Box>
                  <Text className="text-xs md:text-sm">{chartName}</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel
                className={`shadow-none pt-0 ${isLightMode ? 'focus:ring-light' : 'focus:ring-dark '}`}
                motionProps={{
                  onAnimationStart() {
                    // set this accordion as the active one to scroll to if the state has changed and we are expanding.
                    // set to null if we're collapsing (we only want to set an accordion as active if we've clicked it)
                    if (
                      currentConversationID &&
                      stateExpanded['activeStates'][currentConversationID]?.[
                        `${chartType}${messageIndex.toString()}`
                      ] !== isExpanded
                    ) {
                      const currentMessageId = `${chartType}${messageIndex.toString()}`
                      updateStateExpanded(
                        currentConversationID,
                        currentMessageId,
                        isExpanded,
                        isExpanded ? currentMessageId : null
                      )
                    }
                  },
                }}
                tabIndex={isExpanded ? 0 : -1}
              >
                {chartType === 'line' && <LineChart data={chartData} />}
                {chartType === 'bar' && <BarChart data={chartData} />}
                {chartType === 'pie' && <PieChart data={chartData} />}
                {chartType === 'table' && <Table data={chartData} />}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </ChartWrapper>
  )
}
