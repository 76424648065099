import { useCallback, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { BiCog, BiExitFullscreen, BiFullscreen, BiMenu, BiSolidDownload, BiX } from 'react-icons/bi'
import { IconType } from 'react-icons/lib'
import { IconButton as ChakraIconButton } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Text } from '@chakra-ui/layout'
import { Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/menu'

import { useBotSpecificContext } from 'providers/BotSpecificProvider'
import { useConversationSettingsContext } from 'providers/ConversationSettingsProvider'
import { useMessagesContext } from 'providers/MessageProvider'
import { usePDFContext } from 'providers/PDFProvider'

import type { DisclaimerProperty } from 'types/types'

import { useEventLogger } from '../hooks/useEventLogger'
import { useChartContext } from '../providers/ChartProvider'
import { useI18Context } from '../providers/i18Provider'
import { useSettingsContext } from '../providers/SettingsProvider'
import { useThemeContext } from '../providers/ThemeProvider'
import { formatLastUpdatedDate } from '../utils/formatter'

import { IconButton } from './buttons/IconButton'
import { TooltipButton } from './buttons/TooltipButton'
import { ClearMessagesBody, ClearMessagesFooter } from './ClearMessagesForm'
import { ConversationSettingsModal } from './ConversationSettings'
import { ModalBox } from './Modal'

type DrawerProps = {
  drawer: JSX.Element
  button: {
    icon: IconType
    onClick: () => void
    hideTooltipText: string
    showTooltipText: string
    text: string
  }
  isOpen: boolean
}

type BotToolbarProps = {
  botName: string
  chatSummaryMemory?: number
  disclaimer: DisclaimerProperty[]
  lastUpdated?: string
  setChatSummary?: (botName: string, summary: string | null, chatSummaryMemory: number) => void
  showSettings: boolean
  rightDrawer?: DrawerProps
  leftDrawer?: DrawerProps
}

export const BotToolbar = (props: BotToolbarProps) => {
  const { botName, chatSummaryMemory, disclaimer, lastUpdated, leftDrawer, rightDrawer, setChatSummary, showSettings } =
    props

  const { setValue } = useFormContext()
  const { isOpen: isClearMessagesOpen, onOpen: onClearMessagesOpen, onClose: onClearMessagesClose } = useDisclosure()
  const { isOpen: isSettingsOpen, onOpen: onSettingsOpen, onClose: onSettingsClose } = useDisclosure()
  const { isTablet } = useThemeContext()
  const { isChatFullScreen, setChatFullScreen } = useSettingsContext()
  const { t, language } = useI18Context()
  const { clearBotAllChartData } = useChartContext()
  const { messages, isStreaming, isFetchingChart } = useBotSpecificContext()
  const { clearAllMessagesInConversation, getCurrentConversationIDForBot, getMessages } = useMessagesContext()
  const { updateSettingsLanguage } = useConversationSettingsContext()
  const { logUIErrorEvent } = useEventLogger()
  const { exportConversationPdf } = usePDFContext()

  const exportOnClick = useCallback(() => {
    exportConversationPdf(botName, getMessages(botName), disclaimer)
  }, [botName, disclaimer, exportConversationPdf, getMessages])

  const lastUpdatedDate = useMemo(() => {
    // Months are zero-based, so 8 represents September
    if (lastUpdated) {
      try {
        return formatLastUpdatedDate(lastUpdated, language)
      } catch (e) {
        logUIErrorEvent({
          bot: botName,
          error: e as Error,
          errorMessage: 'last-updated-date-generate-error',
        })
      }
    }
  }, [botName, language, lastUpdated, logUIErrorEvent])

  const handleClearDocument = useCallback(() => {
    setValue('document', null, { shouldValidate: false })
  }, [setValue])

  const handleClearMessages = useCallback(() => {
    clearBotAllChartData(botName)
    clearAllMessagesInConversation(botName)

    handleClearDocument()
    // If it is a bot that keeps track of the chat summary, clear it
    setChatSummary && setChatSummary(botName, null, chatSummaryMemory ?? 10)
  }, [
    botName,
    chatSummaryMemory,
    clearBotAllChartData,
    clearAllMessagesInConversation,
    handleClearDocument,
    setChatSummary,
  ])

  const handleToggleFullscreen = () => {
    setChatFullScreen(!isChatFullScreen)
  }

  useEffect(() => {
    if (showSettings) {
      updateSettingsLanguage(botName, getCurrentConversationIDForBot(botName) || 0)
    }
    // Need the below disable because we only want to run this useEffect when the language of the app changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <Box>
      <ModalBox
        isOpen={isClearMessagesOpen}
        modalBody={<ClearMessagesBody />}
        modalFooter={<ClearMessagesFooter onClose={onClearMessagesClose} primaryButtonOnClick={handleClearMessages} />}
        modalHeader={t('generic.clearMessages')}
        onClose={onClearMessagesClose}
      />
      <ConversationSettingsModal botName={botName} isOpen={isSettingsOpen} onClose={onSettingsClose} />
      {isTablet ? (
        <Box className="flex items-center">
          {/* Render below all of the buttons that appear in the top-right of the Chat Bot */}
          <TooltipButton
            button={
              <IconButton
                aria-label="clearMessages"
                onClick={() => onClearMessagesOpen()}
                iconName={BiX}
                isDisabled={isStreaming || isFetchingChart || (messages && messages.length < 1) || !messages}
                test-id="clear-messages-button"
              />
            }
            label={t('generic.clearMessages')}
          />
          <TooltipButton
            button={
              <IconButton
                aria-label={isChatFullScreen ? 'chatExitFullScreen' : 'chatFullScreen'}
                onClick={() => setChatFullScreen((currentOpenStatus) => !currentOpenStatus)}
                iconName={isChatFullScreen ? BiExitFullscreen : BiFullscreen}
                test-id="toggle-fullscreen-button"
              />
            }
            label={isChatFullScreen ? t('controls.chatExitFullScreen') : t('controls.chatFullScreen')}
          />
          <TooltipButton
            button={
              <IconButton
                aria-label="exportConversation"
                onClick={exportOnClick}
                iconName={BiSolidDownload}
                isDisabled={!getMessages(botName).length || isStreaming}
                test-id="export-conversation-button"
              />
            }
            label={t('generic.exportConversationToolTip')}
          />
          {showSettings && (
            <TooltipButton
              button={
                <IconButton
                  aria-label="settings"
                  onClick={() => onSettingsOpen()}
                  iconName={BiCog}
                  isDisabled={isStreaming || isFetchingChart}
                  test-id="conversation-settings-button"
                />
              }
              label={t('settings.chatSettings')}
            />
          )}
          {(leftDrawer || rightDrawer) && <Box className="ml-5 border-l border-kpmgGray4">&nbsp;</Box>}
          {leftDrawer && (
            <TooltipButton
              button={
                <IconButton
                  aria-label={leftDrawer.isOpen ? 'hideLeftDrawer' : 'showLeftDrawer'}
                  onClick={leftDrawer.button.onClick}
                  iconName={leftDrawer.button.icon}
                  test-id="left-drawer-button"
                />
              }
              label={leftDrawer.isOpen ? leftDrawer.button.hideTooltipText : leftDrawer.button.showTooltipText}
            />
          )}
          {rightDrawer && (
            <TooltipButton
              button={
                <IconButton
                  aria-label={rightDrawer.isOpen ? 'hideRightDrawer' : 'showRightDrawer'}
                  onClick={rightDrawer.button.onClick}
                  iconName={rightDrawer.button.icon}
                  test-id="right-drawer-button"
                />
              }
              label={rightDrawer.isOpen ? rightDrawer.button.hideTooltipText : rightDrawer.button.showTooltipText}
            />
          )}
        </Box>
      ) : (
        <Menu>
          <MenuButton size="sm" as={ChakraIconButton} aria-label="Options" icon={<BiMenu />} />
          <MenuList className="max-w-[90vw]" zIndex="dropdown">
            <MenuItem
              icon={<BiX className="text-xl md:text-2xl" />}
              isDisabled={isStreaming || isFetchingChart || (messages && messages.length < 1) || !messages}
              onClick={() => onClearMessagesOpen()}
              tabIndex={0}
            >
              {t('controls.clear')}
            </MenuItem>
            <MenuItem
              icon={
                isChatFullScreen ? (
                  <BiExitFullscreen className="text-lg md:text-2xl" />
                ) : (
                  <BiFullscreen className="text-lg md:text-2xl" />
                )
              }
              onClick={handleToggleFullscreen}
              tabIndex={0}
            >
              {isChatFullScreen ? t('controls.chatExitFullScreen') : t('controls.chatFullScreen')}
            </MenuItem>
            <MenuItem
              icon={<BiSolidDownload className="text-lg md:text-2xl" />}
              isDisabled={!getMessages(botName).length || isStreaming}
              onClick={exportOnClick}
              tabIndex={0}
            >
              {t('controls.export')}
            </MenuItem>
            {showSettings && (
              <MenuItem
                icon={<BiCog className="text-lg md:text-2xl" />}
                isDisabled={isStreaming || isFetchingChart}
                onClick={() => onSettingsOpen()}
                tabIndex={0}
              >
                {t('settings.chatSettings')}
              </MenuItem>
            )}
            {(leftDrawer || rightDrawer) && <MenuDivider />}
            {leftDrawer && (
              <MenuItem
                icon={<leftDrawer.button.icon className="text-lg md:text-2xl" />}
                onClick={leftDrawer.button.onClick}
                tabIndex={0}
              >
                {leftDrawer.button.text}
              </MenuItem>
            )}
            {rightDrawer && (
              <MenuItem
                icon={<rightDrawer.button.icon className="text-lg md:text-2xl" />}
                onClick={rightDrawer.button.onClick}
                tabIndex={0}
              >
                {rightDrawer.button.text}
              </MenuItem>
            )}
            {lastUpdatedDate && (
              <>
                <MenuDivider />
                <Text className="px-4 py-1 text-xs">
                  {t('generic.lastUpdated')} {lastUpdatedDate}
                </Text>
              </>
            )}
          </MenuList>
        </Menu>
      )}
    </Box>
  )
}
