import { useCallback, useState } from 'react'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { Box, Text } from '@chakra-ui/layout'
import { API } from '@kleo/types'

import { useMatchedElements } from 'hooks/useMatchedElements'

import { useEventLogger } from '../hooks/useEventLogger'
import { useI18Context } from '../providers/i18Provider'
import { useSettingsContext } from '../providers/SettingsProvider'
import { useThemeContext } from '../providers/ThemeProvider'
import { ChatMessage } from '../types/types'

import { Button } from './buttons/Button'
import { IconButton } from './buttons/IconButton'
import { Link } from './Link'
import { ModalBox } from './Modal'

type FeedbackModalProps = {
  isOpen: boolean
  onClose: () => void
  feedbackSubmit: (feedbackResponse: API.ChatMessageFeedback) => void
  botName: string
  botType: string
  feedbackMessage: { message: ChatMessage; index: number } | null
}

export const FeedbackModal = ({
  isOpen,
  onClose,
  feedbackMessage,
  botName,
  botType,
  feedbackSubmit,
}: FeedbackModalProps) => {
  const { t } = useI18Context()
  const { isLightMode } = useSettingsContext()
  const { isTablet } = useThemeContext()
  const { logUIFeedbackEvent } = useEventLogger()
  const { matchedElements } = useMatchedElements({
    botType,
    content: feedbackMessage?.message?.content || '',
    docContext: feedbackMessage?.message?.docContext,
  })

  const [feedback, setFeedback] = useState<null | API.ChatMessageFeedback>(null)
  const [status, setStatus] = useState<0 | 1 | 2>(0) // 0 = selecting, 1 = submitting, 2 = submitted

  const handleSubmitFeedback = useCallback(() => {
    if (feedback && feedbackMessage?.message) {
      setStatus(1)
      logUIFeedbackEvent({ bot: botName, documents: matchedElements, feedback }).then(() => {
        setTimeout(() => {
          setStatus(2)
          setTimeout(() => {
            feedbackSubmit(feedback)
            setFeedback(null)
            setStatus(0)
          }, 500)
        }, 300)
      })
    }
  }, [botName, feedback, feedbackMessage?.message, feedbackSubmit, logUIFeedbackEvent, matchedElements])

  const handleCloseFeedback = () => {
    setFeedback(null)
    onClose()
  }

  return (
    <ModalBox
      isOpen={isOpen}
      modalBody={
        <Box>
          <Text className="mb-6">{t('generic.feedbackDescription')}</Text>
          <Box className="text-center">
            <Box className="flex justify-center gap-6 mb-2 md:mb-4">
              <IconButton
                aria-label="good-feedback"
                className={`rounded-full h-16 w-16 md:h-20 md:w-20 ${
                  isLightMode ? 'border-2 border-kpmgPurple' : 'bg-kpmgPurple hover:bg-kpmgPurpleHover'
                } ${feedback !== 'good' && 'opacity-50 hover:opacity-70'} ${
                  feedback === 'good' && !isLightMode && 'border-2 border-kpmgLightPurple'
                }`}
                onClick={() => {
                  // Only when in the "selecting" state will we allow the feedback reason to change
                  status === 0 && setFeedback('good')
                }}
                iconName={BiSolidLike}
                iconSize={isTablet ? 30 : 26}
              />
              <IconButton
                aria-label="bad-feedback"
                className={`rounded-full h-16 w-16 md:h-20 md:w-20 ${
                  isLightMode ? 'border-2 border-kpmgCobaltBlue' : 'bg-kpmgCobaltBlue hover:bg-kpmgCobaltBlueHover'
                } ${feedback !== 'bad' && 'opacity-50 hover:opacity-70'} ${
                  feedback === 'bad' && !isLightMode && 'border-2 border-kpmgLightBlue'
                }`}
                onClick={() => {
                  // Only when in the "selecting" state will we allow the feedback reason to change
                  status === 0 && setFeedback('bad')
                }}
                iconName={BiSolidDislike}
                iconSize={isTablet ? 30 : 26}
              />
            </Box>
            <Box className="text-center">
              {feedback && (
                <Text as="p" tabIndex={0}>
                  {t(`feedbackModal.${feedback}Response`)}
                </Text>
              )}
            </Box>
            {feedback === 'bad' && (
              <Box className="mt-4 text-xs text-left">
                <Link
                  defaultStyle
                  external={true}
                  name={t('feedbackModal.supportLink')}
                  url="https://cakpmgprod.service-now.com/its?id=sc_cat_item&sys_id=3f1dd0320a0a0b99000a53f7604a2ef9"
                />
              </Box>
            )}
          </Box>
        </Box>
      }
      modalFooter={
        <Box className="flex flex-col gap-3 md:flex-row">
          <Button
            className="ml-auto text-sm md:text-base max-w-min"
            aria-label="cancel"
            id="cancel-button"
            isDisabled={status > 0}
            onClick={() => handleCloseFeedback()}
          >
            {t('controls.cancel')}
          </Button>
          <Button
            aria-label="submit"
            className={`whitespace-normal text-sm md:text-base ${
              isLightMode && status < 2
                ? 'clear-messages-modal-button text-white bg-kpmgCobaltBlue hover:bg-kpmgCobaltBlueHover'
                : 'text-black bg-white hover:bg-whiteHover'
            }`}
            id="submit-button"
            isDisabled={feedback === null || status > 0}
            isLoading={status === 1}
            onClick={handleSubmitFeedback}
          >
            {t(`feedbackModal.${status < 2 ? 'submit' : 'feedbackSubmitted'}`)}
          </Button>
        </Box>
      }
      modalHeader={t('generic.feedbackTooltip')}
      onClose={() => handleCloseFeedback()}
    />
  )
}
