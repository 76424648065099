import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { BiBarChart, BiLineChart, BiPieChartAlt, BiTable } from 'react-icons/bi'
import { Image } from '@chakra-ui/image'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu'
import { Box } from '@chakra-ui/react'

import { useBotContext } from 'providers/BotProvider'
import { useBotSpecificContext } from 'providers/BotSpecificProvider'
import { useChartContext } from 'providers/ChartProvider'
import { useI18Context } from 'providers/i18Provider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useSettingsContext } from 'providers/SettingsProvider'

import { ChartType, ChartTypeProperties, ChatMessage, GraphOutputOption } from 'types/types'

type BotMenuProps = {
  botName: string
  clipboardText: string
  hasBarChart: boolean
  hasLineChart: boolean
  hasPieChart: boolean
  hasTable: boolean
  index: number
  messageProperties?: {
    line: ChartTypeProperties
    bar: ChartTypeProperties
    pie: ChartTypeProperties
    table: ChartTypeProperties
  }
  outputFormatOptions?: GraphOutputOption[]
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const BotMenu = (props: BotMenuProps) => {
  const {
    botName,
    clipboardText,
    hasBarChart,
    hasLineChart,
    hasPieChart,
    hasTable,
    index: messageIndex,
    messageProperties,
    setIsOpen,
  } = props

  const { chartRequest } = useChartContext()
  const { isLightMode, isChatFullScreen } = useSettingsContext()
  const { addMessage, getCurrentConversationIDForBot, setConversations } = useMessagesContext()
  const { isFetchingChart, isStreaming } = useBotSpecificContext()
  const { setIsFetchingChart } = useBotContext()
  const { t } = useI18Context()

  const currentConversationID = useMemo(() => {
    return getCurrentConversationIDForBot(botName)
  }, [botName, getCurrentConversationIDForBot])

  const handleChartRequest = useCallback(
    async ({ chartType }: { chartType: ChartType }) => {
      // updateMessageProperties contains logic for updating boolean properties in our message data
      const updateMessageProperties = (chartType: 'LINE' | 'PIE' | 'BAR' | 'TABLE', propertyKey: string) => {
        // Convert the input to it's lowercase version for using to query the messageData property
        // It's not possible to just use .toLowerCase because this converts the type to a string where "updatedProperties" throws an error
        let updateChartType: 'line' | 'pie' | 'bar' | 'table' = 'bar'
        if (chartType === 'LINE') {
          updateChartType = 'line'
        } else if (chartType === 'PIE') {
          updateChartType = 'pie'
        } else if (chartType === 'TABLE') {
          updateChartType = 'table'
        }

        setConversations((conversations) => {
          if (botName in conversations && currentConversationID && currentConversationID in conversations[botName]) {
            const updatedBotMessages = {
              ...conversations,
              [botName]: {
                ...conversations[botName],
                [currentConversationID]: {
                  ...conversations[botName][currentConversationID],
                  messages: conversations[botName][currentConversationID].messages.map((message, index) => {
                    if (index === messageIndex && message.messageProperties) {
                      const updatedProperties = {
                        ...message.messageProperties,
                      }

                      updatedProperties[updateChartType] = {
                        ...updatedProperties[updateChartType],
                        [propertyKey]: true,
                      }

                      return {
                        ...message,
                        messageProperties: updatedProperties,
                      }
                    }

                    return message
                  }),
                },
              },
            }

            return updatedBotMessages
          }
          return conversations
        })
      }

      // This setter is responsible for updating the isFetching property depending on the chartType and message it belongs to
      updateMessageProperties(chartType, 'isFetching')

      setIsFetchingChart((fetching) => ({
        ...fetching,
        [botName]: true,
      }))
      const newInput: ChatMessage = {
        content: clipboardText,
        role: 'user',
      }
      const chartMessage = await chartRequest({
        message: newInput,
        chartType,
        botName,
        messageIndex,
      })

      if (chartMessage === 'api' || chartMessage === 'generate' || chartMessage === 'timeout') {
        let propertyKey = ''
        if (chartMessage === 'api') {
          propertyKey = 'isAPIError'
        } else if (chartMessage === 'generate') {
          propertyKey = 'isGenerateError'
        } else if (chartMessage === 'timeout') {
          propertyKey = 'isTimeoutError'
        }

        updateMessageProperties(chartType, propertyKey)
      } else if (chartMessage) {
        addMessage(botName, chartMessage)
        setIsOpen(true)
      }

      // This setter is responsible for updating the isFetching property depending on the chartType and message it belongs to
      setConversations((conversations) => {
        const conversationID = getCurrentConversationIDForBot(botName)
        if (conversationID && conversations[botName]?.[conversationID]) {
          const updatedBotMessages = {
            ...conversations,
            [botName]: {
              ...conversations[botName],
              [conversationID]: {
                ...conversations[botName][conversationID],
                messages: conversations[botName][conversationID].messages.map((message, index) => {
                  if (index === messageIndex && message.messageProperties) {
                    const updatedProperties = {
                      ...message.messageProperties,
                    }

                    if (chartType === 'LINE') {
                      updatedProperties.line = {
                        ...updatedProperties.line,
                        isFetching: false,
                      }
                    } else if (chartType === 'PIE') {
                      updatedProperties.pie = {
                        ...updatedProperties.pie,
                        isFetching: false,
                      }
                    } else if (chartType === 'BAR') {
                      updatedProperties.bar = {
                        ...updatedProperties.bar,
                        isFetching: false,
                      }
                    } else if (chartType === 'TABLE') {
                      updatedProperties.table = {
                        ...updatedProperties.table,
                        isFetching: false,
                      }
                    }

                    return {
                      ...message,
                      messageProperties: updatedProperties,
                    }
                  }

                  return message
                }),
              },
            },
          }

          return updatedBotMessages
        }
        return conversations
      })

      setIsFetchingChart((fetching) => ({
        ...fetching,
        [botName]: false,
      }))
    },
    [
      addMessage,
      botName,
      chartRequest,
      clipboardText,
      currentConversationID,
      getCurrentConversationIDForBot,
      messageIndex,
      setConversations,
      setIsFetchingChart,
      setIsOpen,
    ]
  )

  // Handle logic on Enter key press
  const handleFormatOutputPress = useCallback(
    (chartType: ChartType) => {
      if (chartType === 'LINE') {
        if (
          !!messageProperties?.line.isFetching ||
          !!messageProperties?.line.isAPIError ||
          !!messageProperties?.line.isGenerateError
        )
          return
        handleChartRequest({
          chartType,
        })
      } else if (chartType === 'BAR') {
        if (
          !!messageProperties?.bar.isFetching ||
          !!messageProperties?.bar.isAPIError ||
          !!messageProperties?.bar.isGenerateError
        )
          return
        handleChartRequest({
          chartType,
        })
      } else if (chartType === 'PIE') {
        if (
          !!messageProperties?.pie.isFetching ||
          !!messageProperties?.pie.isAPIError ||
          !!messageProperties?.pie.isGenerateError
        )
          return
        handleChartRequest({
          chartType,
        })
      } else if (chartType === 'TABLE') {
        if (
          !!messageProperties?.table.isFetching ||
          !!messageProperties?.table.isAPIError ||
          !!messageProperties?.table.isGenerateError
        )
          return
        handleChartRequest({
          chartType,
        })
      }
    },
    [
      messageProperties?.line.isFetching,
      messageProperties?.line.isAPIError,
      messageProperties?.line.isGenerateError,
      messageProperties?.bar.isFetching,
      messageProperties?.bar.isAPIError,
      messageProperties?.bar.isGenerateError,
      messageProperties?.pie.isFetching,
      messageProperties?.pie.isAPIError,
      messageProperties?.pie.isGenerateError,
      messageProperties?.table.isFetching,
      messageProperties?.table.isAPIError,
      messageProperties?.table.isGenerateError,
      handleChartRequest,
    ]
  )

  const isMenuItemDisabled = (type: ChartType): boolean => {
    if (type === 'BAR') {
      return hasBarChart
    } else if (type === 'LINE') {
      return hasLineChart
    } else if (type === 'PIE') {
      return hasPieChart
    } else if (type === 'TABLE') {
      return hasTable
    }
    // Default return, however the type always needs to equal one of the above values
    return false
  }

  const outputFormatOptions: GraphOutputOption[] = useMemo(() => {
    return [
      { icon: BiLineChart, name: t('chart.line'), type: 'LINE' },
      { icon: BiBarChart, name: t('chart.bar'), type: 'BAR' },
      { icon: BiPieChartAlt, name: t('chart.pie'), type: 'PIE' },
      { icon: BiTable, name: t('chart.table'), type: 'TABLE' },
    ]
  }, [t])

  return (
    <Menu placement="top-start">
      {/* If the padding value ever changes for this MenuButton, need to adjust the widths defined in ChartAccordion and UserMessage */}
      <MenuButton
        aria-label="menu"
        className={`p-2 rounded-full bg-kpmgGray5 ${isLightMode ? 'focus:ring-light' : 'focus:ring-dark'} ${
          !isFetchingChart && 'hover:bg-kpmgGray45'
        } ${isChatFullScreen && 'border border-kpmgGray4 border-opacity-50'}`}
        tabIndex={0}
      >
        <AvatarImage />
      </MenuButton>
      <MenuList
        className={`text-sm md:text-base max-w-[90vw] ${
          isLightMode ? 'bg-white' : 'background-secondary bg-kpmgDarkBlue'
        }`}
      >
        {outputFormatOptions?.map((outputFormatOption, index) => (
          <MenuItem
            key={outputFormatOption.name}
            onClick={() => handleFormatOutputPress(outputFormatOption.type)}
            icon={<outputFormatOption.icon className="text-lg md:text-2xl" />}
            className={`${
              isLightMode
                ? 'bg-white hover:bg-whiteHover focus:bg-whiteHover'
                : 'background-menu-item bg-kpmgDarkBlue hover:bg-kpmgCobaltBlue focus:bg-kpmgCobaltBlue'
            }`}
            tabIndex={index}
            isDisabled={isStreaming || isFetchingChart || isMenuItemDisabled(outputFormatOption.type)}
          >
            {outputFormatOption.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

const AvatarImage = () => <Image alt="k-logo" className="h-4 md:h-8" src="../images/k-logo.png" />

export const LogoOnlyAvatar = () => {
  const { isChatFullScreen, isLightMode } = useSettingsContext()
  return (
    <Box
      className={`p-2 rounded-full bg-kpmgGray5 ${isLightMode ? 'focus:ring-light' : 'focus:ring-dark'} ${
        isChatFullScreen && 'border border-kpmgGray4 border-opacity-50'
      }`}
    >
      <AvatarImage />
    </Box>
  )
}
