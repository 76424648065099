import { useEffect, useRef } from 'react'

const useIsMounted = () => {
  const isMounted = useRef(false)

  // Update isMounted so that specific useEffects can run
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    }
  }, [])

  return { isMounted: isMounted.current }
}

export { useIsMounted }
