import { useEffect } from 'react'
import { InteractionStatus } from '@azure/msal-browser'
import { Box } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'

import { useAuthContext } from 'providers/AuthProvider'
import { useThemeContext } from 'providers/ThemeProvider'

export const GeneralSpinner = ({ fullHeight = true }: { fullHeight?: boolean }) => {
  return (
    <Box className={`flex items-center justify-center text-2xl ${fullHeight ? 'flex-grow my-20' : 'my-4'}`}>
      <Spinner size="lg" />
    </Box>
  )
}

export const LoadingInBotsSpinner = () => {
  const { isAuthenticated, msalInstance, inProgress } = useAuthContext()
  const { isTablet } = useThemeContext()

  useEffect(() => {
    // If the user hasn't been authenticated and no interaction with msal-react is currently in progress, log them out
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      msalInstance.logout()
    }
  }, [isAuthenticated, msalInstance, inProgress])

  return (
    <Box className="flex flex-col items-center justify-center flex-grow my-20 text-2xl">
      <Spinner className="mt-4" size={isTablet ? 'lg' : 'md'} />
    </Box>
  )
}
