import { Badge, Stack, Text, Tooltip } from '@chakra-ui/react'

import { useI18Context } from 'providers/i18Provider'
import { useThemeContext } from 'providers/ThemeProvider'

import { ConversationSpecificSettings } from 'types/types'

type TemperatureVoiceControlProps = {
  conversationSettings: ConversationSpecificSettings
}

export const TemperatureVoiceControl = (props: TemperatureVoiceControlProps) => {
  const { t } = useI18Context()
  const { isTablet } = useThemeContext()

  const { conversationSettings } = props

  const {
    input: { voice, temperature },
  } = conversationSettings

  return (
    <>
      <Stack direction="row">
        <Tooltip
          className="text-xs text-black bg-kpmgGray5"
          label={t('settings.voice')}
          placement="top"
          openDelay={200}
          isDisabled={!isTablet}
        >
          <Badge variant="solid">
            <Text className="text-xs md:text-sm">{t(`settings.voices.${voice.value}`)}</Text>
          </Badge>
        </Tooltip>
        <Tooltip
          className="text-xs text-black bg-kpmgGray5"
          label={t('settings.temperature')}
          placement="top"
          openDelay={200}
          isDisabled={!isTablet}
        >
          <Badge variant="solid" colorScheme="blue">
            <Text className="text-xs md:text-sm">{t(`settings.temperatures.${temperature.value}`)}</Text>
          </Badge>
        </Tooltip>
      </Stack>
    </>
  )
}
