import { Fragment, useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useDisclosure } from '@chakra-ui/hooks'
import { Box } from '@chakra-ui/layout'
import { API } from '@kleo/types'

import { useScroller } from 'hooks/useScroller'

import { useBotSpecificContext } from 'providers/BotSpecificProvider'
import { useI18Context } from 'providers/i18Provider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useSettingsContext } from 'providers/SettingsProvider'

import { BotFilterValues, BotFormValues, ChatMessage, DisclaimerProperty, SubheaderProperty } from '../types/types'

import { Message, StreamingMessage } from './botMessage/Message'
import { FeedbackModal } from './FeedbackModal'
import { HeaderText, SubheaderText } from './Header'
import { OpenFeedbackButton } from './OpenFeedbackButton'
import PromptLibrary from './PromptLibrary'
import { UserMessage } from './UserMessage'

type ChatBotProps = {
  botName: string
  botType: string
  chatSummaryMemory: number
  disclaimer: DisclaimerProperty[]
  forceMarkdown?: boolean
  subheader: SubheaderProperty[][]
  submit: SubmitHandler<BotFormValues & BotFilterValues>
  includeStyles?: boolean
}

export const ChatMessages = (props: ChatBotProps) => {
  const {
    botName,
    botType,
    chatSummaryMemory,
    disclaimer,
    forceMarkdown,
    subheader,
    submit,
    includeStyles = true,
  } = props

  const { i18n } = useI18Context()
  const { isChatFullScreen } = useSettingsContext()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    addMessage,
    getCurrentConversationIDForBot,
    setConversations,
    setConversationSummary,
    setStreamingMessage,
    setStreamingSummary,
  } = useMessagesContext()
  const { isFetchingChart, isStreaming, messages, streamingMessage, streamingSummary, conversationSettings } =
    useBotSpecificContext()

  const { scrollRef } = useScroller(botName)

  const [currentFeedbackMessage, setCurrentFeedbackMessage] = useState<{ message: ChatMessage; index: number } | null>(
    null
  )

  const handleOpenFeedback = (message: ChatMessage, index: number) => {
    setCurrentFeedbackMessage({ message, index })
    return onOpen()
  }

  const feedbackSubmit = (feedbackResponse: API.ChatMessageFeedback) => {
    if (feedbackResponse && currentFeedbackMessage) {
      setConversations((conversations) => {
        const conversationID = getCurrentConversationIDForBot(botName)
        if (botName in conversations && conversationID && conversationID in conversations[botName]) {
          const newMessages = [...conversations[botName][conversationID].messages]
          const index = currentFeedbackMessage.index
          newMessages[index] = { ...newMessages[index], feedback: feedbackResponse }
          return {
            ...conversations,
            [botName]: {
              ...conversations[botName],
              [conversationID]: { ...conversations[botName][conversationID], messages: newMessages },
            },
          }
        }
        return conversations
      })
    }
    setCurrentFeedbackMessage(null)
    onClose()
  }

  //when streaming is done, move the streaming message to messages list
  useEffect(() => {
    if (!isStreaming) {
      if (streamingMessage) {
        addMessage(botName, { ...streamingMessage, tempVoiceControl: conversationSettings })
        setStreamingMessage((prev) => ({ ...prev, [botName]: null }))
      }
      if (streamingSummary) {
        setConversationSummary(botName, streamingSummary, chatSummaryMemory ?? 10)
        setStreamingSummary((prev) => ({ ...prev, [botName]: null }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName, chatSummaryMemory, isStreaming, streamingMessage, streamingSummary])

  return (
    <Box
      id="chat-message-box"
      ref={scrollRef}
      className={`overflow-y-auto h-full ${includeStyles && 'bg-gray-800 backdrop-blur bg-opacity-[.3] rounded-t-xl'}`}
    >
      {/* If no messages have been sent yet, display the "informational" placeholder */}
      {!messages || (messages && !messages.length) ? (
        <Box className="flex justify-center h-full">
          <Box className="py-6 my-auto text-white md:py-10 width-layout">
            <Box className="mb-4 md:mb-8">
              <HeaderText botName={botName} />
            </Box>
            <Box className="last:mb-0">
              <SubheaderText botName={botName} subheader={subheader} />
            </Box>
            {i18n.exists('promptLibrary', { ns: botName.toLocaleLowerCase() }) && (
              <>
                <Box className="h-[1px] w-[75%] md:w-[50%] mx-auto my-4 md:my-8 bg-white bg-opacity-75" />
                <PromptLibrary botName={botName} />
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box className={`flex flex-col ${isChatFullScreen ? 'p-0' : 'p-2 md:p-4'}`}>
          {messages && messages.length && (
            <FeedbackModal
              botName={botName}
              botType={botType}
              feedbackSubmit={feedbackSubmit}
              isOpen={isOpen}
              feedbackMessage={currentFeedbackMessage}
              onClose={onClose}
            />
          )}
          {messages &&
            messages.map((message: ChatMessage, messageIndex: number) =>
              message.role === 'user' ? (
                <Fragment key={`${message.role}_${messageIndex}`}>
                  <UserMessage content={message.content} />
                </Fragment>
              ) : message.role === 'assistant' ? (
                <Fragment key={`${message.role}_${messageIndex}`}>
                  <Message
                    botName={botName}
                    botType={botType}
                    disclaimer={disclaimer}
                    feedbackButton={
                      <OpenFeedbackButton
                        feedback={message.feedback}
                        isDisabled={isStreaming || isFetchingChart || !!message.feedback}
                        onClick={() => handleOpenFeedback(message, messageIndex)}
                      />
                    }
                    index={messageIndex}
                    message={message}
                    scrollRef={scrollRef}
                    submit={submit}
                    forceMarkdown={forceMarkdown}
                  />
                </Fragment>
              ) : (
                <Fragment key={messageIndex} />
              )
            )}
          {streamingMessage && (
            <StreamingMessage
              forceMarkdown={forceMarkdown}
              messageIndex={messages?.length}
              streamingMessage={streamingMessage}
            />
          )}
        </Box>
      )}
    </Box>
  )
}
