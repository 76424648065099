import { KeyboardEvent } from 'react'
import { IconType } from 'react-icons'
import { BiGlobe, BiHome, BiInfoCircle, BiLogOut, BiMenu, BiMoon, BiSun } from 'react-icons/bi'
import * as BiIcons from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthenticatedTemplate } from '@azure/msal-react'
import { IconButton as ChakraIconButton } from '@chakra-ui/button'
import { Image } from '@chakra-ui/image'
import { Box } from '@chakra-ui/layout'
import { Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList } from '@chakra-ui/menu'
import { API } from '@kleo/types'

import { useAuthContext } from 'providers/AuthProvider'
import { useI18Context } from 'providers/i18Provider'
import { useSettingsContext } from 'providers/SettingsProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import { FEUIConfig } from 'types/types'

import { IconButton } from './buttons/IconButton'

export const Navbar = (props: { config?: FEUIConfig[] }) => {
  const { config } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { msalInstance, areTermsAccepted } = useAuthContext()
  const { language, changeLanguage, t, getTForNS } = useI18Context()
  const { toggleColorMode, isLightMode, isDarkMode } = useSettingsContext()
  const { isTablet } = useThemeContext()

  const languageNames: { [key: string]: string } = {
    'en-US': 'English',
    'fr-CA': 'Français',
  }

  // Filter the data array to include only objects with isHidden: false
  const visibleBots = config && config.filter((bot) => !bot.isHidden)

  const currentBot = config && config.filter((bot) => bot.route === location.pathname)

  const assetsPrefix = currentBot && currentBot[0]?.botName && `${currentBot[0].botName.toLocaleLowerCase()}/assets`

  const resourcesURL =
    language === 'en-US'
      ? 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-NMC-Kleo/SitePages/Home.aspx'
      : 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-NMC-Kleo/SitePages/fr/Home.aspx'

  return (
    <Box className={`${isDarkMode && 'background-primary'} border-b`}>
      <Box className="flex items-center justify-between my-2 text-sm md:my-3 width-layout md:text-base">
        {!isTablet ? (
          <>
            <Box className="flex items-center">
              <Image
                alt="k-logo"
                className="h-[32px] mr-4 hover:cursor-pointer"
                onClick={() => navigate(areTermsAccepted ? '/home' : '/')}
                onKeyDown={(event: KeyboardEvent<HTMLImageElement>) => {
                  if (event.key === 'Enter') {
                    navigate(areTermsAccepted ? '/home' : '/')
                  }
                }}
                src="../images/k-logo.png"
                tabIndex={0}
              />
              {currentBot && currentBot[0]?.navbarLogoLight && currentBot[0]?.navbarLogoDark && assetsPrefix && (
                // Max height of the image is set to 45px to match the KPMG logo
                <Image
                  className="mr-4 max-h-[45px]"
                  src={
                    isLightMode
                      ? `${assetsPrefix}/${currentBot[0].navbarLogoLight}.svg`
                      : `${assetsPrefix}/${currentBot[0].navbarLogoDark}.svg`
                  }
                  alt="navbar-secondary-logo"
                  tabIndex={0}
                />
              )}
            </Box>
            <Box className="z-50">
              <Menu>
                <MenuButton size="sm" as={ChakraIconButton} aria-label="Options" icon={<BiMenu />} variant="outline" />
                <MenuList className="max-w-[90vw] pt-0">
                  <MenuGroup className="mx-3" aria-label="page-menu-selections" title={t('navbar.groups.pages')}>
                    <AuthenticatedTemplate>
                      <MenuItem
                        tabIndex={0}
                        onClick={() => navigate(areTermsAccepted ? '/home' : '/')}
                        onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                          if (event.key === 'Enter') {
                            navigate(areTermsAccepted ? '/home' : '/')
                          }
                        }}
                        icon={<BiHome className="text-lg" />}
                      >
                        {t('navbar.links.home')}
                      </MenuItem>
                    </AuthenticatedTemplate>

                    <MenuItem
                      tabIndex={0}
                      onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                        if (event.key === 'Enter') {
                          window.open(resourcesURL, '_blank')
                        }
                      }}
                      onClick={() => window.open(resourcesURL, '_blank')}
                      icon={<BiInfoCircle className="text-lg" />}
                    >
                      {t('navbar.links.resources')}
                    </MenuItem>

                    <MenuItem
                      tabIndex={0}
                      onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                        if (event.key === 'Enter') {
                          navigate('/about')
                        }
                      }}
                      onClick={() => navigate('/about')}
                      icon={<BiInfoCircle className="text-lg" />}
                    >
                      {t('navbar.links.about')}
                    </MenuItem>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup className="mx-3" aria-label="utility-menu-selections" title={t('navbar.groups.utilities')}>
                    <AuthenticatedTemplate>
                      <MenuItem
                        tabIndex={0}
                        onClick={() => msalInstance.logout()}
                        onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                          if (event.key === 'Enter') {
                            msalInstance.logout()
                          }
                        }}
                        icon={<BiLogOut className="text-lg" />}
                      >
                        {t('navbar.links.logout')}
                      </MenuItem>
                    </AuthenticatedTemplate>
                    <MenuItem
                      tabIndex={0}
                      onClick={() => changeLanguage(language === 'en-US' ? 'fr-CA' : 'en-US')}
                      onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                        if (event.key === 'Enter') {
                          changeLanguage(language === 'en-US' ? 'fr-CA' : 'en-US')
                        }
                      }}
                      icon={<BiGlobe className="text-lg" />}
                    >
                      {languageNames[language === 'en-US' ? 'fr-CA' : 'en-US']}
                    </MenuItem>
                    <MenuItem
                      tabIndex={0}
                      icon={isLightMode ? <BiMoon className="text-lg" /> : <BiSun className="text-lg" />}
                      onClick={toggleColorMode}
                      onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                        if (event.key === 'Enter') {
                          toggleColorMode()
                        }
                      }}
                    >
                      {t('navbar.links.theme')}
                    </MenuItem>
                  </MenuGroup>
                  {Array.isArray(visibleBots) && visibleBots.length && areTermsAccepted ? (
                    <>
                      <MenuDivider />
                      <MenuGroup className="mx-3" aria-label="bot-menu-selections" title={t('navbar.groups.bots')}>
                        {visibleBots.map((bot: API.FEConfig, botIndex: number) => {
                          let Icon: IconType | string = ''
                          const botT = getTForNS(bot.botName.toLocaleLowerCase())

                          if (bot.icon) {
                            const BiIcon = (BiIcons as Record<string, IconType>)[bot.icon]
                            // Check if the component exists in the 'react-icons' library
                            if (BiIcon) {
                              Icon = BiIcon
                            } else {
                              Icon = isLightMode ? '../images/robotBlack.png' : '../images/robotWhite.png'
                            }
                          } else {
                            Icon = isLightMode ? '../images/robotBlack.png' : '../images/robotWhite.png'
                          }

                          return (
                            <MenuItem
                              key={`${bot.botName}_${bot.type}_${botIndex}`}
                              tabIndex={0}
                              onClick={() => navigate(bot.route)}
                              onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                                if (event.key === 'Enter') {
                                  navigate(bot.route)
                                }
                              }}
                              icon={
                                <>
                                  {typeof Icon === 'string' ? (
                                    <Image src={Icon} alt="robot-black" className="w-[18px] h-[18px]" />
                                  ) : (
                                    <Icon className="text-lg" />
                                  )}
                                </>
                              }
                            >
                              {botT('home.header')}
                            </MenuItem>
                          )
                        })}
                      </MenuGroup>
                    </>
                  ) : null}
                </MenuList>
              </Menu>
            </Box>
          </>
        ) : (
          <>
            <Box className="flex items-center">
              <Image
                alt="k-logo"
                className="h-10 mr-4 hover:cursor-pointer"
                onClick={() => navigate(areTermsAccepted ? '/home' : '/')}
                onKeyDown={(event: KeyboardEvent<HTMLImageElement>) => {
                  if (event.key === 'Enter') {
                    navigate(areTermsAccepted ? '/home' : '/')
                  }
                }}
                src="../images/k-logo.png"
                tabIndex={0}
              />
              {currentBot && currentBot[0]?.navbarLogoLight && currentBot[0]?.navbarLogoDark && assetsPrefix && (
                // Max height of the image is set to 45px to match the KPMG logo
                <Image
                  className="mr-4 max-h-[45px]"
                  src={
                    isLightMode
                      ? `${assetsPrefix}/${currentBot[0].navbarLogoLight}.svg`
                      : `${assetsPrefix}/${currentBot[0].navbarLogoDark}.svg`
                  }
                  alt="navbar-secondary-logo"
                  tabIndex={0}
                />
              )}
            </Box>
            <Box className="flex items-center">
              <AuthenticatedTemplate>
                <p
                  tabIndex={0}
                  onClick={() => navigate(areTermsAccepted ? '/home' : '/')}
                  onKeyDown={(event: KeyboardEvent<HTMLParagraphElement>) => {
                    if (event.key === 'Enter') {
                      navigate(areTermsAccepted ? '/home' : '/')
                    }
                  }}
                  className={`text-sm lg:text-base shadow-none px-3 my-1 hover:cursor-pointer hover:underline rounded ${
                    isLightMode ? 'focus:ring-light' : 'focus:ring-dark'
                  }`}
                >
                  {t('navbar.links.home')}
                </p>
              </AuthenticatedTemplate>
              <p
                tabIndex={0}
                onClick={() => window.open(resourcesURL, '_blank')}
                onKeyDown={(event: KeyboardEvent<HTMLParagraphElement>) => {
                  if (event.key === 'Enter') {
                    window.open(resourcesURL, '_blank')
                  }
                }}
                className={`text-sm lg:text-base px-3 my-1 hover:cursor-pointer hover:underline rounded ${
                  isLightMode ? 'focus:ring-light' : 'focus:ring-dark'
                }`}
              >
                {t('navbar.links.resources')}
              </p>
              <p
                tabIndex={0}
                onClick={() => navigate('/about')}
                onKeyDown={(event: KeyboardEvent<HTMLParagraphElement>) => {
                  if (event.key === 'Enter') {
                    navigate('/about')
                  }
                }}
                // Added whitespace-nowrap below because "About" translates to "A propos de" and made the item start wrapping to the next line due to the spaces
                className={`text-sm lg:text-base px-3 my-1 hover:cursor-pointer hover:underline rounded whitespace-nowrap ${
                  isLightMode ? 'focus:ring-light' : 'focus:ring-dark'
                }`}
              >
                {t('navbar.links.about')}
              </p>
              <Box className="pr-1 ml-2 border-l border-kpmgGray4">&nbsp;</Box>
              <AuthenticatedTemplate>
                <p
                  tabIndex={0}
                  onClick={() => msalInstance.logout()}
                  onKeyDown={(event: KeyboardEvent<HTMLParagraphElement>) => {
                    if (event.key === 'Enter') {
                      msalInstance.logout()
                    }
                  }}
                  className={`text-sm lg:text-base px-3 my-1 hover:cursor-pointer hover:underline rounded ${
                    isLightMode ? 'focus:ring-light' : 'focus:ring-dark'
                  }`}
                >
                  {t('navbar.links.logout')}
                </p>
              </AuthenticatedTemplate>

              <p
                tabIndex={0}
                className={`text-sm lg:text-base px-3 my-1 hover:cursor-pointer hover:underline rounded ${
                  isLightMode ? 'focus:ring-light' : 'focus:ring-dark'
                }`}
                onClick={() => changeLanguage(language === 'en-US' ? 'fr-CA' : 'en-US')}
                onKeyDown={(event: KeyboardEvent<HTMLParagraphElement>) => {
                  if (event.key === 'Enter') {
                    changeLanguage(language === 'en-US' ? 'fr-CA' : 'en-US')
                  }
                }}
              >
                {languageNames[language === 'en-US' ? 'fr-CA' : 'en-US']}
              </p>
              <IconButton
                sx={{
                  p: '2', // Set the desired padding value in the y-direction
                }}
                className={`focus:shadow-none ml-2 rounded-lg ${isLightMode ? 'focus:ring-light' : 'focus:ring-dark'}`}
                onClick={toggleColorMode}
                onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                  // TODO - Investigate why === 'Enter' does not work properly
                  if (event.code === '13') {
                    toggleColorMode()
                  }
                }}
                variant="outline"
                aria-label="light-mode"
                iconName={isLightMode ? BiMoon : BiSun}
              />
              {/* Need to use ternary operators "?" and ":" instead of just "&&" because instead of nothing showing when the condition is false, it shows a "0" in the UI */}
              {Array.isArray(visibleBots) && visibleBots.length && areTermsAccepted ? (
                <Box className="z-50 ml-4">
                  <Menu>
                    <MenuButton
                      as={ChakraIconButton}
                      aria-label="Options"
                      icon={
                        <Image
                          src={isLightMode ? '../images/robotBlack.png' : '../images/robotWhite.png'}
                          alt="Robot"
                          className="w-6 h-6"
                        />
                      }
                      variant="outline"
                      className={`focus:shadow-none rounded-lg ${isLightMode ? 'focus:ring-light' : 'focus:ring-dark'}`}
                    />
                    <MenuList className="max-w-[90vw]">
                      {visibleBots.map((bot, botIndex: number) => {
                        let Icon: IconType | string = ''
                        const botT = getTForNS(bot.botName.toLocaleLowerCase())

                        if (bot.icon) {
                          const BiIcon = (BiIcons as Record<string, IconType>)[bot.icon]
                          // Check if the component exists in the 'react-icons' library
                          if (BiIcon) {
                            Icon = BiIcon
                          } else {
                            Icon = isLightMode ? '../images/robotBlack.png' : '../images/robotWhite.png'
                          }
                        } else {
                          Icon = isLightMode ? '../images/robotBlack.png' : '../images/robotWhite.png'
                        }

                        return (
                          <MenuItem
                            tabIndex={0}
                            key={`${bot.type}_${bot.botName}_${botIndex}`}
                            onClick={() => navigate(bot.route)}
                            onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                              if (event.key === 'Enter') {
                                navigate(bot.route)
                              }
                            }}
                            icon={
                              <>
                                {typeof Icon === 'string' ? (
                                  <Image src={Icon} alt="robot-black" className="w-6 h-6" />
                                ) : (
                                  <Icon className="text-lg md:text-2xl" />
                                )}
                              </>
                            }
                          >
                            {botT('home.header')}
                          </MenuItem>
                        )
                      })}
                    </MenuList>
                  </Menu>
                </Box>
              ) : null}
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
