import { BiBarChart, BiLineChart, BiPieChartAlt, BiTable } from 'react-icons/bi'
import { Box } from '@chakra-ui/layout'

import { useI18Context } from '../../providers/i18Provider'
import { useSettingsContext } from '../../providers/SettingsProvider'
import {
  BarChartData,
  ChartTypeProperties,
  LineChartData,
  PieChartData,
  SearchFilterItem,
  TableData,
} from '../../types/types'
import { Chart } from '../charts/ChartAccordion'
import { Corpus } from '../Corpus'

type AccordionProps = {
  barChartData?: BarChartData
  botName: string
  botType: string
  docContext?: string
  hasBarChart: boolean
  hasLineChart: boolean
  hasPieChart: boolean
  hasTable: boolean
  index: number
  isOpen: boolean
  lineChartData?: LineChartData
  messageProperties?: {
    line: ChartTypeProperties
    bar: ChartTypeProperties
    pie: ChartTypeProperties
    table: ChartTypeProperties
  }
  messageSpecificDocuments?: SearchFilterItem[]
  pieChartData?: PieChartData
  tableData?: TableData
}

export const Accordion = (props: AccordionProps) => {
  const {
    barChartData,
    botName,
    botType,
    docContext,
    hasBarChart,
    hasLineChart,
    hasPieChart,
    hasTable,
    index: messageIndex,
    isOpen,
    lineChartData,
    messageProperties,
    messageSpecificDocuments,
    pieChartData,
    tableData,
  } = props

  const { t } = useI18Context()
  const { isChatFullScreen } = useSettingsContext()
  const shouldShowCorpus = botType !== 'upload'
  const shouldShowBorder =
    botType === 'upload'
      ? hasLineChart || hasBarChart || hasPieChart || hasTable
      : docContext || hasLineChart || hasBarChart || hasPieChart || hasTable

  return (
    <Box className={`${shouldShowBorder && 'border-t border-t-kpmgDarkBlue'}`}>
      <Chart
        botName={botName}
        chartData={lineChartData}
        chartIcon={<BiLineChart className={`w-4 h-4 md:h-5 md:w-5 ${!isChatFullScreen && 'mr-3 lg:mr-4'}`} />}
        chartName={t('chart.line')}
        chartType="line"
        hasAPIError={!!messageProperties?.line.isAPIError}
        hasGenerateError={!!messageProperties?.line.isGenerateError}
        hasOtherCharts={
          botType === 'upload'
            ? hasBarChart || hasPieChart || hasTable
            : !!docContext || hasBarChart || hasPieChart || hasTable
        }
        hasTimeoutError={!!messageProperties?.line.isTimeoutError}
        isFetchingChart={!!messageProperties?.line.isFetching}
        isOpen={isOpen}
        messageIndex={messageIndex}
      />
      <Chart
        botName={botName}
        chartData={barChartData}
        chartIcon={<BiBarChart className={`w-4 h-4 md:h-5 md:w-5 ${!isChatFullScreen && 'mr-3 lg:mr-4'}`} />}
        chartName={t('chart.bar')}
        chartType="bar"
        hasAPIError={!!messageProperties?.bar.isAPIError}
        hasGenerateError={!!messageProperties?.bar.isGenerateError}
        hasOtherCharts={botType === 'upload' ? hasPieChart || hasTable : !!docContext || hasPieChart || hasTable}
        hasTimeoutError={!!messageProperties?.bar.isTimeoutError}
        isFetchingChart={!!messageProperties?.bar.isFetching}
        isOpen={isOpen}
        messageIndex={messageIndex}
      />
      <Chart
        botName={botName}
        chartData={pieChartData}
        chartIcon={<BiPieChartAlt className={`w-4 h-4 md:h-5 md:w-5 ${!isChatFullScreen && 'mr-3 lg:mr-4'}`} />}
        chartName={t('chart.pie')}
        chartType="pie"
        hasAPIError={!!messageProperties?.pie.isAPIError}
        hasGenerateError={!!messageProperties?.pie.isGenerateError}
        hasOtherCharts={botType === 'upload' ? hasTable : !!docContext || hasTable}
        hasTimeoutError={!!messageProperties?.pie.isTimeoutError}
        isFetchingChart={!!messageProperties?.pie.isFetching}
        isOpen={isOpen}
        messageIndex={messageIndex}
      />
      <Chart
        botName={botName}
        chartData={tableData}
        chartIcon={<BiTable className={`w-4 h-4 md:h-5 md:w-5 ${!isChatFullScreen && 'mr-3 lg:mr-4'}`} />}
        chartName={t('chart.table')}
        chartType="table"
        hasAPIError={!!messageProperties?.table.isAPIError}
        hasGenerateError={!!messageProperties?.table.isGenerateError}
        hasOtherCharts={botType === 'upload' ? false : !!docContext}
        hasTimeoutError={!!messageProperties?.table.isTimeoutError}
        isFetchingChart={!!messageProperties?.table.isFetching}
        isOpen={isOpen}
        messageIndex={messageIndex}
      />
      {shouldShowCorpus && (
        <Corpus
          botName={botName}
          docContext={docContext}
          hasChartData={false}
          messageIndex={messageIndex}
          messageSpecificDocuments={messageSpecificDocuments}
        />
      )}
    </Box>
  )
}
