import { MutableRefObject, ReactNode, useEffect, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Box } from '@chakra-ui/layout'

import { useMatchedElements } from 'hooks/useMatchedElements'

import { useChartContext } from 'providers/ChartProvider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useScrollContext } from 'providers/ScrollProvider'
import { useSettingsContext } from 'providers/SettingsProvider'

import { BotFilterValues, BotFormValues, ChatMessage, DisclaimerProperty } from 'types/types'

import { Accordion } from './Accordion'
import { Content, StreamingContent } from './Content'
import { BotMenu, LogoOnlyAvatar } from './Menu'

type MessageProps = {
  botName: string
  botType: string
  disclaimer: DisclaimerProperty[]
  doc?: string | null
  feedbackButton?: JSX.Element
  index: number
  message: ChatMessage
  scrollRef: MutableRefObject<HTMLDivElement | null>
  submit: SubmitHandler<BotFormValues & BotFilterValues>
  forceMarkdown?: boolean
}

export const Message = (props: MessageProps) => {
  const {
    botName,
    botType,
    disclaimer,
    feedbackButton,
    forceMarkdown,
    index: messageIndex,
    message: {
      content,
      docContext,
      delimiter,
      document: messageSpecificDocuments,
      error,
      messageProperties,
      cancelled,
      tempVoiceControl,
    },
    scrollRef,
    submit,
  } = props

  const { getLineChartData, getBarChartData, getPieChartData, getTableData } = useChartContext()
  const { getCurrentConversationIDForBot } = useMessagesContext()
  const { stateExpanded, handleScrollStateCallback, stateClassNameString: scrollTarget } = useScrollContext()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { showLinks } = useMatchedElements({
    botType,
    content,
    docContext,
  })

  const clipboardText = useMemo(() => {
    if (delimiter) {
      return content.split(delimiter)[0].replace(/\n+$/, '')
    }
    return content
  }, [content, delimiter])

  const currentConversationID = useMemo(() => {
    return getCurrentConversationIDForBot(botName)
  }, [botName, getCurrentConversationIDForBot])

  const lineChartData = useMemo(
    () => getLineChartData(botName, messageIndex, currentConversationID),
    [botName, currentConversationID, getLineChartData, messageIndex]
  )
  const barChartData = useMemo(
    () => getBarChartData(botName, messageIndex, currentConversationID),
    [botName, currentConversationID, getBarChartData, messageIndex]
  )
  const pieChartData = useMemo(
    () => getPieChartData(botName, messageIndex, currentConversationID),
    [botName, currentConversationID, getPieChartData, messageIndex]
  )
  const tableData = useMemo(
    () => getTableData(botName, messageIndex, currentConversationID),
    [botName, currentConversationID, getTableData, messageIndex]
  )

  // The below variables are used to determine whether one of the chart/table accordions are visible on screen for the message
  const hasLineChart =
    !!lineChartData ||
    !!messageProperties?.line.isFetching ||
    !!messageProperties?.line.isAPIError ||
    !!messageProperties?.line.isGenerateError ||
    !!messageProperties?.line.isTimeoutError
  const hasBarChart =
    !!barChartData ||
    !!messageProperties?.bar.isFetching ||
    !!messageProperties?.bar.isAPIError ||
    !!messageProperties?.bar.isGenerateError ||
    !!messageProperties?.bar.isTimeoutError
  const hasPieChart =
    !!pieChartData ||
    !!messageProperties?.pie.isFetching ||
    !!messageProperties?.pie.isAPIError ||
    !!messageProperties?.pie.isGenerateError ||
    !!messageProperties?.pie.isTimeoutError
  const hasTable =
    !!tableData ||
    !!messageProperties?.table.isFetching ||
    !!messageProperties?.table.isAPIError ||
    !!messageProperties?.table.isGenerateError ||
    !!messageProperties?.table.isTimeoutError

  // stateExpended holds boolean value and accordion specific ID to run when opened, sends callback to ChatBot component, setTimeout used to wait for graphs to be fully rendered
  useEffect(() => {
    if (stateExpanded !== undefined) {
      const timer = setTimeout(() => {
        handleScrollStateCallback(stateExpanded)
      }, 200)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateExpanded])

  useEffect(() => {
    if (showLinks && scrollRef && scrollRef.current && scrollTarget) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [showLinks, scrollRef, scrollTarget])

  const MemoizedBotMenu = useMemo(
    () => (
      <BotMenu
        botName={botName}
        clipboardText={clipboardText}
        hasBarChart={!!barChartData || !!messageProperties?.bar.isGenerateError}
        hasLineChart={!!lineChartData || !!messageProperties?.line.isGenerateError}
        hasPieChart={!!pieChartData || !!messageProperties?.pie.isGenerateError}
        hasTable={!!tableData || !!messageProperties?.table.isGenerateError}
        index={messageIndex}
        messageProperties={messageProperties}
        setIsOpen={setIsOpen}
      />
    ),
    [barChartData, botName, clipboardText, lineChartData, messageIndex, messageProperties, pieChartData, tableData]
  )

  const MemoizedAccordion = useMemo(
    () => (
      <Accordion
        barChartData={barChartData}
        botName={botName}
        botType={botType}
        docContext={docContext}
        hasBarChart={hasBarChart}
        hasLineChart={hasLineChart}
        hasPieChart={hasPieChart}
        hasTable={hasTable}
        index={messageIndex}
        isOpen={isOpen}
        lineChartData={lineChartData}
        messageProperties={messageProperties}
        messageSpecificDocuments={messageSpecificDocuments}
        pieChartData={pieChartData}
        tableData={tableData}
      />
    ),
    [
      barChartData,
      botName,
      botType,
      docContext,
      hasBarChart,
      hasLineChart,
      hasPieChart,
      hasTable,
      isOpen,
      lineChartData,
      messageIndex,
      messageProperties,
      messageSpecificDocuments,
      pieChartData,
      tableData,
    ]
  )

  const MemoizedContent = useMemo(
    () => (
      <Content
        botName={botName}
        botType={botType}
        cancelled={cancelled}
        content={content}
        delimiter={delimiter}
        disclaimer={disclaimer}
        docContext={docContext}
        error={error}
        hasBarChart={hasBarChart}
        hasLineChart={hasLineChart}
        hasPieChart={hasPieChart}
        hasTable={hasTable}
        index={messageIndex}
        scrollRef={scrollRef}
        submit={submit}
        forceMarkdown={forceMarkdown}
        tempVoiceControl={tempVoiceControl}
      />
    ),
    [
      tempVoiceControl,
      botName,
      botType,
      cancelled,
      content,
      delimiter,
      disclaimer,
      docContext,
      error,
      forceMarkdown,
      hasBarChart,
      hasLineChart,
      hasPieChart,
      hasTable,
      messageIndex,
      scrollRef,
      submit,
    ]
  )

  return (
    <MessageLayout
      accordion={MemoizedAccordion}
      avatar={MemoizedBotMenu}
      feedback={feedbackButton}
      messageIndex={messageIndex}
    >
      {MemoizedContent}
    </MessageLayout>
  )
}

type StreamingMessageProps = {
  forceMarkdown?: boolean
  messageIndex: number
  streamingMessage: ChatMessage
}

export const StreamingMessage = ({ forceMarkdown, messageIndex, streamingMessage }: StreamingMessageProps) => {
  return (
    <MessageLayout messageIndex={messageIndex} avatar={<LogoOnlyAvatar />}>
      <StreamingContent forceMarkdown={forceMarkdown} streamingMessage={streamingMessage} />
    </MessageLayout>
  )
}

export const MessageLayout = ({
  accordion,
  avatar,
  children,
  feedback,
  messageIndex,
}: {
  accordion?: ReactNode
  avatar: ReactNode
  children: ReactNode
  feedback?: ReactNode
  messageIndex: number
}) => {
  const { isChatFullScreen } = useSettingsContext()

  if (isChatFullScreen) {
    return (
      <Box className="flex flex-col w-full bg-kpmgGray5" test-id="bot-message">
        <Box className="flex items-start px-2 py-4">
          <Box className="flex flex-col mr-2">
            <Box className="mb-2">{avatar}</Box>
            {feedback && <Box className="text-center">{feedback}</Box>}
          </Box>
          <Box tabIndex={0} className="flex-1 text-black" id={messageIndex.toString()}>
            {children}
          </Box>
        </Box>
        {accordion && accordion}
      </Box>
    )
  } else {
    return (
      <Box className="flex items-start mt-2 md:mt-4" test-id="bot-message">
        <Box className="flex flex-col mr-2 md:mr-4">
          <Box className="mb-2">{avatar}</Box>
          {feedback && <Box className="text-center">{feedback}</Box>}
        </Box>
        <Box tabIndex={0} className="max-w-[80%] text-black" id={messageIndex.toString()}>
          {children}
          {accordion && accordion}
        </Box>
      </Box>
    )
  }
}
