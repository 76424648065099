export const removePunctuation = (text: string) => {
  return text.replace(/[^\w\s]|_/g, '')
}

const blacklistedChars = ['\u20DE', '\u20E3']

const blackListExpression = `[${blacklistedChars.join('')}]`
export const blackListRegex = new RegExp(blackListExpression, 'g')

export const isStringArray = <T>(arr: T[]): arr is Extract<T, string>[] => {
  return arr.every((item) => typeof item === 'string')
}
