import { TFunction } from 'i18next'
import moment from 'moment'

import 'moment/locale/fr-ca' // Import the French Canadian locale

export function formatLastUpdatedDate(date: string, language: string): string {
  // Set the language for the date to be formatted in
  const formattedDateString = moment(date, 'YYYY-MM-DD').locale(language).set({
    hour: 12,
    minute: 0,
    second: 0,
    millisecond: 0,
  })

  if (formattedDateString.isValid()) {
    return formattedDateString.format(language === 'en-US' ? 'MMMM D, YYYY' : 'D MMMM, YYYY')
  } else {
    throw new Error(`Can not format date. lastUpdated: ${date}. language: ${language}`)
  }
}

export function formatMaxFileSize(uploadFileMaxSize: number, numberFormat: Intl.NumberFormat, t: TFunction): string {
  if (uploadFileMaxSize < 1000000) {
    return numberFormat.format(uploadFileMaxSize / 1000) + ' ' + t('uploadModal.KB')
  } else {
    return numberFormat.format(uploadFileMaxSize / 1000000) + ' ' + t('uploadModal.MB')
  }
}
