import { KeyboardEvent } from 'react'
import { useFormContext, UseFormSetValue } from 'react-hook-form'
import { IconType } from 'react-icons'
import * as BiIcons from 'react-icons/bi'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion'
import { Image } from '@chakra-ui/image'
import { Box, Text } from '@chakra-ui/layout'
import { TFunction } from 'i18next'

import { useI18Context } from '../providers/i18Provider'
import { BotFormValues, PromptLibraryPrompts, PromptLibraryProperty } from '../types/types'

import { Link } from './Link'

type PromptLibraryProps = {
  botName: string
}

export const PromptLibrary = (props: PromptLibraryProps) => {
  const { botName } = props
  const { getTForNS, t } = useI18Context()
  const botT = getTForNS(botName.toLocaleLowerCase())
  const { setValue } = useFormContext<BotFormValues>()

  const promptLibrary = botT('promptLibrary', { returnObjects: true }) as PromptLibraryProperty[]

  return (
    <Box className="flex flex-col items-center">
      <Box className="mb-4 bg-gray-800 backdrop-blur bg-opacity-[.3] px-2 md:px-3 py-1 rounded-md inline-block">
        <Text tabIndex={0} className="text-xs font-bold md:text-sm">
          {t('promptLibrary.linkVerbiage')}{' '}
          <Link
            classToAdd="text-darkLink hover:text-darkLinkHover"
            external
            name={t('promptLibrary.here')}
            url="https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-NMC-Kleo/SitePages/Kleo-prompt-library.aspx"
          />
        </Text>
      </Box>
      <Accordion
        allowToggle
        className="bg-gray-800 backdrop-blur bg-opacity-[.3] rounded-md first:border-t-0 last:border-b-0 w-full"
      >
        {promptLibrary.map((promptItem: PromptLibraryProperty, promptLibraryIndex: number) => {
          const BiIcon = (BiIcons as Record<string, IconType>)[botT(promptItem.icon)]
          return (
            <AccordionItem key={`${promptLibraryIndex}_${promptItem.title}`}>
              <AccordionButton className="flex justify-between">
                <Box className="flex items-center">
                  {BiIcon ? (
                    <BiIcon className="mr-2 text-lg md:text-xl" />
                  ) : (
                    <Image src="../images/robotWhite.png" alt="Robot" className="w-4 h-4 mr-2 md:w-5 md:h-5" />
                  )}
                  <Text className="text-sm text-left md:text-base">{botT(promptItem.title)}</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel
                className={`${'prompts' in promptItem && 'grid grid-cols-1 gap-2 pb-4 xs:grid-cols-2 xl:grid-cols-3'}`}
              >
                {'prompts' in promptItem ? (
                  promptItem.prompts.map((prompt: string, promptIndex: number) => {
                    return (
                      <AccordionPrompts
                        key={`${promptLibraryIndex}_${promptItem.title}_${promptIndex}`}
                        botT={botT}
                        index={promptIndex}
                        prompt={prompt}
                        setValue={setValue}
                      />
                    )
                  })
                ) : (
                  <Accordion
                    allowToggle
                    className="bg-gray-800 backdrop-blur bg-opacity-[.3] rounded-md first:border-t-0 last:border-b-0 w-full"
                  >
                    {promptItem.subPrompts.map((subPromptItem: PromptLibraryPrompts, subPromptIndex: number) => {
                      const SubPromptBiIcon = (BiIcons as Record<string, IconType>)[botT(subPromptItem.icon)]
                      return (
                        <AccordionItem key={`${subPromptIndex}_${subPromptItem.title}`}>
                          <AccordionButton className="flex justify-between">
                            <Box className="flex items-center">
                              {SubPromptBiIcon ? (
                                <SubPromptBiIcon className="mr-2 text-lg md:text-xl" />
                              ) : (
                                <Image
                                  src="../images/robotWhite.png"
                                  alt="Robot"
                                  className="w-4 h-4 mr-2 md:w-5 md:h-5"
                                />
                              )}
                              <Text className="text-sm text-left md:text-base">{botT(subPromptItem.title)}</Text>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel className="grid grid-cols-1 gap-2 pb-4 xs:grid-cols-2 xl:grid-cols-3">
                            {subPromptItem.prompts.map((prompt: string, promptIndex: number) => {
                              return (
                                <AccordionPrompts
                                  key={`${promptLibraryIndex}_${promptItem.title}_${promptIndex}`}
                                  botT={botT}
                                  index={promptIndex}
                                  prompt={prompt}
                                  setValue={setValue}
                                />
                              )
                            })}
                          </AccordionPanel>
                        </AccordionItem>
                      )
                    })}
                  </Accordion>
                )}
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
    </Box>
  )
}

const AccordionPrompts = (props: {
  botT: TFunction<'translation', undefined>
  index: number
  prompt: string
  setValue: UseFormSetValue<BotFormValues>
}) => {
  const { botT, index, prompt, setValue } = props
  const valueToSet = botT(prompt)
  return (
    <Box
      key={`${index}_${prompt}`}
      tabIndex={0}
      className="relative px-2 py-1 text-black border rounded-br-none rounded-xl bg-kpmgGray5 hover:bg-kpmgGray4 hover:border-kpmgGray4 hover:cursor-pointer"
      onClick={() =>
        setValue('userQuery', valueToSet, {
          shouldValidate: false,
        })
      }
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
          event.preventDefault()
          setValue('userQuery', valueToSet, {
            shouldValidate: false,
          })
        }
      }}
    >
      <Text className="pr-4 text-xs">{botT(prompt)}</Text>
      <BiIcons.BiPlus className="absolute right-0.5 bottom-0.5" />
    </Box>
  )
}

export default PromptLibrary
