import { ComponentType } from 'react'

import { useEventLogger } from '../hooks/useEventLogger'
import { UIErrorEventPayload } from '../types/types'

type WithEventLoggerHOCProps = {
  logUIErrorEvent: (payload: UIErrorEventPayload) => void
}

export const withEventLoggerHOC = <P extends WithEventLoggerHOCProps>(Component: ComponentType<P>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (props: any) => {
    const { logUIErrorEvent } = useEventLogger()

    return <Component logUIErrorEvent={logUIErrorEvent} {...props} />
  }
}
