import { type ThemeConfig } from '@chakra-ui/theme'
import { extendTheme } from '@chakra-ui/theme-utils'

const config: ThemeConfig = {
  // Set the initial color mode to 'dark'
  initialColorMode: 'dark',
  // Disable the use of the system color mode
  useSystemColorMode: false,
}

const theme = extendTheme({
  config,
  components: {
    Button: {
      variants: {
        kpmgWhite: {
          bg: 'white',
          // gray-800
          color: '#1F2937',
          _hover: {
            // whiteHover
            bg: '#F2F2F2',
          },
        },
        kpmgDarkBlue: {
          // kpmgDarkBlue
          bg: '#0C233C',
          color: 'white',
          _hover: {
            // kpmgDarkBlueHover
            bg: '#0F2B49',
          },
        },
        kpmgCobaltBlue: {
          // kpmgCobaltBlue
          bg: '#1E4AE2',
          color: 'white',
          _hover: {
            // kpmgCobaltBlueHover
            bg: '#1A41CB',
          },
        },
      },
    },
  },
  fonts: {
    heading: 'OpenSansCondensed, ui-sans-serif, system-ui',
    body: 'OpenSans, ui-sans-serif, system-ui',
  },
})

export default theme
