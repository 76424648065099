import { useEffect, useRef, useState } from 'react'
import * as Scroll from 'react-scroll'

import { useBotSpecificContext } from 'providers/BotSpecificProvider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useScrollContext } from 'providers/ScrollProvider'

export const useScroller = (botName: string) => {
  const { isStreaming, messages, streamingMessage } = useBotSpecificContext()
  const { getCurrentConversationIDForBot } = useMessagesContext()
  const { stateClassNameString, stateScroll, updateStateClassNameString } = useScrollContext()

  const scroll = Scroll.scroller

  // Used to as a reference of the Chat Bot messages screen to automatically scroll to the bottom when new messages are added
  const scrollRef = useRef<HTMLDivElement | null>(null)

  // isAutoScrolling keeps track of whether or not we are in a state of auto-scrolling being enabled while messages are streamed in
  const [isAutoScrolling, setIsAutoScrolling] = useState<boolean>(true)

  // isScrolling keeps track of whether we are currently scrolling down automatically
  const [isScrolling, setIsScrolling] = useState<boolean>(false)

  // wasStreaming keeps track of the previous value of isStreaming
  const [wasStreaming, setWasStreaming] = useState<boolean>(false)

  // Check where the user is in the scrollable container to know if he is at the bottom or not
  if (scrollRef.current) {
    // If a user scrolls to the bottom of the messages container or we are already at the bottom of the messages container (what is being checked in the "< 1" check), enable auto-scrolling
    if (
      scrollRef.current.scrollHeight - scrollRef.current.clientHeight - scrollRef.current.scrollTop < 1 &&
      !isAutoScrolling
    ) {
      setIsAutoScrolling(true)
    }
  }

  // This useEffect is responsible for adding an EventListener to the messages container to know when a user is scrolling within it
  useEffect(() => {
    const container = scrollRef.current
    container?.addEventListener('scroll', handleScroll)

    return () => {
      container?.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // This keeps track of the last scroll position value that the user was at.
  // Can't be changed to a useState due to React's lifecycle messing up
  let lastScrollPosition = 0

  const handleScroll = () => {
    const currentScrollTop = scrollRef.current?.scrollTop || 0

    // If the current scroll position is less than the lastScrollPosition, then that means the user is scrolling up - therefore stop auto-scrolling
    if (currentScrollTop < lastScrollPosition) {
      setIsAutoScrolling(false)
    }

    lastScrollPosition = currentScrollTop
  }

  // When the messages useState is updated, automatically scroll to the bottom of the messages Box or when we are already in the bottom
  useEffect(() => {
    if (scrollRef.current && Array.isArray(messages)) {
      if (wasStreaming !== isStreaming || (isAutoScrolling && !isScrolling && isStreaming)) {
        // If we have started streaming or finished streaming || (auto-scrolling is enabled && an auto-scroll isn't currently taking place && we are currently streaming in a message)
        if (isStreaming) {
          // If a new answer is being streamed in, set auto-scrolling to true by default
          setIsAutoScrolling(true)
        }
        setIsScrolling(true)
        if (wasStreaming && !isStreaming && isAutoScrolling) {
          // If we just stopped streaming and we should be auto-scrolling, delay the scroll from happening by 250ms. This is to give enough time for Corpus to show on screen, then scroll
          setTimeout(() => {
            if (scrollRef.current) {
              scrollRef.current.scrollTop = scrollRef.current.scrollHeight
            }
          }, 250)
        } else if (isAutoScrolling && isStreaming) {
          // Only when we are allowed to scroll and actively streaming, do the auto scrolling
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        }
        setTimeout(() => {
          // Need this timeout so that we don't have multiple scrolls going on concurrently, drastically improves UX
          setIsScrolling(false)
          // Assuming scroll duration is around 250ms
        }, 250)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, isStreaming, streamingMessage])

  // When we mount on a page using the Bot Menu in the top-right (specifically bot's of the same type), we need to make sure we scroll to the bottom automatically, not doing so with scroll-smooth enabled
  useEffect(() => {
    if (scrollRef.current) {
      // Temporarily disable smooth scrolling
      scrollRef.current.style.scrollBehavior = 'auto'

      // Scroll to the bottom
      if (Array.isArray(messages) && messages.length) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight
      }

      // Re-enable smooth scrolling
      scrollRef.current.style.scrollBehavior = 'smooth'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName, getCurrentConversationIDForBot(botName)])

  // Use stateClassNameString from id of BotMessage accordions to identify to where to scroll to
  useEffect(() => {
    const conversationID = getCurrentConversationIDForBot(botName)
    if (
      conversationID &&
      stateClassNameString !== undefined &&
      stateScroll !== undefined &&
      stateScroll.activeScrollTo &&
      stateScroll['activeStates'][conversationID]?.[stateScroll.activeScrollTo] !== false
    ) {
      scroll.scrollTo(stateClassNameString, {
        smooth: 'easeInOutQuint',
        containerId: 'chat-message-box',
      })
    }
    // We've finished scrolling to our target or it was already in view, we no need to keep track of it anymore. Target className will be set in the next time we run the callback handler
    updateStateClassNameString(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateScroll])

  // Keep in memory the previous state of the isStreaming value. This will later be used to determine if streaming has started or ended
  useEffect(() => {
    setWasStreaming(isStreaming)
  }, [isStreaming])

  return {
    scrollRef,
  }
}
