import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { API } from '@kleo/types'

import { useEventLogger } from 'hooks/useEventLogger'

import { EmptyError, UploadError, UploadSchemaError } from 'utils/appError'
import { formatMaxFileSize } from 'utils/formatter'
import { fetchData, putFileIntoStorage } from 'utils/http/methods'

import type { FEUIConfig, UploadDocContents } from 'types/types'

import { useAuthContext } from './AuthProvider'
import { useConfigContext } from './ConfigurationProvider'
import { useI18Context } from './i18Provider'

type UploadContextType = {
  deleteDocumentContent: (conversationIDsToDelete: number[]) => void
  docContents: Record<number, UploadDocContents>
  isFileUploading: boolean
  isWarningAcknowledged: boolean
  maxFileNameLength: number
  maxFileSize: string
  setDocContents: Dispatch<SetStateAction<Record<number, UploadDocContents>>>
  setIsWarningAcknowledged: Dispatch<SetStateAction<boolean>>
  setUploadError: Dispatch<SetStateAction<string>>
  uploadError: string
  uploadFileRequest: (file: File, conversationID?: number) => void
  uploadModalOperations: {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
  }
}

export const UploadContext = createContext<UploadContextType>({} as UploadContextType)

type UploadProviderProps = {
  children?: React.ReactNode
  config?: FEUIConfig[]
}

export const UploadProvider = ({ children, config }: UploadProviderProps) => {
  const { getToken } = useAuthContext()
  const { API_ENDPOINT } = useConfigContext()
  const { logUIErrorEvent } = useEventLogger()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t, language } = useI18Context()

  const [isFileUploading, setIsFileUploading] = useState(false)
  const [docContents, setDocContents] = useState<Record<number, UploadDocContents>>({})
  const [uploadError, setUploadError] = useState('')
  const [isWarningAcknowledged, setIsWarningAcknowledged] = useState<boolean>(false)

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const { uploadFileMaxSize, maxFileNameLength } = useMemo(() => {
    const uploadBotConfig = config?.find((botConfig) => botConfig.type === 'upload')

    if (!uploadBotConfig) {
      return {
        uploadTokenMaxSize: 0,
        uploadFileMaxSize: 0,
        maxFileNameLength: 0,
      }
    }

    return {
      uploadTokenMaxSize: uploadBotConfig.uploadTokenMaxSize ?? 0,
      uploadFileMaxSize: uploadBotConfig.uploadFileMaxSize ?? 0,
      maxFileNameLength: uploadBotConfig.fileNameLength ?? 0,
    }
  }, [config])

  const maxFileSize = useMemo(() => {
    return formatMaxFileSize(uploadFileMaxSize, numberFormat, t)
  }, [uploadFileMaxSize, numberFormat, t])

  const getUploadUrl = async ({ file, rId }: { file: File; rId: string }) => {
    try {
      // const string: unknown = 'hello'
      const token = await getToken()

      const response = await fetchData<API.UploadAPI.GetUrlUploadRes>({
        url: `${API_ENDPOINT}/uploadapi/urlUpload`,
        token,
        payload: { contentType: file.type, fileSize: file.size, fileName: file.name, botName: 'UPLOADBOT' },
        rId,
      })

      // if uploadurl is a success, because documentId only exist in success response
      if ('documentId' in response) {
        return { documentId: response.documentId, url: response.url }
      }
      // schema error
      throw new UploadSchemaError(response.errorList?.[0].code)
    } catch (e) {
      if (e instanceof UploadSchemaError) {
        throw new UploadSchemaError(e.message)
      }

      throw new UploadError('upload-request-error-uploadUrl', e as Error)
    }
  }

  const putFileIntoStorageContainer = async ({ url, file, rId }: { url: string; file: File; rId: string }) => {
    try {
      const { status, statusText } = await putFileIntoStorage({
        url,
        file,
        headers: {
          'Content-Type': file.type,
          'x-ms-blob-type': 'BlockBlob',
        },
        rId,
      })

      return { status, statusText }
    } catch (e) {
      throw new UploadError('upload-request-error-documentUpload', e as Error)
    }
  }

  const getDocumentText = async ({ documentId, rId }: { documentId: string; rId: string }) => {
    try {
      const token = await getToken()
      const {
        documentId: returnedDocumentId,
        text,
        characterCount,
      } = await fetchData<{
        title: string
        documentId: string
        text: string
        characterCount: number
        tokens: number
      }>({
        url: `${API_ENDPOINT}/uploadapi/readDocument`,
        token,
        payload: {
          documentId,
          botName: 'UPLOADBOT',
        },
        retries: 0,
        rId,
      })

      return {
        characterCount,
        returnedDocumentId,
        text,
      }
    } catch (e) {
      throw new UploadError('upload-request-error-readDocument', e as Error)
    }
  }

  const uploadFileRequest = async (file: File, conversationID?: number) => {
    // Initialize a global request ID that will be overwritten for each step in the Upload process. The step that fails will be the request ID used in the logEvent
    let rId = ''

    try {
      setIsFileUploading(true)

      // request ID for getUploadUrl
      rId = `${Math.random()}-${new Date().getTime()}`
      const { documentId, url } = await getUploadUrl({ file, rId })

      if (documentId && url) {
        // request ID for putFileIntoStorageContainer
        rId = `${Math.random()}-${new Date().getTime()}`
        const { status, statusText } = await putFileIntoStorageContainer({ url, file, rId })

        if (status === 201 && statusText === 'Created') {
          // request ID for getDocumentText
          rId = `${Math.random()}-${new Date().getTime()}`
          const { text, characterCount } = await getDocumentText({ documentId, rId })

          if (characterCount === 0) {
            throw new EmptyError()
          }
          // if document id sent is the same as the one in the response

          setDocContents((currentDocContents) => {
            return {
              ...currentDocContents,
              [conversationID ? conversationID : 0]: {
                text,
                title: file.name,
                type: file.type,
                documentId,
                characterCount,
              },
            }
          })
        } else {
          logUIErrorEvent({
            bot: 'UPLOADBOT',
            errorMessage: `Did NOT get back status === 201 && statusText === 'Created' when calling putFileIntoStorageContainer. status: ${status}, statusText: ${statusText}`,
            requestId: rId,
          })
        }
      }

      onClose()
    } catch (e) {
      logUIErrorEvent({
        bot: 'UPLOADBOT',
        error: e as Error,
        errorMessage: 'upload-file-request-error',
        requestId: rId,
      })

      if (e instanceof UploadSchemaError) {
        setUploadError(e.message)
      } else if (e instanceof UploadError) {
        if (e.message === 'upload-request-error-uploadUrl') {
          setUploadError('uploadUrlEndpointFailed')
        } else if (e.message === 'upload-request-error-documentUpload') {
          setUploadError('uploadError')
        } else if (e.message === 'upload-request-error-readDocument') {
          setUploadError('readDocumentEndpointFailed')
        } else {
          setUploadError('uploadError')
        }
      } else if (e instanceof EmptyError) {
        setUploadError('emptyError')
      } else {
        // generic error
        setUploadError('uploadError')
      }
    } finally {
      setIsFileUploading(false)
    }
  }

  /**
   * Deletes an specific file's content
   * @param {number} conversationID - The ID of the conversation to be deleted.
   * @returns {void}
   */
  const deleteDocumentContent = (conversationIDsToDelete: number[]): void => {
    setDocContents((currentDocContents) => {
      const filteredDocContents: Record<number, UploadDocContents> = {}

      for (const key in currentDocContents) {
        const recordNumber = parseInt(key)
        if (!conversationIDsToDelete.includes(recordNumber)) {
          filteredDocContents[recordNumber] = currentDocContents[recordNumber]
        }
      }

      return filteredDocContents
    })
  }

  return (
    <>
      <UploadContext.Provider
        value={{
          deleteDocumentContent,
          docContents,
          isFileUploading,
          isWarningAcknowledged,
          maxFileNameLength,
          maxFileSize,
          setDocContents,
          setIsWarningAcknowledged,
          setUploadError,
          uploadError,
          uploadFileRequest,
          uploadModalOperations: {
            isOpen,
            onOpen,
            onClose,
          },
        }}
      >
        {children}
      </UploadContext.Provider>
    </>
  )
}

export const useUploadContext = (): UploadContextType => useContext(UploadContext)
