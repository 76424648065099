import { Box } from '@chakra-ui/layout'

// 3 dots loading animation
export const DotDotDot = () => (
  // The animation moves the dots up from their original position, so when using the component, that that into account when applying (external) margins/padding
  <Box className="flex mt-2">
    <Box className="w-2 h-2 mx-1 bg-gray-600 rounded-full animate-loader" />
    <Box className="w-2 h-2 mx-1 bg-gray-600 rounded-full animate-loader animation-delay-200" />
    <Box className="w-2 h-2 mx-1 bg-gray-600 rounded-full animate-loader animation-delay-400" />
  </Box>
)
