import { useEffect, useState } from 'react'
import { lazily } from 'react-lazily'
import { Route, Routes as RouterRoutes } from 'react-router-dom'
import { Configuration, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { API } from '@kleo/types'

import { useScrollToTop } from 'hooks/useScrollToTop'

import { AssessmentProvider } from 'providers/AssessmentProvider'
import { ConversationSettingsProvider } from 'providers/ConversationSettingsProvider'
import { FiltersAndFormsProvider } from 'providers/FiltersAndFormsProvider'
import { ScrollProvider } from 'providers/ScrollProvider'
import { UIConfigProvider } from 'providers/UIConfigProvider'

import { BotFunctions, FEUIConfig } from 'types/types'

import { LoginRedirect } from '../pages/LoginRedirect'
import { Upload } from '../pages/Upload'
import { AuthProvider, useAuthContext } from '../providers/AuthProvider'
import { BotProvider } from '../providers/BotProvider'
import { ChartProvider } from '../providers/ChartProvider'
import { useConfigContext } from '../providers/ConfigurationProvider'
import { useI18Context } from '../providers/i18Provider'
import { MessagesProvider } from '../providers/MessageProvider'
import { PDFProvider } from '../providers/PDFProvider'
import { UploadProvider } from '../providers/UploadProvider'
import { fetchData } from '../utils/http/methods'

import { AuthErrorBoundary } from './APIErrorBoundary'
import { ErrorBoundary } from './ErrorBoundary'
import { Layout } from './Layout'
import { GeneralSpinner, LoadingInBotsSpinner } from './Spinner'
import { SuspenseAuthFullPage, SuspenseFullPage, SuspenseRedirectFullPage } from './SuspenseFullPage'

const { About } = lazily(() => import('../pages/About'))
const { Document } = lazily(() => import('../pages/Document'))
const { General } = lazily(() => import('../pages/General'))
const { Home } = lazily(() => import('../pages/Home'))
const { Knowledge } = lazily(() => import('../pages/Knowledge'))
const { Landing } = lazily(() => import('../pages/Landing'))
const { NotFound } = lazily(() => import('../pages/NotFound'))
const { Assessment } = lazily(() => import('../pages/Assessment'))

export const App = () => {
  useScrollToTop()

  const config = useConfigContext()
  const [msalInstance, setMsalInstance] = useState<IPublicClientApplication | null>(null)
  // Need to do this check so that we don't run into: "ClientConfigurationError: empty_url_error: url was empty or null."
  // The ClientConfigurationError occurs when the fetch of the config.json doesn't happen fast enough and we provide the PublicClientApplication an empty configuration

  useEffect(() => {
    if (config.isContextReady) {
      const configuration: Configuration = {
        auth: {
          clientId: config.AUTH.CLIENT_ID,
          authority: config.AUTH.AUTHORITY,
          redirectUri: config.AUTH.REDIRECT_URI,
          knownAuthorities: config.AUTH.KNOWN_AUTHORITIES,
        },
        cache: {
          cacheLocation: 'sessionStorage',
        },
      }
      const fetchPcaConfig = async () => {
        try {
          const pcaInstance = await PublicClientApplication.createPublicClientApplication(configuration)
          setMsalInstance(pcaInstance)
        } catch (err) {
          console.error(err)
        }
      }
      fetchPcaConfig()
    }
  }, [
    config.AUTH.AUTHORITY,
    config.AUTH.CLIENT_ID,
    config.AUTH.KNOWN_AUTHORITIES,
    config.AUTH.REDIRECT_URI,
    config.isContextReady,
  ])

  if (!msalInstance) {
    return (
      <Layout>
        <GeneralSpinner />
      </Layout>
    )
  }

  return (
    <AuthErrorBoundary>
      <MsalProvider instance={msalInstance}>
        <AuthProvider>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </AuthProvider>
      </MsalProvider>
    </AuthErrorBoundary>
  )
}

const Routes = () => {
  const { API_ENDPOINT, isContextReady } = useConfigContext()
  const { getToken, isAuthenticated } = useAuthContext()
  const [retryBotConfig, setRetryBotConfig] = useState<boolean>(false)
  const [botConfig, setBotConfig] = useState<FEUIConfig[] | undefined>(undefined)
  const [botConfigAPIError, setBotConfigAPIError] = useState<boolean>(false)
  const [fetchingBotConfig, setFetchingBotConfig] = useState<boolean>(false)
  const { loadBotTranslations } = useI18Context()

  useEffect(() => {
    async function fetchFEConfigs() {
      try {
        const authToken = await getToken()
        const rId = `${Math.random()}-${new Date().getTime()}`

        const { botConfigs } = await fetchData<{ botConfigs: API.FEConfig[] }>({
          url: `${API_ENDPOINT}/chatapi/ui`,
          token: authToken,
          setRetrying: setRetryBotConfig,
          rId,
        })

        const UIBotConfigs: FEUIConfig[] = []

        for await (const bot of botConfigs) {
          await loadBotTranslations(bot.botName)
          try {
            const functions: BotFunctions = await import(
              /* webpackIgnore: true */ `/${bot.botName.toLocaleLowerCase()}/assets/functions.js`
            )
            UIBotConfigs.push({ ...bot, functions })
          } catch (e) {
            const functions: BotFunctions = await import('../utils/defaults/functions')
            UIBotConfigs.push({ ...bot, functions })
          }
        }

        setBotConfig(UIBotConfigs)
        setBotConfigAPIError(false)
      } catch (error) {
        // We set botConfig to an empty array here to identify that we failed to fetch and produced 0 bots
        setBotConfig([])
        setBotConfigAPIError(true)
      } finally {
        setFetchingBotConfig(false)
      }
    }
    // If we have the context ready, user is authenticated, we aren't currently fetching, and we haven't set any bots: call the function to fetch bot configs
    if (isContextReady && API_ENDPOINT && isAuthenticated && !botConfig && !fetchingBotConfig) {
      setFetchingBotConfig(true)
      fetchFEConfigs()
    }
  }, [API_ENDPOINT, getToken, isAuthenticated, isContextReady, botConfig, fetchingBotConfig, loadBotTranslations])

  return (
    <UIConfigProvider>
      <ConversationSettingsProvider config={botConfig}>
        <FiltersAndFormsProvider>
          <MessagesProvider>
            <ScrollProvider>
              <BotProvider>
                <PDFProvider>
                  <ChartProvider>
                    <UploadProvider config={botConfig}>
                      <AssessmentProvider config={botConfig}>
                        <Layout config={botConfig}>
                          <RouterRoutes>
                            <Route
                              path="/"
                              element={
                                <SuspenseAuthFullPage>
                                  <Landing />
                                </SuspenseAuthFullPage>
                              }
                            />
                            <Route
                              path="/login"
                              element={
                                <SuspenseFullPage>
                                  <LoginRedirect />
                                </SuspenseFullPage>
                              }
                            />
                            <Route
                              path="/about"
                              element={
                                <SuspenseAuthFullPage>
                                  <About />
                                </SuspenseAuthFullPage>
                              }
                            />
                            <Route
                              path="/home"
                              element={
                                <SuspenseRedirectFullPage>
                                  <Home config={botConfig} retryAPI={retryBotConfig} apiError={botConfigAPIError} />
                                </SuspenseRedirectFullPage>
                              }
                            />
                            {botConfig &&
                              botConfig.map((config) => (
                                <Route
                                  key={config.route}
                                  path={`${config.route}/*`}
                                  element={
                                    config.functions ? (
                                      <SuspenseRedirectFullPage>
                                        {(() => {
                                          switch (config.type) {
                                            case 'general':
                                              return <General config={config} />
                                            case 'document':
                                              return <Document config={config} />
                                            case 'knowledge':
                                              return <Knowledge config={config} />
                                            case 'upload':
                                              return <Upload config={config} />
                                            case 'assessment':
                                              return <Assessment config={config} />
                                            default:
                                              return null // Handle any other cases if necessary
                                          }
                                        })()}
                                      </SuspenseRedirectFullPage>
                                    ) : null
                                  }
                                />
                              ))}
                            {botConfig ? (
                              // If we have fetched bot config, show a 404 error for all routes that haven't been defined
                              <Route
                                path="*"
                                element={
                                  <SuspenseAuthFullPage>
                                    <NotFound />
                                  </SuspenseAuthFullPage>
                                }
                              />
                            ) : (
                              // If we have not fetched bot config, show a Spinner while we are in the middle of the fetch
                              <Route
                                path="*"
                                element={
                                  <SuspenseAuthFullPage>
                                    <LoadingInBotsSpinner />
                                  </SuspenseAuthFullPage>
                                }
                              />
                            )}
                          </RouterRoutes>
                        </Layout>
                      </AssessmentProvider>
                    </UploadProvider>
                  </ChartProvider>
                </PDFProvider>
              </BotProvider>
            </ScrollProvider>
          </MessagesProvider>
        </FiltersAndFormsProvider>
      </ConversationSettingsProvider>
    </UIConfigProvider>
  )
}
