import { useEffect, useMemo, useState } from 'react'

import { documentsMatchInString } from 'utils/documentUtils/documentsMatchInString'

type UseMatchedElementsProps = {
  botType: string
  content: string
  docContext?: string
}

export const useMatchedElements = ({ botType, docContext, content }: UseMatchedElementsProps) => {
  const [matchedElements, setMatchedElements] = useState<string[]>([])

  const areAllDocsSame = (docs: string[]) => {
    return docs.every((element) => element === docs[0])
  }

  const showLinks = useMemo(() => {
    return (
      (botType === 'document' || botType === 'knowledge') &&
      matchedElements.length > 0 &&
      !areAllDocsSame(matchedElements)
    )
  }, [botType, matchedElements])

  useEffect(() => {
    if (docContext && content && (botType === 'document' || botType === 'knowledge')) {
      // Aggregate a list of all the document names used in the corpus search results

      const documentsInCorpus = documentsMatchInString(docContext)

      if (documentsInCorpus) {
        // this is needed as typescript before 5.2 complains about array of union types on reduce, this is a workaround.
        const _documentsInCorpus: ReadonlyArray<{ document: string; text: string } | { text: string }> =
          documentsInCorpus
        // Now that we have a list of document object from the corpus, need to find out which ones are referenced within the message "content"
        const matchingDocumentsInContent = _documentsInCorpus.reduce<string[]>((currentMatches, currentDocument) => {
          if ('document' in currentDocument) {
            // sanitizing the document names, escaping brackets
            // eslint-disable-next-line no-useless-escape
            const escapedInputs = currentDocument.document.replace(/[\$\(\)\?\+\*\{\}\^\.\[\]\\\/]/g, '\\$&')
            const regex = new RegExp(`${escapedInputs}`, 'i') // Case-insensitive search
            if (regex.test(content)) {
              return [...currentMatches, currentDocument.document]
            }
          }
          return currentMatches
        }, [])

        // Remove all duplicates
        const uniqueItemsSet = new Set(matchingDocumentsInContent)
        const uniqueItemsArray = Array.from(uniqueItemsSet)

        setMatchedElements(uniqueItemsArray)
      }
    }
  }, [botType, content, docContext])

  return {
    showLinks,
    matchedElements,
  }
}
