import { useCallback, useMemo } from 'react'
import { BiBug } from 'react-icons/bi'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion'
import { Box, Text } from '@chakra-ui/layout'

import { useBotSpecificContext } from 'providers/BotSpecificProvider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useScrollContext } from 'providers/ScrollProvider'

import { useI18Context } from '../providers/i18Provider'
import { useSettingsContext } from '../providers/SettingsProvider'
import { SearchFilterItem } from '../types/types'

import { HTMLStringParser } from './HTMLStringParser'

type CorpusProps = {
  botName: string
  docContext?: string
  hasChartData: boolean
  messageIndex: number
  messageSpecificDocuments?: SearchFilterItem[]
}

export const Corpus = ({ botName, docContext, hasChartData, messageIndex, messageSpecificDocuments }: CorpusProps) => {
  const { t } = useI18Context()
  const { isChatFullScreen, isLightMode } = useSettingsContext()
  const { getCurrentConversationIDForBot } = useMessagesContext()
  const { stateExpanded, updateStateExpanded } = useScrollContext()
  const { functions } = useBotSpecificContext()

  const currentConversationID = useMemo(() => {
    return getCurrentConversationIDForBot(botName)
  }, [botName, getCurrentConversationIDForBot])

  const handleGetDocumentname = useCallback(
    (document: string) => {
      const documentName = functions.documentToName(document)

      return documentName
    },
    [functions]
  )

  if (!docContext) return null

  return (
    <Box className="flex text-black">
      <Box className="w-full">
        <Box
          className={`bg-kpmgGray4 ${isChatFullScreen ? 'rounded-b-none' : 'rounded-b-2xl'} ${
            hasChartData && 'rounded-b-none border-t border-y-0 border-t-kpmgDarkBlue'
          }`}
        >
          <Accordion allowToggle id="doc">
            {/* className used as id to be identified in ChatBot component for purposes of react scroll library, in order to know to which accordian item to scroll to exactly */}
            <AccordionItem
              className={`corpus${messageIndex} border-kpmgGray4 ${
                isChatFullScreen ? 'rounded-b-none' : 'rounded-b-2xl'
              }`}
            >
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    className={`border-kpmgGray4 p-2 ${!isChatFullScreen && 'px-3 lg:px-4'} ${
                      isChatFullScreen || isExpanded ? 'rounded-b-none' : 'rounded-b-2xl'
                    } ${
                      isLightMode ? 'focus:ring-light focus:shadow-none' : 'focus:ring-dark focus:shadow-none'
                    } flex justify-between`}
                    tabIndex={0}
                  >
                    <Box className="flex items-center">
                      {/* For width definitions below, we need to match what the K and Feedback buttons have: w-[34px] is w-8 + 2px worth of border. w-[50px] is w-12 + 2px worth of border */}
                      <Box className={`flex justify-center ${isChatFullScreen && 'w-[34px] md:w-[50px] mr-2'}`}>
                        <BiBug className={`w-4 h-4 md:h-5 md:w-5 ${!isChatFullScreen && 'mr-3 lg:mr-4'}`} />
                      </Box>
                      <Text className="text-xs md:text-sm">{`${t('generic.corpus')}${
                        messageSpecificDocuments
                          ? Array.isArray(messageSpecificDocuments)
                            ? ` - ${messageSpecificDocuments
                                .map((doc) => (functions ? handleGetDocumentname(doc.value).label : doc.value))
                                .join(', ')} `
                            : ''
                          : ''
                      }`}</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel
                    whiteSpace="pre-wrap"
                    className={`shadow-none overflow-y-auto max-h-[30vh] ${
                      isLightMode ? 'focus:ring-light focus:shadow-none' : 'focus:ring-dark focus:shadow-none'
                    }`}
                    pt={0}
                    motionProps={{
                      onAnimationStart() {
                        // only update the expanded state if it's different in order to prevent unnecessary callbacks when toggling fullscreen
                        if (
                          currentConversationID &&
                          stateExpanded['activeStates'][currentConversationID]?.[`${messageIndex.toString()}`] !==
                            isExpanded
                        ) {
                          const currentMessageId = `corpus${messageIndex.toString()}`
                          updateStateExpanded(
                            currentConversationID,
                            currentMessageId,
                            isExpanded,
                            isExpanded ? currentMessageId : null
                          )
                        }
                      },
                    }}
                    tabIndex={isExpanded ? 0 : -1}
                  >
                    <Text className="pt-2 text-sm">{t('generic.context')}</Text>
                    <Box className="my-5">
                      <HTMLStringParser htmlString={docContext} getDocumentName={handleGetDocumentname} />
                    </Box>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
    </Box>
  )
}
