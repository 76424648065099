import React, { ReactElement } from 'react'
import { IconType } from 'react-icons'
import { Button, ButtonProps } from '@chakra-ui/button'

type LeftOrRightIcon =
  | {
      leftIcon: ReactElement<IconType>
      rightIcon?: never
    }
  | {
      leftIcon?: never
      rightIcon: ReactElement<IconType>
    }

type TextIconButtonProps = Omit<ButtonProps, 'leftIcon' | 'rightIcon'> & LeftOrRightIcon

export const TextIconButton = React.forwardRef<HTMLButtonElement, TextIconButtonProps>((props, ref) => {
  return (
    <Button {...props} ref={ref}>
      {props.children}
    </Button>
  )
})
