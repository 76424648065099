import React from 'react'
import { IconType } from 'react-icons'
import { IconButton as ChakraIconButton, IconButtonProps as ChakraIconProps } from '@chakra-ui/button'

import { useSettingsContext } from 'providers/SettingsProvider'

type IconButtonProps = {
  iconName: IconType
  iconClassName?: string
  iconSize?: number
} & Omit<ChakraIconProps, 'icon'>

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { isLightMode } = useSettingsContext()
  const {
    iconName: Icon,
    iconClassName = 'text-xl md:text-2xl',
    iconSize,
    sx = {
      p: '1', // Set the desired padding value in the y-direction
    },
    className = `focus:shadow-none self-end ml-4 rounded-lg ${isLightMode ? 'focus:ring-light' : 'focus:ring-dark'}`,
    ...rest
  } = props

  return (
    <ChakraIconButton
      {...rest}
      className={className}
      ref={ref}
      icon={<Icon className={iconClassName} size={iconSize} />}
      sx={sx}
      tabIndex={0}
    />
  )
})
