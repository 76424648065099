import { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Text } from '@chakra-ui/layout'
import { Select } from 'chakra-react-select'

import { useConversationSettingsContext } from 'providers/ConversationSettingsProvider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useSettingsContext } from 'providers/SettingsProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import { ConversationSpecificSettings, SearchFilterItem } from 'types/types'

import { useI18Context } from '../providers/i18Provider'

import { Button } from './buttons/Button'
import { TooltipButton } from './buttons/TooltipButton'
import { ModalBox } from './Modal'

type ConversationSettingsModalProps = {
  botName: string
  isOpen: boolean
  onClose: () => void
}

export const ConversationSettingsModal = ({ botName, isOpen, onClose }: ConversationSettingsModalProps) => {
  const [currentSettingsCategoryIndex, setCurrentSettingsCategoryIndex] = useState(0)

  const { getConversationSettings, updateConversationSettings, availableConversationSettings, getDefaultSettings } =
    useConversationSettingsContext()
  const [settings, setSettings] = useState<ConversationSpecificSettings>({} as ConversationSpecificSettings)
  const { t } = useI18Context()

  const { getCurrentConversationIDForBot } = useMessagesContext()
  const { isTablet } = useThemeContext()
  const { isDarkMode } = useSettingsContext()

  useEffect(() => {
    if (isOpen) {
      const currentConversationID = getCurrentConversationIDForBot(botName) || 0
      const botSettings = getConversationSettings(botName, currentConversationID)

      // If we have bot settings, load them in, else use a default and save those default settings
      const initialSettings = botSettings || getDefaultSettings(botName)

      if (!botSettings) updateConversationSettings(botName, currentConversationID, initialSettings)
      if (initialSettings) setSettings(initialSettings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])
  // Current settings category
  const settingsCategories = ['input']

  // Voice
  const voiceOptions = useMemo(
    () => availableConversationSettings[botName]?.input?.voices,
    [availableConversationSettings, botName]
  )

  // Temperature
  const temperatureOptions = useMemo(
    () => availableConversationSettings[botName]?.input?.temperatures,
    [availableConversationSettings, botName]
  )

  const handleTemperatureSelect = useCallback((temperature: SearchFilterItem) => {
    setSettings((currentSettings) => ({
      ...currentSettings,
      input: {
        ...currentSettings.input,
        temperature,
      },
    }))
  }, [])

  const handleSave = useCallback(() => {
    updateConversationSettings(botName, getCurrentConversationIDForBot(botName) || 0, settings)
    onClose()
  }, [botName, getCurrentConversationIDForBot, onClose, settings, updateConversationSettings])

  const settingsGUIArray = [
    <>
      <Box className="mb-4">
        <Text className="mb-2 text-xs font-bold">{t('settings.voice').toUpperCase()}</Text>
        <Select
          size={isTablet ? 'md' : 'sm'}
          aria-label="voice"
          name="voice"
          options={voiceOptions}
          defaultValue={[settings.input?.voice] || []}
          value={settings && settings.input ? [settings.input.voice] : []}
          onChange={(value) => {
            setSettings((currentSettings) => ({
              ...currentSettings,
              input: { ...currentSettings.input, voice: value as SearchFilterItem },
            }))
          }}
        />
        <Text className={`mt-2 text-xs text-gray-${isDarkMode ? 400 : 500}`}>
          {t(`settings.voices.description.${settings.input?.voice.value}`)}
        </Text>
      </Box>
      {settings.input?.temperature && (
        <Box className="mb-4">
          <Text className="mb-2 text-xs font-bold">{t('settings.temperature').toUpperCase()}</Text>
          <Box className="flex justify-around w-full text-xs text-white rounded-lg md:text-sm bg-kpmgGray2 child:px-3 child:py-2">
            {temperatureOptions &&
              temperatureOptions.map((temperature: SearchFilterItem) => {
                return (
                  <Box
                    key={temperature.value}
                    tabIndex={0}
                    className={`${temperature.value !== settings.input.temperature.value && 'hover:cursor-pointer'} ${
                      temperature.value === settings.input.temperature.value && 'rounded-md bg-kpmgCobaltBlue'
                    } w-full text-center`}
                    onClick={() => handleTemperatureSelect(temperature)}
                    onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
                      if (event.key === 'Enter') {
                        handleTemperatureSelect(temperature)
                      }
                    }}
                  >
                    {temperature.label}
                  </Box>
                )
              })}
          </Box>
          <Text className={`mt-2 text-xs text-gray-${isDarkMode ? 400 : 500}`}>
            {settings && t(`settings.details.${settings.input.temperature.value}`)}
          </Text>
        </Box>
      )}
    </>,
  ]

  return (
    <ModalBox
      size="lg"
      isOpen={isOpen}
      hideCloseButton={true}
      closeOnEscape={false}
      modalBody={
        <Box className={isTablet ? 'flex' : ''} test-id="conversation-settings-modal">
          <Box className={`flex flex-col mr-4 whitespace-nowrap ${!isTablet && 'mb-4'}`}>
            <Text className={`mb-3 text-xs text-gray-${isDarkMode ? 400 : 500}`}>{t('settings.categoriesHeader')}</Text>
            {settingsCategories.map((setting, index) => {
              return (
                <Box
                  key={`${setting}-${index}`}
                  className={`mb-2 text-sm text-white rounded-md hover:cursor-pointer py-1 px-2 ${
                    currentSettingsCategoryIndex === index && 'bg-kpmgCobaltBlue'
                  }`}
                  onClick={() => setCurrentSettingsCategoryIndex(index)}
                >
                  {t(`settings.${setting}`)}
                </Box>
              )
            })}
          </Box>
          <Box className="flex flex-col w-full">{settingsGUIArray[currentSettingsCategoryIndex]}</Box>
        </Box>
      }
      modalHeader={t('settings.header')}
      onClose={onClose}
      modalFooter={
        <Box className="flex gap-3">
          <TooltipButton
            label={t('settings.cancelWarning')}
            placement="left"
            button={
              <Button
                onClick={onClose}
                aria-label="close-button"
                test-id="cancel-settings-button"
                className="text-sm md:text-base"
              >
                {t('controls.cancel')}
              </Button>
            }
          />
          <Button
            className="text-sm text-white bg-green-700 hover:bg-green-600 md:text-base"
            onClick={handleSave}
            aria-label="next"
            test-id="save-settings-button"
          >
            {t('settings.save')}
          </Button>
        </Box>
      }
    />
  )
}
