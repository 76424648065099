import { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import isEqual from 'lodash.isequal'

import { ChatBot } from 'components/ChatBot'
import { ConversationDrawer } from 'components/drawer/ConversationDrawer'
import { UploadDrawer } from 'components/drawer/UploadDrawer'

import { useBotContext } from 'providers/BotProvider'
import { BotSpecificProvider } from 'providers/BotSpecificProvider'
import { useConversationSettingsContext } from 'providers/ConversationSettingsProvider'
import { useFiltersContext } from 'providers/FiltersAndFormsProvider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useThemeContext } from 'providers/ThemeProvider'
import { useUploadContext } from 'providers/UploadProvider'

import { BotFilterValues, BotFormValues, DisclaimerProperty, FEUIConfig, SubheaderProperty } from 'types/types'

type UploadBotProps = {
  config: FEUIConfig
  chatQuerySubmit: SubmitHandler<BotFormValues & BotFilterValues>
  fileSubmit: (files: File[]) => void
}

const disclaimer: DisclaimerProperty[] = [
  { value: 'disclaimer.title', type: 'title' },
  { value: 'disclaimer.text.1', type: 'text' },
]

const subheader: SubheaderProperty[][] = [
  [{ value: 'subheader.text.1', type: 'text' }],
  [{ value: 'subheader.text.2', type: 'text' }],
]

export const UploadBot = ({ config, chatQuerySubmit, fileSubmit }: UploadBotProps) => {
  const { setValue, watch } = useFormContext<BotFormValues & BotFilterValues>()
  const {
    bgImageDark,
    bgImageLight,
    botName,
    lastUpdated,
    maxInput,
    supportedMimeTypes,
    supportedFileExtensions,
    type,
    uploadTokenMaxSize,
    urlLinks,
    functions,
  } = config
  const { getStreamingStatus, isFetchingChart } = useBotContext()
  const { updateSelectedFormValues, getSelectedFormValues, getSelectedFilterValues, updateSelectedFilterValues } =
    useFiltersContext()
  const { conversations, getCurrentConversationIDForBot, getMessages, streamingMessage, streamingSummary } =
    useMessagesContext()
  const { getConversationSettings, getDefaultSettings } = useConversationSettingsContext()
  const { isDesktop } = useThemeContext()
  const { docContents, setDocContents } = useUploadContext()

  // This method will watch specified inputs and return their values. It is useful to render input value and for determining what to render by condition.
  const values = watch()

  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState<boolean>(isDesktop)
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState<boolean>(isDesktop)

  const [isStreamingInitiated, setIsStreamingInitiated] = useState<boolean>(false)

  const currentConversationID = useMemo(() => {
    return getCurrentConversationIDForBot(botName) ?? 0
  }, [botName, getCurrentConversationIDForBot])

  const currentConversationSettings = useMemo(() => {
    return getConversationSettings(botName, currentConversationID) || getDefaultSettings(botName)
  }, [botName, currentConversationID, getConversationSettings, getDefaultSettings])

  const { streamingInfo } = useMemo(() => {
    const streamingInfo = getStreamingStatus(botName)

    return { streamingInfo }
  }, [botName, getStreamingStatus])

  useEffect(() => {
    if (!isDesktop && isLeftDrawerOpen && isRightDrawerOpen) {
      setIsRightDrawerOpen(false)
    }
    // Need the below disable so that we only run this for when isLeftDrawerOpen value changes. In the future we can look for a better solution
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, isLeftDrawerOpen])

  useEffect(() => {
    if (!isDesktop && isLeftDrawerOpen && isRightDrawerOpen) {
      setIsLeftDrawerOpen(false)
    }
    // Need the below disable so that we only run this for when isRightDrawerOpen value changes. In the future we can look for a better solution
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, isRightDrawerOpen])

  useEffect(() => {
    if (isDesktop) {
      if (!isRightDrawerOpen) {
        setIsRightDrawerOpen(true)
      }
      if (!isLeftDrawerOpen) {
        setIsLeftDrawerOpen(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop])

  useEffect(() => {
    if (streamingInfo?.status && !isStreamingInitiated) {
      setIsRightDrawerOpen(isRightDrawerOpen && isDesktop)
      setIsLeftDrawerOpen(isLeftDrawerOpen && isDesktop)
    }
  }, [botName, isDesktop, isLeftDrawerOpen, isRightDrawerOpen, streamingInfo?.status, isStreamingInitiated])

  useEffect(() => {
    if (
      !isEqual(getSelectedFormValues(botName, currentConversationID), {
        userQuery: values.userQuery,
        clicked: values.clicked,
      })
    ) {
      updateSelectedFormValues(botName, currentConversationID, { userQuery: values.userQuery, clicked: values.clicked })
    }
    if (!isEqual(getSelectedFilterValues(botName, currentConversationID), { filters: values.filters })) {
      updateSelectedFilterValues(botName, currentConversationID, { filters: values.filters })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName, values])

  // This is needed so that if we change from one bot to another of the same type, the form values will update properly
  useEffect(() => {
    const botFormValues = getSelectedFormValues(botName, currentConversationID)
    const botFilterValues = getSelectedFilterValues(botName, currentConversationID)

    if (botFormValues) {
      setValue('userQuery', botFormValues.userQuery)
      setValue('clicked', botFormValues.clicked)
    }

    if (botFilterValues) {
      setValue('filters.document', botFilterValues?.filters?.document)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName, currentConversationID])

  // As soon as the form has sent the API request, reset the value being shown in the Textarea
  useEffect(() => {
    if (streamingInfo?.status) {
      setValue('userQuery', '', { shouldValidate: false })
      setIsStreamingInitiated(true)
    } else {
      setValue('clicked', null, { shouldValidate: false })
      setIsStreamingInitiated(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName, streamingInfo?.status])

  useEffect(() => {
    if (botName in conversations && 0 in docContents) {
      setDocContents((currentDocContents) => {
        // Spread the previous contents, assign the old key's value to the new key
        const updatedContents = {
          ...currentDocContents,
          [currentConversationID]: currentDocContents[0] ?? {},
        }
        // Delete the old key
        delete updatedContents[0]
        return updatedContents
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName, conversations[botName]])

  return (
    <BotSpecificProvider
      botName={botName}
      conversationSettings={currentConversationSettings}
      isFetchingChart={isFetchingChart[botName]}
      isStreaming={!!streamingInfo?.status}
      streamingStep={streamingInfo?.step}
      messages={getMessages(botName)}
      streamingMessage={streamingMessage[botName]}
      streamingSummary={streamingSummary[botName]}
      urlLinks={urlLinks}
      functions={functions}
    >
      <ChatBot
        bgImageDark={bgImageDark}
        bgImageLight={bgImageLight}
        botName={botName}
        botType={type}
        disclaimer={disclaimer}
        lastUpdated={lastUpdated}
        leftDrawer={ConversationDrawer({
          botName,
          isDrawerOpen: isLeftDrawerOpen,
          isStreaming: !!streamingInfo?.status,
          setIsDrawerOpen: setIsLeftDrawerOpen,
        })}
        maxInput={maxInput}
        rightDrawer={UploadDrawer({
          fileSubmit,
          isDrawerOpen: isRightDrawerOpen,
          isFetchingChart: isFetchingChart[botName],
          isStreaming: !!streamingInfo?.status,
          setIsDrawerOpen: setIsRightDrawerOpen,
          supportedMimeTypes: supportedMimeTypes || [],
          supportedFileExtensions: supportedFileExtensions || [],
          uploadTokenMaxSize: uploadTokenMaxSize ?? 0,
        })}
        showSettings={!!config.settings}
        subheader={subheader}
        submit={chatQuerySubmit}
      />
    </BotSpecificProvider>
  )
}
