import { useMemo } from 'react'
import { Box, Text } from '@chakra-ui/react'

import { useI18Context } from 'providers/i18Provider'
import { useThemeContext } from 'providers/ThemeProvider'

import { SubheaderProperty } from 'types/types'

type HeaderTextProps = {
  botName: string
}

export const HeaderText = ({ botName }: HeaderTextProps) => {
  const { isDesktop } = useThemeContext()
  const { i18n, getTForNS, t } = useI18Context()

  const botT = getTForNS(botName.toLocaleLowerCase())

  const headerText = useMemo(() => {
    return i18n.exists('welcomeToHeader', { ns: botName.toLowerCase() }) ? botT('welcomeToHeader') : botT('header')
  }, [botName, botT, i18n])

  // If the bot name text is long, create two lines for the header: 1. For the "Welcome to the" and 2. For the bot name to display. Only do this for desktop view
  return isDesktop && headerText.length > 30 ? (
    <>
      <Text as="h2" className="text-xl text-center sm:text-2xl lg:text-3xl font-opensanscondensed" tabIndex={0}>
        {t('generic.welcomeToThe')}
      </Text>
      <Text as="h2" className="text-xl text-center sm:text-2xl lg:text-3xl font-opensanscondensed" tabIndex={0}>
        {headerText}
      </Text>
    </>
  ) : (
    <Text as="h2" className="text-xl text-center sm:text-3xl lg:text-4xl font-opensanscondensed" tabIndex={0}>
      {t('generic.welcomeToThe')}
      {headerText}
    </Text>
  )
}

type SubheaderTextProps = {
  botName: string
  subheader: SubheaderProperty[][]
}

export const SubheaderText = ({ botName, subheader }: SubheaderTextProps) => {
  const { i18n, getTForNS } = useI18Context()

  const botT = getTForNS(botName.toLocaleLowerCase())

  return (
    <>
      {subheader.map((property: SubheaderProperty[], subheaderIndex: number) => {
        // Check if one of the object in "property: SubheaderProperty[]" has a translation that exists
        if (property.some((obj) => i18n.exists(obj.value, { ns: botName.toLocaleLowerCase() }))) {
          return (
            <Box key={`${subheaderIndex}_${property}`} className="mb-4">
              <Box className="px-2 md:px-3 py-1 border-l-4 border-white border-opacity-75 bg-gray-800 backdrop-blur bg-opacity-[.3] inline-block rounded-r-md">
                {property.map((obj: SubheaderProperty, objIndex: number) => {
                  if (obj.type === 'text') {
                    // Need to check if the translation exists before displaying it back to the user. Can end up with issues like displaying to the user "subheader.3" in the case where the translation can't get mapped
                    return i18n.exists(obj.value, { ns: botName.toLocaleLowerCase() }) ? (
                      <Text
                        key={`${objIndex}_${obj.value}`}
                        as="span"
                        className={`text-xs md:text-sm xl:text-base ${objIndex !== property.length && 'mr-1'}`}
                        tabIndex={0}
                      >
                        {botT(obj.value)}
                      </Text>
                    ) : null
                  }
                  if (obj.type === 'highlight') {
                    // Need to check if the translation exists before displaying it back to the user. Can end up with issues like displaying to the user "subheader.3" in the case where the translation can't get mapped
                    return i18n.exists(obj.value, { ns: botName.toLocaleLowerCase() }) ? (
                      <Text
                        as="span"
                        className={`text-xs md:text-sm xl:text-base bg-yellow-300 bg-opacity-50 ${
                          objIndex !== property.length && 'mr-1'
                        }`}
                        tabIndex={0}
                        key={`${obj.value}_${objIndex}`}
                      >
                        {botT(obj.value)}
                      </Text>
                    ) : null
                  }
                  // This return null should never get hit because "obj.type" is always equal to either "text" or "highlight"
                  return null
                })}
              </Box>
            </Box>
          )
        }
        // None of the objects in "property: SubheaderProperty[]" contained a translation that exists, so return null
        return null
      })}
    </>
  )
}
