import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'
import { useColorMode } from '@chakra-ui/color-mode'

type SettingsType = {
  isChatFullScreen: boolean
  isLightMode: boolean
  isDarkMode: boolean
  setChatFullScreen: Dispatch<SetStateAction<boolean>>
  toggleColorMode: () => void
}

export const SettingsContext = createContext<SettingsType>({} as SettingsType)

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [isChatFullScreen, setChatFullScreen] = useState(false)

  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <>
      <SettingsContext.Provider
        value={{
          isChatFullScreen,
          isLightMode: colorMode === 'light',
          isDarkMode: colorMode === 'dark',
          setChatFullScreen,
          toggleColorMode,
        }}
      >
        {children}
      </SettingsContext.Provider>
    </>
  )
}

export const useSettingsContext = (): SettingsType => useContext(SettingsContext)
